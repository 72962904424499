/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"

export default function Footer(props) {
  const fullscreen = props.fullscreen
  return (
    <footer
      className='section bg-gray-04'
      css={css`
        grid-area: d;
        ${fullscreen && `display: none;`}
        @media only screen and (max-width: 768px) {
          display: none;
        }
        @media print {
          display: none;
        }
      `}
    >
      <div className='container'>
        <div className='row wrapper-05'>
          <div className='col-sm-20 item-05'>
            <strong className='h8'>Informace</strong>
            <ul className='list-unstyled ul-footer'>
              <li>
                <a href='https://lj-kuchyne.cz/kuchyne-na-miru'>Kuchyně na míru</a>
              </li>
              <li>
                <a href='https://lj-kuchyne.cz/vestavene-skrine'>Věstavěné skříně</a>
              </li>
              <li>
                <a href='https://lj-kuchyne.cz/obyvaci-steny'>Obývací stěny</a>
              </li>
              <li>
                <a href='https://lj-kuchyne.cz/realizace'>Realizace</a>
              </li>
              <li>
                <a href='https://lj-kuchyne.cz/o-nas'>O nás</a>
              </li>
              <li>
                <a
                  href='https://konfigurator.lj-kuchyne.cz/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Konfigurátor skříní
                </a>
              </li>
              <li>
                <a href='https://lj-kuchyne.cz/kuchynske-studio-hermanuv-mestec'>
                  Studio Heřmanův Městec
                </a>
              </li>
              <li>
                <a href='https://lj-kuchyne.cz/lj-kuchyne'>Kontakty</a>
              </li>
            </ul>
          </div>
          <div className='col-sm-20 item-05'>
            <strong className='h8'>Kariéra</strong>
            <p>
              Hledáte práci? Podívejte se
              <br className='visible-lg' /> na naše aktuálně otevřené
              <br className='visible-lg' /> pracovní nabídky.
            </p>
            <a
              css={css`
                margin-top: 2.5rem !important;
              `}
              className='btn-main btn-main-white-01'
              href='/kariera'
            >
              Zobrazit volná místa
            </a>
          </div>
          <div className='col-sm-20 item-05'>
            <strong className='h8'>Heřmanův Městec</strong>
            <ul className='list-unstyled ul-footer'>
              <li>Nový Dvůr 918,</li>
              <li>538 03 Heřmanův Městec</li>
              <li>+420 774 408 054</li>
              <li className='ul-footer-space'>
                <a href='mailto:hermanuvmestec@LJ.cz'>hermanuvmestec@LJ.cz</a>
              </li>
              <li>
                <strong>Otevírací doba</strong>
              </li>
              <li>Po-Pá: 6:30 - 12:00, 12:30 - 15:00</li>
            </ul>
          </div>
        </div>
        <div className='row section section-bottom-0'>
          <div className='col-lg-30 text-left'>
            <ul className='list-unstyled ul-copyright'>
              <li> © 2021 LJ spol. s.r.o. - Heřmanův Městec</li>
            </ul>
          </div>
          <div className='col-lg-30 text-right'>
            <ul className='list-unstyled ul-copyright'>
              <li>
                <a
                  href='https://cs-cz.facebook.com/LJvyrobanabytku'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <svg
                    className='svg-inline--fa fa-facebook-f fa-w-9'
                    aria-hidden='true'
                    data-prefix='fab'
                    data-icon='facebook-f'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 264 512'
                    data-fa-i2svg=''
                  >
                    <path
                      fill='currentColor'
                      d='M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229'
                    ></path>
                  </svg>
                  {` `}
                  Sledujte nás na Facebooku
                </a>
              </li>
              <li>
                <a href='https://www.nabytek-bydleni.cz/' target='_blank' rel='noopener noreferrer'>
                  www.nabytek-bydleni.cz
                </a>
              </li>
              <li>
                <a href='https://www.bilyraj.cz/' target='_blank' rel='noopener noreferrer'>
                  www.bilyraj.cz
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
