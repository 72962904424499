/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState, useContext, useEffect } from "react"
import { SketchPicker } from "react-color"
import UserContext from "../../context/UserContext"
import Axios from "axios"
import ColorIconActive from "../../img/art-active.svg"
import { useTheme } from "emotion-theming"
import Loading from "../../img/loading.svg"

export default function Colors() {
  const { userData } = useContext(UserContext)
  const [pickerPrimary, setPrimaryPicker] = useState(false)
  const [pickerSecondary, setSecondaryPicker] = useState(false)
  const [pickerTertiary, setTertiaryPicker] = useState(false)
  const [pickerQuaternary, setQuaternaryPicker] = useState(false)
  const [pickerLabel, setLabelPicker] = useState(false)
  const [pickerColor6, setPickerColor6] = useState(false)
  const [primaryColor, setPrimaryColor] = useState()
  const [secondaryColor, setSecondaryColor] = useState()
  const [tertiaryColor, setTertiaryColor] = useState()
  const [quaternaryColor, setQuaternaryColor] = useState()
  const [labelColor, setLabelColor] = useState()
  const [color6, setColor6] = useState()
  const [successColors, setSuccessColors] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchColors = async () => {
      const userRes = await Axios.get(`/users/all/${userData.user.displayName}`)
      setPrimaryColor(userRes.data.primaryColor)
      setSecondaryColor(userRes.data.secondaryColor)
      setTertiaryColor(userRes.data.tertiaryColor)
      setQuaternaryColor(userRes.data.quaternaryColor)
      setColor6(userRes.data.color6)
      setLabelColor(userRes.data.labelColor)
      setLoading(false)
    }
    fetchColors()
  }, [userData.user.displayName])

  const [error, setError] = useState()

  const handleColorsChange = async (e) => {
    e.preventDefault()
    try {
      const submittedData = {
        primaryColor,
        secondaryColor,
        tertiaryColor,
        quaternaryColor,
        labelColor,
        color6
      }
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      await Axios.put(`/users/colors`, submittedData, {
        headers: { "x-auth-token": token }
      })
      setSuccessColors(`Barevné schéma bylo změněno.`)
      setTimeout(function() {
        setSuccessColors()
      }, 5000)
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg)
    }
  }

  const setOriginColors = async (color) => {
    try {
      const submittedData = {
        primaryColor: `#1a3a3a`,
        secondaryColor: `#d1b283`,
        tertiaryColor: `#e7e2f6`,
        quaternaryColor: `#3c3941`,
        labelColor: `#000000`,
        color6: `#e6e6d9`
      }
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      await Axios.put(`/users/colors`, submittedData, {
        headers: { "x-auth-token": token }
      })
      setSuccessColors(`Barevné schéma bylo změněno.`)
      setTimeout(function() {
        setSuccessColors()
      }, 5000)
      setPrimaryColor(`#1a3a3a`)
      setSecondaryColor(`#d1b283`)
      setTertiaryColor(`#e7e2f6`)
      setQuaternaryColor(`#3c3941`)
      setLabelColor(`#000000`)
      setColor6(`#e6e6d9`)
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg)
    }
  }

  const colors = useTheme()

  return (
    <>
      <h2
        css={css`
          display: flex;
          &:before {
            content: "";
            background-image: url(${ColorIconActive});
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            display: inline-flex;
          }
        `}
      >
        Barevné schéma konfigurátoru
      </h2>
      {loading ? (
        <img src={Loading} alt='Loading...' />
      ) : (
        <form
          onSubmit={handleColorsChange}
          css={css`
            display: grid;
            grid-template-columns: auto auto 20px auto;
            grid-gap: 10px;
            align-items: center;
            padding: 10px;
            background: #ccc;
            label {
              font-weight: 600;
              font-size: 13px;
              text-transform: uppercase;
            }
            span {
              font-weight: 600;
              font-size: 13px;
              text-transform: uppercase;
            }
            @media only screen and (max-width: 768px) {
              max-width: 100%;
              grid-template-columns: auto auto 20px;
              span {
                grid-column-start: 1;
                grid-column-end: 4;
              }
            }
          `}
        >
          {!error && successColors && (
            <div
              css={css`
                background: ${error ? `${colors.red}` : `#60a26b`};
                grid-column-start: 1;
                grid-column-end: 5;
                color: #fff;
                padding: 10px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                position: relative;
              `}
            >
              {error}
              {successColors}
            </div>
          )}
          <label>Barva 1:</label>
          <div
            css={css`
              position: relative;
            `}
          >
            {pickerPrimary === true && (
              <div
                css={css`
                  position: absolute;
                  z-index: 2;
                  top: 40px;
                `}
              >
                <div
                  css={css`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  `}
                  onClick={() => setPrimaryPicker(false)}
                ></div>
                <SketchPicker
                  color={primaryColor}
                  onChange={(e) => {
                    setPrimaryColor(e.hex)
                  }}
                />
              </div>
            )}
            <input
              type='text'
              name='color'
              value={primaryColor}
              onClick={() => setPrimaryPicker(true)}
              onChange={(e) => setPrimaryColor(e.target.value)}
            />
          </div>
          <div
            css={css`
              width: 20px;
              height: 20px;
              background: ${primaryColor};
              cursor: pointer;
            `}
            onClick={() => setPrimaryPicker(true)}
          ></div>
          <span>(hlavička, patička, přepínač, tlačítko, posuvník)</span>
          <label>Barva 2:</label>
          <div
            css={css`
              position: relative;
            `}
          >
            {pickerSecondary === true && (
              <div
                css={css`
                  position: absolute;
                  z-index: 2;
                  top: 40px;
                `}
              >
                <div
                  css={css`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  `}
                  onClick={() => setSecondaryPicker(false)}
                ></div>
                <SketchPicker
                  color={secondaryColor}
                  onChange={(e) => {
                    setSecondaryColor(e.hex)
                  }}
                />
              </div>
            )}
            <input
              type='text'
              name='color'
              value={secondaryColor}
              onClick={() => setSecondaryPicker(true)}
              onChange={(e) => setSecondaryColor(e.target.value)}
            />
          </div>
          <div
            css={css`
              width: 20px;
              height: 20px;
              background: ${secondaryColor};
              cursor: pointer;
            `}
            onClick={() => setSecondaryPicker(true)}
          ></div>
          <span>(levý panel, pravý spodní panel)</span>
          <label>Barva 3:</label>
          <div
            css={css`
              position: relative;
            `}
          >
            {pickerTertiary === true && (
              <div
                css={css`
                  position: absolute;
                  z-index: 2;
                  top: 40px;
                `}
              >
                <div
                  css={css`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  `}
                  onClick={() => setTertiaryPicker(false)}
                ></div>
                <SketchPicker
                  color={tertiaryColor}
                  onChange={(e) => {
                    setTertiaryColor(e.hex)
                  }}
                />
              </div>
            )}
            <input
              type='text'
              name='color'
              value={tertiaryColor}
              onClick={() => setTertiaryPicker(true)}
              onChange={(e) => setTertiaryColor(e.target.value)}
            />
          </div>
          <div
            css={css`
              width: 20px;
              height: 20px;
              background: ${tertiaryColor};
              cursor: pointer;
            `}
            onClick={() => setTertiaryPicker(true)}
          ></div>
          <span>
            (pozadí položek menu, text nadpisů sekcí, text v pravém spodním panelu, text tlačítka,
            text v tlačítcích ve 3D modelu, titulek textur, pozadí šuplíků ve 2D modelu, text
            rozměrů skříně ve 2D modelu)
          </span>
          <label>Barva 4:</label>
          <div
            css={css`
              position: relative;
            `}
          >
            {pickerQuaternary === true && (
              <div
                css={css`
                  position: absolute;
                  z-index: 2;
                  top: 40px;
                `}
              >
                <div
                  css={css`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  `}
                  onClick={() => setQuaternaryPicker(false)}
                ></div>
                <SketchPicker
                  color={quaternaryColor}
                  onChange={(e) => {
                    setQuaternaryColor(e.hex)
                  }}
                />
              </div>
            )}
            <input
              type='text'
              name='color'
              value={quaternaryColor}
              onClick={() => setQuaternaryPicker(true)}
              onChange={(e) => setQuaternaryColor(e.target.value)}
            />
          </div>
          <div
            css={css`
              width: 20px;
              height: 20px;
              background: ${quaternaryColor};
              cursor: pointer;
            `}
            onClick={() => setQuaternaryPicker(true)}
          ></div>
          <span>
            (pozadí nadpisů sekcí, pozadí pravého spodního panelu, pozadí textur, mřížka 2D modelu
            vnitřního vybavení, pozadí rozměrů skříně ve 2D modelu)
          </span>
          <label>Barva 5:</label>
          <div
            css={css`
              position: relative;
            `}
          >
            {pickerLabel === true && (
              <div
                css={css`
                  position: absolute;
                  z-index: 2;
                  top: 40px;
                `}
              >
                <div
                  css={css`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  `}
                  onClick={() => setLabelPicker(false)}
                ></div>
                <SketchPicker
                  color={labelColor}
                  onChange={(e) => {
                    setLabelColor(e.hex)
                  }}
                />
              </div>
            )}
            <input
              type='text'
              name='color'
              value={labelColor}
              onClick={() => setLabelPicker(true)}
              onChange={(e) => setLabelColor(e.target.value)}
            />
          </div>
          <div
            css={css`
              width: 20px;
              height: 20px;
              background: ${labelColor};
              cursor: pointer;
            `}
            onClick={() => setLabelPicker(true)}
          ></div>
          <span>(titulky v levém panelu)</span>
          <label>Barva 6:</label>
          <div
            css={css`
              position: relative;
            `}
          >
            {pickerColor6 === true && (
              <div
                css={css`
                  position: absolute;
                  z-index: 2;
                  top: 40px;
                `}
              >
                <div
                  css={css`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  `}
                  onClick={() => setPickerColor6(false)}
                ></div>
                <SketchPicker
                  color={color6}
                  onChange={(e) => {
                    setColor6(e.hex)
                  }}
                />
              </div>
            )}
            <input
              type='text'
              name='color'
              value={color6}
              onClick={() => setPickerColor6(true)}
              onChange={(e) => setColor6(e.target.value)}
            />
          </div>
          <div
            css={css`
              width: 20px;
              height: 20px;
              background: ${color6};
              cursor: pointer;
            `}
            onClick={() => setPickerColor6(true)}
          ></div>
          <span>(text v hlavičce, text v patičce)</span>
          <div
            onClick={setOriginColors}
            css={css`
              grid-column-start: 1;
              grid-column-end: 4;
              cursor: pointer;
              font-size: 13px;
              text-transform: uppercase;
              font-weight: 600;
              text-decoration: underline;
              &:hover {
                color: #fff;
              }
            `}
          >
            Vrátit výchozí barvy
          </div>
          <input
            type='submit'
            value='Upravit barevné schéma'
            css={css`
              grid-column-start: 1;
              grid-column-end: 4;
              cursor: pointer;
              &:hover {
                color: white;
                background: ${colors.green};
              }
            `}
          />
        </form>
      )}
    </>
  )
}
