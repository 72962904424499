import React, { useEffect, useState } from "react"
import * as THREE from "three"
import { useThree } from "react-three-fiber"
import FloorTexture from "../../../img/floor.png"
import WallTexture from "../../../img/wall256.jpeg"
import ChromeTexture from "../../../img/chrome3.jpg"
import { useXR } from "@react-three/xr"
import { Reflector } from "@react-three/drei"
import OpenSans from "../../../fonts/OpenSans_Regular.json"

function Scene(props) {
  const prm = props.prm
  const setPrm = props.setPrm
  const textureURL = props.textureURL
  const textureFrameURL = props.textureFrameURL
  const location = props.location
  const doorsTextures = props.doorsTextures

  const sumOfParts = prm.parts.reduce((a, b) => a + b, 0)

  const [texture, setTexture] = useState()
  const [textureFrame, setTextureFrame] = useState()
  const [doorsTexturesMap, setDoorsTexturesMap] = useState(Array(sumOfParts).fill([]))
  const [floor] = useState(new THREE.TextureLoader().load(FloorTexture))
  const [wall] = useState(new THREE.TextureLoader().load(WallTexture))
  const [chrome] = useState(new THREE.TextureLoader().load(ChromeTexture))

  let chromeMap = chrome
  chromeMap.wrapS = chromeMap.wrapT = THREE.RepeatWrapping
  chromeMap.repeat.set(1, 1)

  const { player, isPresenting } = useXR()

  useEffect(() => {
    if (isPresenting) {
      player.position.z = 2
      player.position.y = -1
    }
  }, [isPresenting, player.position.y, player.position.z])

  useEffect(() => {
    const doorsTexturesMap = Array(sumOfParts)
      .fill(1)
      .map((el, i) => {
        return Array(doorsTextures[i].length)
          .fill(1)
          .map((el, j) => {
            if (doorsTextures[i][j] !== ``)
              return new THREE.TextureLoader().load(doorsTextures[i][j] + `?cacheblock=true`)
            else return ``
          })
      })
    setDoorsTexturesMap(doorsTexturesMap)
  }, [doorsTextures, sumOfParts])

  useEffect(() => {
    if (textureURL !== null)
      setTexture(new THREE.TextureLoader().load(textureURL + `?cacheblock=true`))
  }, [textureURL])

  useEffect(() => {
    if (textureFrameURL !== null)
      setTextureFrame(new THREE.TextureLoader().load(textureFrameURL + `?cacheblock=true`))
  }, [textureFrameURL])

  const {
    camera,
    gl: { domElement }
  } = useThree()

  const Floor = (props) => {
    let floorMap = floor
    floorMap.wrapS = floorMap.wrapT = THREE.RepeatWrapping
    floorMap.repeat.set(50, 50)
    return (
      <mesh position={props.position}>
        <boxBufferGeometry attach='geometry' args={props.size} />
        <meshStandardMaterial attach='material' map={floorMap} />
      </mesh>
    )
  }

  const Wall = (props) => {
    let wallMap = wall
    wallMap.wrapS = wallMap.wrapT = THREE.RepeatWrapping
    wallMap.repeat.set(100, 100)
    return (
      <mesh position={props.position}>
        <boxBufferGeometry attach='geometry' args={props.size} />
        <meshStandardMaterial attach='material' map={wallMap} />
      </mesh>
    )
  }

  const Text = (props) => {
    const font = new THREE.FontLoader().parse(OpenSans)
    return (
      <mesh position={props.position} rotation={props.rotation}>
        <textGeometry
          attach='geometry'
          args={[
            props.text,
            {
              font,
              size: props.size ? props.size : 0.1,
              height: 0.007
            }
          ]}
        />
        <meshStandardMaterial attach='material' color={`#A1317B`} />
      </mesh>
    )
  }

  const Cube = (props) => {
    let color
    if (props.color === prm.currentColor) color = prm.currentColor
    else if (
      (prm.whiteInside === 1 &&
        !props.notInner &&
        props.part !== `doorsSlot` &&
        props.part !== `doorsFrame`) ||
      (!props.notInner && props.part === `backDesk`)
    )
      color = `#ffffff`
    else {
      if (props.part === `doorsFrame`) color = prm.doorsFrameColor
      else color = prm.color
    }

    const color2 =
      props.part === `doorsSlot` ||
      (prm.showCurrent === true &&
        props.order === prm.currentDoorPart &&
        props.part === `doorsFrame` &&
        !location.endsWith(`/summary`)) ||
      (prm.showCurrent === true &&
        props.order === prm.currentPart &&
        (location.endsWith(`/dimensions`) ||
          location.endsWith(`/parts`) ||
          location.endsWith(`/colors`)))
        ? color
        : null

    let colorMap
    let map

    if (props.part === `doorsFrame`) {
      if (textureFrameURL === null) {
        map = null
        colorMap = color
      } else {
        map = textureFrame
        colorMap = color2
      }
    } else if (props.part === `doorsSlot`) {
      if (prm.doorsColors[props.order][props.slot] === ``) {
        map = props.map
        colorMap = null
      } else {
        map = null
        colorMap = prm.doorsColors[props.order][props.slot]
      }
    } else {
      if (textureURL === null) {
        map = null
        colorMap = color
      } else {
        map = texture // new THREE.TextureLoader().load(textureURL + `?cacheblock=true`)
        //prepare different rotation for different parts
        colorMap = color2
      }
    }

    if (props.part === `currentDoorSlot`) {
      if (prm.currentColor === `#7ed321`) colorMap = `#ffa000`
      else {
        colorMap = `#7ed321`
      }
    }

    if (map !== null && map && props.part === `doorsSlot`) {
      map.wrapS = map.wrapT = THREE.RepeatWrapping
      let timesToRepeatHorizontally
      let timesToRepeatVertically
      if (props.size[2] < 0.05) {
        timesToRepeatHorizontally = props.size[0] / 0.5
        timesToRepeatVertically = props.size[1] / 0.5
      } else if (props.size[1] < 0.05) {
        timesToRepeatHorizontally = props.size[0] / 0.5
        timesToRepeatVertically = props.size[2] / 0.5
      } else if (props.size[0] < 0.05) {
        timesToRepeatHorizontally = props.size[2] / 0.5
        timesToRepeatVertically = props.size[1] / 0.5
      }
      map.repeat.set(timesToRepeatHorizontally, timesToRepeatVertically)
    }

    /*    if (map && !props.mapVertical) { // prepare different rotation for different parts
    map.rotation = Math.PI / 2
  } else if (map) {
    map.rotation = Math.PI
  }
*/

    if (
      (prm.whiteInside === 1 &&
        !props.notInner &&
        props.part !== `doorsSlot` &&
        props.part !== `doorsFrame`) ||
      (!props.notInner && props.part === `backDesk`)
    )
      map = null

    return (
      <mesh position={props.position} onClick={props.onClick} rotation={props.rotation}>
        <boxBufferGeometry attach='geometry' args={props.size} translate={[3, 0, 0]} />
        <meshStandardMaterial
          attach='material'
          colorWrite={props.colorWrite}
          color={colorMap}
          map={map}
          transparent={props.transparent === 1 ? true : false}
          opacity={props.transparent === 1 ? 0 : 1}
        />
      </mesh>
    )
  }

  const Cylinder = (props) => {
    return (
      <mesh position={props.position} rotation={[0, 0, Math.PI / 2]}>
        <cylinderGeometry attach='geometry' args={[0.015, 0.015, props.width, 32]} />
        <meshStandardMaterial attach='material' color={props.color} map={props.map} />
      </mesh>
    )
  }

  const RoundedShelf = (props) => {
    let color
    if (props.color === prm.currentColor) color = prm.currentColor
    else if (prm.whiteInside === 1 && !props.notInner) color = `#ffffff`
    else {
      color = prm.color
    }

    const color2 =
      props.part === `doorsSlot` ||
      (prm.showCurrent === true &&
        props.order === prm.currentPart &&
        (location.endsWith(`/dimensions`) ||
          location.endsWith(`/parts`) ||
          location.endsWith(`/colors`)))
        ? color
        : null

    let colorMap
    let map

    if (textureURL === null || props.part === `doorsSlot`) map = null
    else {
      map = texture
    }

    if (textureURL === null) colorMap = color
    else {
      colorMap = color2
    }

    let sizeX = prm.roundedWidth
    let sizeY = prm.depth
    let radius = 0.2

    let halfX = sizeX * 0.5 - radius
    let halfY = sizeY * 0.5 - radius
    let baseAngle = Math.PI * 0.5

    let shape = new THREE.Shape()

    if (prm.roundedType === 1) {
      shape.absarc(
        sizeX / 2 + halfX,
        sizeY / 2 + halfY,
        radius,
        baseAngle * 0,
        baseAngle * 0 + baseAngle
      )
      shape.lineTo(0, sizeY / 2 + sizeY / 2)
      shape.lineTo(0, 0)
      shape.lineTo(sizeX / 2 + sizeX / 2, 0)
    } else if (prm.roundedType === 2) {
      shape.moveTo(0, 0)
      shape.lineTo(sizeX, 0)
      shape.lineTo(sizeX, sizeY - sizeX / 2)
      shape.lineTo(sizeX, sizeY - sizeX / 2)
      shape.lineTo(sizeX / 2, sizeY)
      shape.lineTo(0, sizeY)
      shape.lineTo(0, 0)
    }

    return (
      <mesh
        position={[
          props.xPosition + (props.side === `right` ? -sizeX : sizeX) / 2,
          props.yPosition - (props.side === `right` ? 0 : prm.deskWidth),
          -prm.depth / 2
        ]}
        rotation={[Math.PI / 2, props.side === `right` ? 0 : Math.PI, 0]}
        onClick={props.onClick}
      >
        <extrudeBufferGeometry
          attach='geometry'
          args={[
            shape,
            { bevelEnabled: false, depth: props.deskWidth ? props.deskWidth : prm.deskWidth }
          ]}
        />
        <meshStandardMaterial attach='material' color={colorMap} map={map} />
      </mesh>
    )
  }

  const modelStartLeft = -(sumOfParts * prm.width) / 2
  const realHeight = prm.showTopSpace
    ? prm.height - prm.topSpaceHeight
    : prm.height - (prm.bottomSokl === 1 ? prm.soklHeight : 0)
  const modelStartTop = prm.showTopSpace ? prm.height / 2 - prm.topSpaceHeight : prm.height / 2

  const whereAreReverseDoors = []

  prm.parts.map((value, i) => {
    if (value === 2) {
      return whereAreReverseDoors.push(prm.parts.slice(0, i + 1).reduce((a, b) => a + b, 0))
    } else {
      return null
    }
  })

  prm.openDoorsRight.map((value, i) => {
    if (value === true) return whereAreReverseDoors.push(i + 1)
    else {
      return null
    }
  })

  const basicWidth = sumOfParts * prm.width
  const roundedPartsWidth = (prm.roundedLeft + prm.roundedRight) * prm.roundedWidth
  const availableSpace = basicWidth - roundedPartsWidth
  const partsWithoutRounded = sumOfParts - prm.roundedLeft - prm.roundedRight
  const singlePartWidth = availableSpace / partsWithoutRounded

  return (
    <>
      {Array(parseInt(prm.parts.length))
        .fill(1)
        .map((el, i) => {
          const color =
            (prm.currentPart !== i && prm.showCurrent) ||
            !prm.showCurrent ||
            (location.endsWith(`/doors`) && prm.showDoors) ||
            location.endsWith(`/summary`)
              ? props.color
              : prm.currentColor
          const setCurrentPart = (e) => {
            if (!location.endsWith(`/summary`)) {
              setPrm({
                ...prm,
                currentPart: i
              })
              e.stopPropagation()
            }
          }
          const numberOfSlots = prm.config[i].length
          const currentPartsFromLeft = prm.parts.slice(0, i).reduce((a, b) => a + b, 0)

          const partWidth = singlePartWidth * prm.parts[i]

          const xPositionLeftPart = () => {
            if (i !== 0) {
              if (prm.roundedLeft === 1) return prm.roundedWidth
              else {
                return singlePartWidth
              }
            } else {
              return 0
            }
          }

          const xPositionMiddleParts = () => {
            const value = (currentPartsFromLeft - 1) * singlePartWidth
            if (isNaN(value) || value < 0) {
              return 0
            } else {
              return value
            }
          }

          const currentPartHalf = () => {
            if (
              (prm.roundedLeft === 1 && i === 0) ||
              (prm.roundedRight === 1 && i === prm.parts.length - 1)
            )
              return prm.roundedWidth / 2
            else {
              return partWidth / 2
            }
          }

          const xPosition =
            modelStartLeft + xPositionLeftPart() + xPositionMiddleParts() + currentPartHalf()

          let sumOfSlots = 0
          prm.config[i].map((x, k) => {
            if (Number.isInteger(x)) {
              sumOfSlots = sumOfSlots + x
            } else {
              const arraySum = prm.config[i][k][0]
                .slice(0, prm.config[i][k][0].length + 1)
                .reduce((a, b) => a + b, 0)
              sumOfSlots = sumOfSlots + arraySum
            }
            return true
          })

          return (
            <React.Fragment key={i}>
              {((i === 0 && prm.roundedLeft === 0) ||
                (i !== 0 && i !== prm.parts.length - 1) ||
                (i === prm.parts.length - 1 && prm.roundedRight === 0)) && (
                <>
                  {prm.topDesk === 1 && (
                    <Cube // top desk
                      size={[partWidth, prm.deskWidth, prm.depth]}
                      position={[xPosition, 0.5 - prm.deskWidth / 2 + (prm.height - 1) * 0.5, 0]}
                      color={color}
                      order={i}
                      onClick={setCurrentPart}
                      notInner
                    />
                  )}
                  {prm.doorsType === 1 && prm.topDesk === 0 && (
                    <Cube // top lišta
                      size={[partWidth, prm.deskWidth, 0.1]}
                      position={[
                        xPosition,
                        prm.height / 2 - prm.deskWidth / 2 + 0.001,
                        prm.depth / 2 - 0.05
                      ]}
                      color={color}
                      order={i}
                      onClick={setCurrentPart}
                      notInner
                    />
                  )}
                  {prm.topDesk === 1 && (
                    <Cube // top desk notInner
                      size={[partWidth, 0.0001, prm.depth + prm.deskWidth]}
                      position={[
                        xPosition,
                        0.5 + (prm.height - 1) * 0.5 + 0.001,
                        0 - prm.deskWidth / 2
                      ]}
                      color={prm.color}
                      notInner
                    />
                  )}
                </>
              )}
              {prm.topDesk === 1 && i === prm.parts.length - 1 && prm.roundedRight === 1 && (
                <RoundedShelf // top rounded shelf right
                  xPosition={xPosition + prm.deskWidth / 2}
                  yPosition={0.5 + (prm.height - 1) * 0.5}
                  order={i}
                  onClick={setCurrentPart}
                  color={color}
                  side='right'
                  notInner
                />
              )}
              {prm.topDesk === 1 && i === 0 && prm.roundedLeft === 1 && (
                <RoundedShelf // top rounded shelf left
                  xPosition={xPosition - prm.deskWidth / 2}
                  yPosition={0.5 + (prm.height - 1) * 0.5}
                  order={i}
                  onClick={setCurrentPart}
                  color={color}
                  side='left'
                  notInner
                />
              )}
              {((i === 0 && prm.roundedLeft === 0) ||
                (i !== 0 && i !== prm.parts.length - 1) ||
                (i === prm.parts.length - 1 && prm.roundedRight === 0)) && (
                <>
                  {prm.showDimensions && (
                    <Text
                      position={[
                        xPosition - partWidth / 2,
                        -prm.height / 2 + 0.01,
                        prm.depth / 2 + 0.2
                      ]}
                      rotation={[-Math.PI / 2, 0, 0]}
                      text={`${(100 * Math.round(partWidth * 1000)) / 1000}`}
                      size={0.12}
                    />
                  )}
                  {prm.bottomDesk === 1 && (
                    <Cube // bottom desk
                      size={[
                        partWidth,
                        prm.bottomSokl === 1 ? prm.soklHeight : prm.deskWidth,
                        prm.depth
                      ]}
                      position={[
                        xPosition,
                        -prm.height / 2 +
                          (prm.bottomSokl === 1 ? prm.soklHeight : prm.deskWidth) / 2,
                        0
                      ]}
                      color={color}
                      order={i}
                      onClick={setCurrentPart}
                      notInner
                    />
                  )}
                  {prm.doorsType === 1 && prm.bottomDesk === 0 && (
                    <Cube // bottom lišta
                      size={[partWidth, prm.deskWidth, 0.1]}
                      position={[
                        xPosition,
                        -prm.height / 2 + prm.deskWidth / 2,
                        prm.depth / 2 - 0.05
                      ]}
                      color={color}
                      order={i}
                      onClick={setCurrentPart}
                      notInner
                    />
                  )}
                </>
              )}
              {prm.bottomDesk === 1 && i === prm.parts.length - 1 && prm.roundedRight === 1 && (
                <>
                  {prm.showDimensions && (
                    <Text
                      position={[
                        xPosition - prm.roundedWidth / 2,
                        -prm.height / 2 + 0.01,
                        prm.depth / 2 + 0.2
                      ]}
                      rotation={[-Math.PI / 2, 0, 0]}
                      text={`${(100 * Math.round(prm.roundedWidth * 1000)) / 1000}`}
                      size={0.12}
                    />
                  )}
                  <RoundedShelf // bottom rounded shelf right
                    xPosition={xPosition + prm.deskWidth / 2}
                    yPosition={
                      -prm.height / 2 + (prm.bottomSokl === 1 ? prm.soklHeight : prm.deskWidth)
                    }
                    order={i}
                    onClick={setCurrentPart}
                    color={color}
                    side='right'
                    notInner
                    deskWidth={prm.bottomSokl === 1 ? prm.soklHeight : prm.deskWidth}
                  />
                </>
              )}
              {prm.bottomDesk === 1 && i === 0 && prm.roundedLeft === 1 && (
                <>
                  {prm.showDimensions && (
                    <Text
                      position={[
                        xPosition - prm.roundedWidth / 2,
                        -prm.height / 2 + 0.01,
                        prm.depth / 2 + 0.2
                      ]}
                      rotation={[-Math.PI / 2, 0, 0]}
                      text={`${(100 * Math.round(prm.roundedWidth * 1000)) / 1000}`}
                      size={0.12}
                    />
                  )}
                  <RoundedShelf // bottom rounded shelf left
                    xPosition={xPosition - prm.deskWidth / 2}
                    yPosition={-prm.height / 2 + (prm.bottomSokl === 1 ? 0 : prm.deskWidth)}
                    order={i}
                    onClick={setCurrentPart}
                    color={color}
                    side='left'
                    notInner
                    deskWidth={prm.bottomSokl === 1 ? prm.soklHeight : prm.deskWidth}
                  />
                </>
              )}
              {prm.showTopSpace &&
                ((i === 0 && prm.roundedLeft === 0) ||
                  (i !== 0 && i !== prm.parts.length - 1) ||
                  (i === prm.parts.length - 1 && prm.roundedRight === 0)) && (
                  <Cube // top space shelf
                    size={[partWidth, prm.deskWidth, prm.depth - prm.deskWidth / 2]}
                    position={[xPosition, prm.height / 2 - prm.topSpaceHeight, 0]}
                    color={color}
                    order={i}
                    onClick={setCurrentPart}
                  />
                )}
              {(i !== 0 || (prm.leftDesk === 1 && i === 0)) && (
                <Cube // left desk
                  size={[
                    i === 0 ? prm.deskWidth : prm.deskWidth / 2,
                    (i === 1 && prm.roundedLeft === 1 && prm.showTopSpace) ||
                    (i === sumOfParts - 1 && prm.roundedRight === 1 && prm.showTopSpace) ||
                    !prm.showTopSpace ||
                    i === 0
                      ? prm.height
                      : prm.height - prm.deskWidth - prm.topSpaceHeight,
                    prm.depth
                  ]}
                  position={[
                    xPosition - currentPartHalf() + prm.deskWidth / (i === 0 ? 2 : 4),
                    (i === 1 && prm.roundedLeft === 1 && prm.showTopSpace) ||
                    (i === sumOfParts - 1 && prm.roundedRight === 1 && prm.showTopSpace) ||
                    !prm.showTopSpace ||
                    i === 0
                      ? 0
                      : -prm.topSpaceHeight / 2 + prm.deskWidth / 2,
                    0
                  ]}
                  color={color}
                  order={i}
                  onClick={setCurrentPart}
                  mapVertical
                  notInner={
                    i === 0 ||
                    (i === 1 && prm.roundedLeft === 1) ||
                    (i === prm.parts.length - 1 && prm.roundedRight === 1)
                      ? true
                      : false
                  }
                />
              )}
              {(i !== prm.parts.length - 1 ||
                (prm.rightDesk === 1 && i === prm.parts.length - 1)) && (
                <Cube // right desk
                  size={[
                    i === prm.parts.length - 1 ? prm.deskWidth : prm.deskWidth / 2,
                    (i === 0 && prm.roundedLeft === 1 && prm.showTopSpace) ||
                    (i === sumOfParts - 2 && prm.roundedRight === 1 && prm.showTopSpace) ||
                    !prm.showTopSpace ||
                    i === sumOfParts - 1
                      ? prm.height
                      : prm.height - prm.deskWidth - prm.topSpaceHeight,
                    prm.depth
                  ]}
                  position={[
                    xPosition +
                      currentPartHalf() -
                      prm.deskWidth / (i === prm.parts.length - 1 ? 2 : 4),
                    (i === 0 && prm.roundedLeft === 1 && prm.showTopSpace) ||
                    (i === sumOfParts - 2 && prm.roundedRight === 1 && prm.showTopSpace) ||
                    !prm.showTopSpace ||
                    i === sumOfParts - 1
                      ? 0
                      : -prm.topSpaceHeight / 2 + prm.deskWidth / 2,
                    0
                  ]}
                  color={color}
                  order={i}
                  onClick={setCurrentPart}
                  mapVertical
                  notInner={
                    (i === 0 && prm.roundedLeft === 1) ||
                    i === prm.parts.length - 1 ||
                    (i === prm.parts.length - 2 && prm.roundedRight === 1)
                      ? true
                      : false
                  }
                />
              )}
              <Cube // back desk
                size={[
                  (i === 0 && prm.roundedLeft === 0) ||
                  (i !== 0 && i !== prm.parts.length - 1) ||
                  (i === prm.parts.length - 1 && prm.roundedRight === 0)
                    ? partWidth
                    : prm.roundedWidth + prm.deskWidth - 0.0001,
                  prm.height,
                  prm.deskWidth
                ]}
                position={[xPosition, 0, -prm.depth / 2 + prm.deskWidth / 2]}
                color={color}
                order={i}
                onClick={setCurrentPart}
                notInner={
                  (i === 0 && prm.roundedLeft === 1) ||
                  (i === prm.parts.length - 1 && prm.roundedRight === 1)
                    ? true
                    : false
                }
                part={`backDesk`}
                mapVertical
                transparent={
                  (i === 0 && prm.roundedLeft === 1) ||
                  (i === prm.parts.length - 1 && prm.roundedRight === 1)
                    ? 0
                    : prm.backDesk === 1
                    ? 0
                    : 1
                }
              />
              ))}
              {Array(numberOfSlots)
                .fill(1)
                .map((el, j) => {
                  let numberOfPartsFromTop = 0
                  prm.config[i].map((x, k) => {
                    if (k < j + 1) {
                      if (Number.isInteger(x)) {
                        numberOfPartsFromTop = numberOfPartsFromTop + x
                      } else {
                        const arraySum = prm.config[i][k][0]
                          .slice(0, prm.config[i][k][0].length + 1)
                          .reduce((a, b) => a + b, 0)
                        numberOfPartsFromTop = numberOfPartsFromTop + arraySum
                      }
                    }
                    return true
                  })
                  const verticalPartHeight = (prm.config[i][j] / sumOfSlots) * realHeight
                  const heightOfOnePart = realHeight / sumOfSlots
                  const verticalPartHeightWithoutShelves =
                    ((realHeight - (prm.config[i].length + 1) * prm.deskWidth) / sumOfSlots) *
                    100 *
                    prm.config[i][j]
                  const arraySize = () => {
                    if (Array.isArray(prm.config[i][j]))
                      return prm.config[i][j][0]
                        .slice(0, prm.config[i][j][0].length + 1)
                        .reduce((a, b) => a + b, 0)
                    else return false
                  }
                  let leftArray = []
                  let rightArray = []
                  let halfPartsLayout
                  let leftContent = []
                  let rightContent = []
                  let xPositionMiddleDesk
                  if (Array.isArray(prm.config[i][j])) {
                    leftArray = prm.config[i][j][0]
                    rightArray = prm.config[i][j][1]
                    halfPartsLayout = prm.config[i][j][3]
                    leftContent = prm.content[i][j][0]
                    rightContent = prm.content[i][j][1]
                    if (halfPartsLayout === 2) xPositionMiddleDesk = xPosition + (1 / 6) * partWidth
                    else if (halfPartsLayout === 1)
                      xPositionMiddleDesk = xPosition - (1 / 6) * partWidth
                    else xPositionMiddleDesk = xPosition
                  }
                  return (
                    <React.Fragment key={j}>
                      {prm.content[i][j] === 1 && (
                        <Cube // drawer
                          size={[partWidth, verticalPartHeight - 0.05, prm.deskWidth]}
                          position={[
                            xPosition,
                            modelStartTop -
                              numberOfPartsFromTop * heightOfOnePart +
                              verticalPartHeight / 2 -
                              0.025,
                            prm.depth / 2 - 0.03
                          ]}
                          color={color}
                          order={i}
                          onClick={setCurrentPart}
                        />
                      )}
                      {prm.content[i][j] === 2 && (
                        <Cylinder // hanger holder
                          width={partWidth - 0.001}
                          position={[
                            xPosition,
                            modelStartTop -
                              numberOfPartsFromTop * heightOfOnePart +
                              verticalPartHeight -
                              0.065,
                            0
                          ]}
                          map={chromeMap}
                          order={i}
                        />
                      )}
                      {!Array.isArray(prm.config[i][j]) &&
                        ((i === 0 && prm.roundedLeft === 0) ||
                          (i !== 0 && i !== prm.parts.length - 1) ||
                          (i === prm.parts.length - 1 && prm.roundedRight === 0)) &&
                        prm.showDimensions && (
                          <Text
                            position={[
                              xPosition - partWidth / 2 + 0.02,
                              modelStartTop - numberOfPartsFromTop * heightOfOnePart + 0.02,
                              prm.depth / 2
                            ]}
                            rotation={[0, 0, 0]}
                            text={`${(100 * Math.round(verticalPartHeightWithoutShelves * 10)) /
                              1000}`}
                          />
                        )}
                      {j !== numberOfSlots - 1 &&
                        !Array.isArray(prm.config[i][j + 1]) &&
                        ((i === 0 && prm.roundedLeft === 0) ||
                          (i !== 0 && i !== prm.parts.length - 1) ||
                          (i === prm.parts.length - 1 && prm.roundedRight === 0)) && (
                          <>
                            <Cube // shelf
                              size={[partWidth - 0.001, prm.deskWidth, prm.depth - prm.deskWidth]}
                              position={[
                                xPosition,
                                modelStartTop - numberOfPartsFromTop * heightOfOnePart,
                                0
                              ]}
                              color={color}
                              order={i}
                              onClick={setCurrentPart}
                            />
                          </>
                        )}
                      {j !== numberOfSlots - 1 &&
                        prm.roundedRight === 1 &&
                        i === prm.parts.length - 1 && (
                          <>
                            {prm.showDimensions && (
                              <Text
                                position={[
                                  xPosition - prm.roundedWidth / 2 + 0.02,
                                  modelStartTop - numberOfPartsFromTop * heightOfOnePart + 0.02,
                                  prm.depth / 2
                                ]}
                                rotation={[0, 0, 0]}
                                text={`${(100 * Math.round(verticalPartHeightWithoutShelves * 10)) /
                                  1000}`}
                              />
                            )}
                            <RoundedShelf // rounded shelf right
                              xPosition={xPosition + prm.deskWidth / 2}
                              yPosition={
                                modelStartTop -
                                numberOfPartsFromTop * heightOfOnePart +
                                prm.deskWidth / 2
                              }
                              order={i}
                              onClick={setCurrentPart}
                              color={color}
                              side='right'
                              notInner
                            />
                          </>
                        )}
                      {j !== numberOfSlots - 1 && prm.roundedLeft === 1 && i === 0 && (
                        <>
                          {prm.showDimensions && (
                            <Text
                              position={[
                                xPosition - prm.roundedWidth / 2 + 0.02,
                                modelStartTop - numberOfPartsFromTop * heightOfOnePart + 0.02,
                                prm.depth / 2
                              ]}
                              rotation={[0, 0, 0]}
                              text={`${(100 * Math.round(verticalPartHeightWithoutShelves * 10)) /
                                1000}`}
                            />
                          )}
                          <RoundedShelf // rounded shelf left
                            xPosition={xPosition - prm.deskWidth / 2}
                            yPosition={
                              modelStartTop -
                              numberOfPartsFromTop * heightOfOnePart +
                              prm.deskWidth / 2
                            }
                            order={i}
                            onClick={setCurrentPart}
                            color={color}
                            side='left'
                            notInner
                          />
                        </>
                      )}
                      {Array.isArray(prm.config[i][j]) && (
                        <>
                          <Cube // middle desk
                            size={[
                              prm.deskWidth / 2,
                              (arraySize() / sumOfSlots) * realHeight,
                              prm.depth * 0.99
                            ]}
                            position={[
                              xPositionMiddleDesk,
                              modelStartTop -
                                ((arraySize() / sumOfSlots) * realHeight) / 2 -
                                numberOfPartsFromTop * heightOfOnePart +
                                (arraySize() / sumOfSlots) * realHeight,
                              0 - 0.01
                            ]}
                            color={color}
                            order={i}
                            onClick={setCurrentPart}
                            mapVertical
                          />
                          {leftArray.map((el, l) => {
                            const halfPartsFromBottom = leftArray
                              .slice(l, leftArray.length + 1)
                              .reduce((a, b) => a + b, 0)
                            const halfPartsFromBottomMinus1 = leftArray
                              .slice(l + 1, leftArray.length + 1)
                              .reduce((a, b) => a + b, 0)
                            const verticalPartHeight = (leftArray[l] / sumOfSlots) * realHeight
                            let widthLeftPart
                            let xPositionLeftPart
                            if (halfPartsLayout === 2) {
                              widthLeftPart = partWidth * (2 / 3)
                              xPositionLeftPart = partWidth * (1 / 6)
                            } else if (halfPartsLayout === 1) {
                              widthLeftPart = partWidth * (1 / 3)
                              xPositionLeftPart = partWidth * (1 / 3)
                            } else {
                              widthLeftPart = partWidth / 2
                              xPositionLeftPart = partWidth / 4
                            }
                            const sizeOfOnePart =
                              ((realHeight - (prm.config[i].length + 1) * prm.deskWidth) /
                                sumOfSlots) *
                              100
                            return (
                              <React.Fragment key={l}>
                                {prm.showDimensions && (
                                  <Text
                                    position={[
                                      xPosition - partWidth / 2 + 0.02,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottomMinus1 * heightOfOnePart +
                                        0.02,
                                      prm.depth / 2
                                    ]}
                                    rotation={[0, 0, 0]}
                                    text={`${(100 * Math.round(el * sizeOfOnePart * 10)) / 1000}`}
                                    size={0.08}
                                  />
                                )}
                                {(l !== 0 || prm.config[i][j][2][0]) && (
                                  <Cube // half-shelf
                                    size={[widthLeftPart - 0.001, prm.deskWidth, prm.depth * 0.99]}
                                    position={[
                                      xPosition - xPositionLeftPart + prm.deskWidth / 4,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottom * heightOfOnePart,
                                      0 - 0.01
                                    ]}
                                    color={color}
                                    order={i}
                                    onClick={setCurrentPart}
                                  />
                                )}
                                {leftContent[l] === 2 && (
                                  <Cylinder // hanger holder
                                    width={widthLeftPart - 0.001}
                                    position={[
                                      xPosition - xPositionLeftPart,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottom * heightOfOnePart -
                                        0.065,
                                      0
                                    ]}
                                    map={chromeMap}
                                    order={i}
                                  />
                                )}
                                {leftContent[l] === 1 && (
                                  <Cube // drawer
                                    size={[widthLeftPart, verticalPartHeight - 0.05, prm.deskWidth]}
                                    position={[
                                      xPosition - xPositionLeftPart,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottom * heightOfOnePart -
                                        verticalPartHeight / 2 -
                                        0.025,
                                      prm.depth / 2 - 0.03
                                    ]}
                                    color={color}
                                    order={i}
                                    onClick={setCurrentPart}
                                  />
                                )}
                              </React.Fragment>
                            )
                          })}
                          {rightArray.map((el, l) => {
                            const halfPartsFromBottom = rightArray
                              .slice(l, rightArray.length + 1)
                              .reduce((a, b) => a + b, 0)
                            const halfPartsFromBottomMinus1 = rightArray
                              .slice(l + 1, rightArray.length + 1)
                              .reduce((a, b) => a + b, 0)
                            const verticalPartHeight = (rightArray[l] / sumOfSlots) * realHeight
                            let widthRightPart
                            let xPositionRightPart
                            if (halfPartsLayout === 2) {
                              widthRightPart = partWidth * (1 / 3)
                              xPositionRightPart = partWidth / 3
                            } else if (halfPartsLayout === 1) {
                              widthRightPart = partWidth * (2 / 3)
                              xPositionRightPart = partWidth / 6
                            } else {
                              widthRightPart = partWidth / 2
                              xPositionRightPart = partWidth / 4
                            }
                            const sizeOfOnePart =
                              ((realHeight - (prm.config[i].length + 1) * prm.deskWidth) /
                                sumOfSlots) *
                              100
                            return (
                              <React.Fragment key={l}>
                                {prm.showDimensions && (
                                  <Text
                                    position={[
                                      xPosition + 0.02,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottomMinus1 * heightOfOnePart +
                                        0.02,
                                      prm.depth / 2
                                    ]}
                                    rotation={[0, 0, 0]}
                                    text={`${(100 * Math.round(el * sizeOfOnePart * 10)) / 1000}`}
                                    size={0.08}
                                  />
                                )}
                                {(l !== 0 || prm.config[i][j][2][1]) && (
                                  <Cube // half-shelf
                                    size={[widthRightPart - 0.001, prm.deskWidth, prm.depth * 0.99]}
                                    position={[
                                      xPosition + xPositionRightPart - prm.deskWidth / 4,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottom * heightOfOnePart,
                                      0 - 0.01
                                    ]}
                                    color={color}
                                    order={i}
                                    onClick={setCurrentPart}
                                  />
                                )}
                                {rightContent[l] === 2 && (
                                  <Cylinder // hanger holder
                                    width={widthRightPart - 0.001}
                                    position={[
                                      xPosition + xPositionRightPart,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottom * heightOfOnePart -
                                        0.065,
                                      0
                                    ]}
                                    map={chromeMap}
                                    order={i}
                                  />
                                )}
                                {rightContent[l] === 1 && (
                                  <Cube // drawer
                                    size={[
                                      widthRightPart,
                                      verticalPartHeight - 0.05,
                                      prm.deskWidth
                                    ]}
                                    position={[
                                      xPosition + xPositionRightPart,
                                      modelStartTop -
                                        numberOfPartsFromTop * heightOfOnePart +
                                        halfPartsFromBottom * heightOfOnePart -
                                        verticalPartHeight / 2 -
                                        0.025,
                                      prm.depth / 2 - 0.03
                                    ]}
                                    color={color}
                                    order={i}
                                    onClick={setCurrentPart}
                                  />
                                )}
                              </React.Fragment>
                            )
                          })}
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
            </React.Fragment>
          )
        })}
      {Array(sumOfParts)
        .fill(1)
        .map((el, i) => {
          const xPositionLeftPart = () => {
            if (i !== 0) {
              if (prm.roundedLeft === 1) return prm.roundedWidth
              else {
                return singlePartWidth
              }
            } else {
              return 0
            }
          }

          const xPositionMiddleParts = () => {
            const value = (i - 1) * singlePartWidth
            if (value < 0) {
              return 0
            } else {
              return value
            }
          }

          const currentPartHalf = () => {
            if (
              (prm.roundedLeft === 1 && i === 0) ||
              (prm.roundedRight === 1 && i === prm.parts.length - 1)
            )
              return prm.roundedWidth / 2
            else {
              return singlePartWidth / 2
            }
          }

          const xPosition =
            modelStartLeft + xPositionLeftPart() + xPositionMiddleParts() + currentPartHalf()
          const doorsHeight = prm.height - prm.bottomSokl * prm.soklHeight

          const doorsFrameColor =
            prm.currentDoorPart === i && prm.showCurrent === true && !location.endsWith(`/summary`)
              ? prm.currentColor
              : prm.doorsFrameColor
          const numberOfDoorSlots = prm.doorsSlots[i].length
          const sumOfDoorSlots = prm.doorsSlots[i].reduce((a, b) => a + b, 0)

          const openXPosition = () => {
            if (prm.openDoors && prm.doorsType === 0) {
              if (whereAreReverseDoors.includes(i + 1)) return -0.134 * (singlePartWidth / 2)
              else return 0.134 * (prm.width / 2)
            } else {
              return 0
            }
          }

          const openZPosition = () => {
            if (prm.openDoors && prm.doorsType === 0) {
              return 0.5 * (singlePartWidth / 2)
            } else {
              return 0
            }
          }

          const openZPositionFix = () => {
            if (prm.openDoors && prm.doorsType === 0) {
              if (i === 0) return 0.003
              else if (i === sumOfParts - 1) return -0.002
              else return 0.00001
            } else {
              return 0.00001
            }
          }

          let slidingZPosition

          const doorsFrameXPosition = () => {
            const goLeft = xPosition - 0.5 * singlePartWidth
            const goRight = xPosition + 0.5 * singlePartWidth
            const stay = xPosition - openXPosition()

            if (prm.doorsType === 1 && prm.openDoors) {
              if (i === 0 + prm.roundedLeft) {
                if (sumOfParts === 2 && prm.roundedLeft === 1) {
                  slidingZPosition = 0.01
                  return goLeft
                } else if (sumOfParts === 3 && prm.roundedLeft === 1 && prm.roundedRight === 1) {
                  slidingZPosition = 0.01
                  return goLeft
                } else if (sumOfParts === 3 && prm.roundedRight === 1) {
                  slidingZPosition = 0
                  return stay
                } else {
                  slidingZPosition = 0.01
                  return goRight
                }
              } else if (i === sumOfParts - 1 - prm.roundedRight) {
                if (
                  i === 1 + prm.roundedLeft &&
                  !(prm.roundedLeft === 0 && prm.roundedRight === 1)
                ) {
                  slidingZPosition = 0
                  return stay
                } else {
                  slidingZPosition = 0.01
                  return goLeft
                }
              } else {
                slidingZPosition = 0
                return stay
              }
            } else {
              slidingZPosition = 0
              return stay
            }
          }

          const setCurrentDoorPart = (e) => {
            if (!location.endsWith(`/summary`) && location.startsWith(`/app`)) {
              setPrm({
                ...prm,
                currentDoorPart: i
              })
              e.stopPropagation()
            }
          }

          return (
            <React.Fragment key={i}>
              {prm.doors === 1 &&
                (location.endsWith(`/doors`) ||
                  location.endsWith(`/summary`) ||
                  location.startsWith(`/admin`) ||
                  location.startsWith(`/order`)) &&
                prm.showDoors &&
                ((i === 0 && prm.roundedLeft === 0) ||
                  (i !== 0 && i !== sumOfParts - 1) ||
                  (i === sumOfParts - 1 && prm.roundedRight === 0)) && (
                  <>
                    {(prm.isFrame === 1 || (prm.isFrame === 0 && prm.currentDoorPart === i)) && (
                      <Cube // doors frame
                        size={[singlePartWidth, doorsHeight, prm.deskWidth]}
                        position={[
                          doorsFrameXPosition(),
                          prm.bottomSokl === 1 ? prm.soklHeight / 2 : 0,
                          prm.depth / 2 + prm.deskWidth / 4 + openZPosition() + slidingZPosition
                        ]}
                        color={doorsFrameColor}
                        order={i}
                        onClick={setCurrentDoorPart}
                        part={`doorsFrame`}
                        rotation={
                          prm.openDoors && prm.doorsType === 0
                            ? [0, whereAreReverseDoors.includes(i + 1) ? 0.5235988 : -0.5235988, 0]
                            : [0, 0, 0]
                        }
                      />
                    )}
                    {prm.isFrame === 0 && prm.doorsColors[i][0] === `mirror` && (
                      <Cube
                        size={[singlePartWidth, doorsHeight, prm.deskWidth]}
                        position={[
                          doorsFrameXPosition(),
                          prm.bottomSokl === 1 ? prm.soklHeight / 2 : 0,
                          prm.depth / 2 + prm.deskWidth / 4 + openZPosition() + slidingZPosition
                        ]}
                        color={prm.color}
                        map={texture}
                        order={i}
                        onClick={setCurrentDoorPart}
                        part={`doorsFrame`}
                        rotation={
                          prm.openDoors && prm.doorsType === 0
                            ? [0, whereAreReverseDoors.includes(i + 1) ? 0.5235988 : -0.5235988, 0]
                            : [0, 0, 0]
                        }
                      />
                    )}
                    {Array(parseInt(numberOfDoorSlots))
                      .fill(1)
                      .map((el, j) => {
                        const setCurrentDoorSlot = (e) => {
                          if (!location.endsWith(`/summary`) && location.startsWith(`/app`)) {
                            const currentDoorSlot = prm.currentDoorSlot.slice()
                            currentDoorSlot[i] = j
                            setPrm({
                              ...prm,
                              currentDoorPart: i,
                              currentDoorSlot
                            })
                            e.stopPropagation()
                          }
                        }

                        let doorsSlotXPosition
                        if (i === 0 && prm.isFrame === 1)
                          doorsSlotXPosition = doorsFrameXPosition() + 0.25 * prm.deskWidth
                        else if (i === sumOfParts - 1 && prm.isFrame === 1)
                          doorsSlotXPosition = doorsFrameXPosition() - 0.25 * prm.deskWidth
                        else doorsSlotXPosition = doorsFrameXPosition()

                        let doorSlotWidth
                        if (prm.isFrame === 1) {
                          if (i === 0 || i === sumOfParts - 1)
                            doorSlotWidth = singlePartWidth - 1.25 * prm.deskWidth
                          else doorSlotWidth = singlePartWidth - prm.deskWidth
                        } else {
                          if (i === 0) doorSlotWidth = singlePartWidth + prm.deskWidth / 4
                          else doorSlotWidth = singlePartWidth
                        }

                        if (
                          prm.isFrame === 0 &&
                          prm.currentDoorPart === i &&
                          !location.endsWith(`summary`)
                        )
                          doorSlotWidth = doorSlotWidth - prm.deskWidth

                        if (
                          prm.doorsColors[i][j] === `mirror` &&
                          prm.openDoors &&
                          prm.doorsType === 0
                        ) {
                          doorsSlotXPosition = doorsSlotXPosition - 0.005
                          doorSlotWidth = doorSlotWidth - 0.005
                        }

                        let slotZCorrector
                        if (prm.isFrame === 1) slotZCorrector = 0.006
                        else slotZCorrector = prm.deskWidth / 2

                        let slotZOpenMirrorCorrector
                        if (prm.isFrame === 0 && prm.doorsType === 0 && prm.openDoors)
                          slotZOpenMirrorCorrector = -0.003
                        else slotZOpenMirrorCorrector = 0

                        const doorSlotHeight = (prm.doorsSlots[i][j] / sumOfDoorSlots) * doorsHeight

                        const cutDoorSlotHeight =
                          doorSlotHeight -
                          (prm.deskWidth * (numberOfDoorSlots + 1)) / numberOfDoorSlots

                        let sumOfSlotsHeightsAbove = 0
                        prm.doorsSlots[i].map((x, k) => {
                          if (k < j) {
                            const doorSlotHeight =
                              (prm.doorsSlots[i][k] / sumOfDoorSlots) * doorsHeight
                            const cutDoorSlotHeight =
                              doorSlotHeight -
                              (prm.deskWidth * (numberOfDoorSlots + 1)) / numberOfDoorSlots
                            sumOfSlotsHeightsAbove = sumOfSlotsHeightsAbove + cutDoorSlotHeight
                          }
                          return true
                        })

                        const doorsSlotYPosition =
                          doorsHeight / 2 -
                          cutDoorSlotHeight / 2 -
                          (j + 1) * prm.deskWidth -
                          sumOfSlotsHeightsAbove +
                          (prm.bottomSokl === 1 ? prm.soklHeight / 2 : 0)

                        if (prm.doorsColors[i][j] !== `mirror`)
                          return (
                            <>
                              {prm.currentDoorPart === i &&
                                prm.currentDoorSlot[i] === j &&
                                prm.doorsSlots[i].length !== 1 &&
                                prm.doorsType !== 0 &&
                                !location.endsWith(`/summary`) &&
                                prm.showCurrent && (
                                  <Cube // current doors slot
                                    size={[
                                      singlePartWidth - 0.001,
                                      (prm.isFrame === 1 ? cutDoorSlotHeight : doorSlotHeight) +
                                        prm.deskWidth * 2,
                                      prm.deskWidth + 0.001
                                    ]}
                                    position={[
                                      doorsFrameXPosition(),
                                      doorsSlotYPosition,
                                      prm.depth / 2 +
                                        openZPositionFix() +
                                        openZPosition() +
                                        slidingZPosition +
                                        0.004
                                    ]}
                                    part='currentDoorSlot'
                                    order={i}
                                    slot={j}
                                    onClick={setCurrentDoorSlot}
                                    key={`j` + j}
                                    rotation={
                                      prm.openDoors && prm.doorsType === 0
                                        ? [
                                            0,
                                            whereAreReverseDoors.includes(i + 1)
                                              ? 0.5235988
                                              : -0.5235988,
                                            0
                                          ]
                                        : [0, 0, 0]
                                    }
                                  />
                                )}
                              <Cube // doors slot
                                size={[
                                  doorSlotWidth,
                                  prm.isFrame === 1 ? cutDoorSlotHeight : doorSlotHeight,
                                  prm.deskWidth + 0.001
                                ]}
                                position={[
                                  doorsSlotXPosition,
                                  doorsSlotYPosition,
                                  prm.depth / 2 +
                                    openZPositionFix() +
                                    openZPosition() +
                                    slidingZPosition +
                                    slotZCorrector
                                ]}
                                color={i > 0 ? `#ff00ff` : `#ffffff`}
                                order={i}
                                slot={j}
                                onClick={setCurrentDoorSlot}
                                part={`doorsSlot`}
                                key={j}
                                rotation={
                                  prm.openDoors && prm.doorsType === 0
                                    ? [
                                        0,
                                        whereAreReverseDoors.includes(i + 1)
                                          ? 0.5235988
                                          : -0.5235988,
                                        0
                                      ]
                                    : [0, 0, 0]
                                }
                                map={doorsTexturesMap[i][j]}
                              />
                            </>
                          )
                        else
                          return (
                            <>
                              {prm.currentDoorPart === i &&
                                prm.currentDoorSlot[i] === j &&
                                prm.doorsSlots[i].length !== 1 &&
                                prm.doorsType !== 0 &&
                                !location.endsWith(`/summary`) &&
                                prm.showCurrent && (
                                  <Cube // current doors slot (copy for the reflector part)
                                    size={[
                                      singlePartWidth - 0.001,
                                      (prm.isFrame === 1 ? cutDoorSlotHeight : doorSlotHeight) +
                                        prm.deskWidth * 2,
                                      prm.deskWidth + 0.001
                                    ]}
                                    position={[
                                      doorsFrameXPosition(),
                                      doorsSlotYPosition,
                                      prm.depth / 2 +
                                        openZPositionFix() +
                                        openZPosition() +
                                        slidingZPosition +
                                        0.004
                                    ]}
                                    part='currentDoorSlot'
                                    order={i}
                                    slot={j}
                                    onClick={setCurrentDoorSlot}
                                    key={`j` + j}
                                    rotation={
                                      prm.openDoors && prm.doorsType === 0
                                        ? [
                                            0,
                                            whereAreReverseDoors.includes(i + 1)
                                              ? 0.5235988
                                              : -0.5235988,
                                            0
                                          ]
                                        : [0, 0, 0]
                                    }
                                  />
                                )}
                              <Reflector
                                resolution={1024}
                                args={[
                                  doorSlotWidth,
                                  prm.isFrame === 1 ? cutDoorSlotHeight : doorSlotHeight
                                ]}
                                mirror={0.85}
                                mixBlur={10}
                                mixStrength={1}
                                blur={[0, 0]}
                                minDepthThreshold={0.8}
                                maxDepthThreshold={1.2}
                                depthScale={0}
                                depthToBlurRatioBias={0.2}
                                debug={0}
                                key={`reflector` + j}
                                position={[
                                  doorsSlotXPosition,
                                  doorsSlotYPosition,
                                  prm.depth / 2 +
                                    openZPositionFix() +
                                    openZPosition() +
                                    slidingZPosition +
                                    slotZCorrector +
                                    slotZOpenMirrorCorrector +
                                    0.012
                                ]}
                                rotation={
                                  prm.openDoors && prm.doorsType === 0
                                    ? [
                                        0,
                                        whereAreReverseDoors.includes(i + 1)
                                          ? 0.5235988
                                          : -0.5235988,
                                        0
                                      ]
                                    : [0, 0, 0]
                                }
                                onClick={setCurrentDoorSlot}
                              >
                                {(Material, props) => (
                                  <Material
                                    color='#ffffff'
                                    metalness={0}
                                    roughness={0}
                                    {...props}
                                  />
                                )}
                              </Reflector>
                            </>
                          )
                      })}
                  </>
                )}
            </React.Fragment>
          )
        })}
      <Floor size={[100, 0.02, 100]} position={[0, -prm.height / 2 - prm.deskWidth / 2, 0]} />
      <Wall size={[100, 100, prm.deskWidth]} position={[0, 0, -prm.depth / 2]} />
      <Wall size={[100, 100, prm.deskWidth]} position={[0, 0, 10]} />
      <Wall size={[prm.deskWidth, 100, 100]} position={[-6, 0, 0]} />
      <Wall size={[prm.deskWidth, 100, 100]} position={[6, 0, 0]} />
      {prm.leftDesk === 1 && (
        <Cube // left notInner
          size={[0.0001, prm.height + 0.002 - (prm.topDesk ? 0 : prm.deskWidth), prm.depth]}
          position={[
            -(sumOfParts * prm.width) / 2 - 0.0001,
            prm.topDesk ? 0 : -prm.deskWidth / 2,
            0
          ]}
          color={prm.color}
          notInner
        />
      )}
      {prm.rightDesk === 1 && (
        <Cube // right notInner
          size={[0.0001, prm.height + 0.002 - (prm.topDesk ? 0 : prm.deskWidth), prm.depth]}
          position={[
            (sumOfParts * prm.width) / 2 + 0.0001,
            prm.topDesk ? 0 : -prm.deskWidth / 2,
            0
          ]}
          color={prm.color}
          notInner
        />
      )}
      {prm.showDimensions && (
        <>
          <Text
            position={[modelStartLeft - 0.05, 0 - 0.13, prm.depth / 2]}
            rotation={[0, 0, Math.PI / 2]}
            text={`${(100 * Math.round(prm.height * 1000)) / 1000}`}
            size={0.12}
          />
          <Text
            position={[modelStartLeft - 0.05, -prm.height / 2 + 0.01, prm.depth / 2]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            text={`${(100 * Math.round(prm.depth * 1000)) / 1000}`}
            size={0.12}
          />
        </>
      )}
      <pointLight intensity={0.47} position={[1, 0, prm.depth + 1]} />
      <pointLight intensity={0.47} position={[-1, 0, prm.depth + 1]} />
      <orbitControls
        args={[camera, domElement]}
        enablePan={false}
        minDistance={0.8}
        maxDistance={3.8}
        minPolarAngle={0}
        maxPolarAngle={1.6}
        minAzimuthAngle={-1.5}
        maxAzimuthAngle={1.5}
      />
      <ambientLight intensity={0.3} />
    </>
  )
}

export default Scene
