/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"

export default function DoorSlots({ prm, doorsTexturesMap, setPrm, setDoorsTexturesMap }) {
  const availableSlotsConfigurations = [
    [1],
    [1, 1],
    [1, 1, 1],
    [1, 1, 1, 1],
    [1, 1, 1, 1, 1],
    [1, 1, 1, 1, 1, 1],
    [1.5, 2, 1.5],
    [1, 2, 1],
    [1, 3, 1],
    [1, 2, 1, 2, 1],
    [1.3, 1, 1.3],
    [1.5, 1, 1.5],
    [2, 1, 2],
    [3, 1, 3]
  ]

  const colors = useTheme()

  const sumOfParts = prm.parts.reduce((a, b) => a + b, 0)

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  function handleDoorsConfig(configuration) {
    const doorsColors = Array(sumOfParts)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentDoorPart) {
          return Array(configuration.length)
            .fill(1)
            .map((el, j) => {
              return ``
            })
        } else return prm.doorsColors[i]
      })
    const doorsPictures = Array(sumOfParts)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentDoorPart) {
          return Array(configuration.length).fill(`platinoveBila`)
        } else return prm.doorsPictures[i]
      })
    const currentDoorSlot = Array(sumOfParts)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentDoorPart) {
          return 0
        } else return prm.currentDoorSlot[i]
      })
    const doorsTextures = Array(sumOfParts)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentDoorPart) {
          return Array(configuration.length).fill(
            `https://${bucketURL}.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`
          )
        } else return doorsTexturesMap[i]
      })

    const doorsSlots = Array(sumOfParts)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentDoorPart) {
          return configuration
        } else return prm.doorsSlots[i]
      })

    setDoorsTexturesMap(doorsTextures)
    setPrm({
      ...prm,
      doorsColors,
      doorsPictures,
      currentDoorSlot,
      doorsSlots
    })
  }

  return (
    <>
      <h2>Zvolte rozdělení dveří:</h2>
      <div
        css={css`
          display: flex;
          overflow-x: scroll;
        `}
      >
        {availableSlotsConfigurations.map((slot, i) => {
          const ratio = 250 / (prm.height * 100)
          return (
            <div
              css={css`
                margin-right: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: ${colors.white};
                cursor: pointer;
              `}
              onClick={() => handleDoorsConfig(availableSlotsConfigurations[i])}
            >
              {slot.map((slotPart, j) => {
                const sumOfSlots = availableSlotsConfigurations[i].reduce((a, b) => a + b, 0)
                return (
                  <div
                    css={css`
                      width: ${prm.width * 100 * ratio}px;
                      height: ${(availableSlotsConfigurations[i][j] / sumOfSlots) *
                        (prm.height * 100) *
                        ratio}px;
                      border: 2px solid ${colors.grey};
                      ${j !== availableSlotsConfigurations[i].length - 1 && `border-bottom: 0;`}
                    `}
                  ></div>
                )
              })}
              <div
                css={css`
                  margin-top: 2px;
                  margin-bottom: 5px;
                  font-size: 13px;
                `}
              >
                {i}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
