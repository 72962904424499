/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"

export default function Table(props) {
  const colors = useTheme()
  return (
    <div
      css={css`
        > div {
          display: grid;
          grid-template-columns: ${props.type === `doors` ? `170px` : `40%`} 60%;
          ${props.grid === `6040` && `grid-template-columns: 60% 40%;`}
          grid-row-gap: 10px;
          padding: 10px;
          background: #fff;
          align-items: center;
          @media print {
            border: 2px solid ${colors.grey};
            border-bottom: 0;
            padding: 7px 10px;
            &:last-of-type {
              border-bottom: 2px solid ${colors.grey};
            }
          }
          &:nth-of-type(even) {
            background: #f2f2f2;
            input[type="text"],
            input[type="password"] {
              background: #fff;
            }
            input[type="submit"] {
              background: #fff;
            }
          }
          .label {
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
          }
          .value {
            font-size: 13px;
            text-transform: uppercase;
          }
          input {
            background: #f2f2f2;
          }
          input[type="submit"] {
            grid-column-start: 1;
            grid-column-end: 3;
            cursor: pointer;
            &:hover {
              color: #fff;
              background: ${colors.green};
            }
          }
        }
      `}
    >
      {props.children}
    </div>
  )
}
