/** @jsx jsx */
import React, { useState } from "react"
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { Link } from "react-router-dom"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"

function GreenButton(props) {
  const colors = useTheme()
  const configErrorNumber = props.configErrorNumber
  const configError = props.configError
  const prm = props.prm
  const setPrm = props.setPrm

  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        center
        styles={{ modal: { backgroundColor: `#ff6c6c`, color: `#fff`, paddingRight: 50 } }}
      >
        {configError}
      </Modal>
      <Link
        to={props.link}
        onClick={(e) => {
          if (configError) {
            openModal()
            setPrm({ ...prm, currentPart: configErrorNumber })
            e.preventDefault()
          }
        }}
      >
        <button
          css={css`
            width: 100%;
            height: 50px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 15px !important;
            border-radius: 3px;
            cursor: pointer;
            border: 3px solid ${colors.green};
            background: ${colors.green};
            color: ${colors.white};
            transition: 200ms;
            margin-top: 8px;
          `}
        >
          {props.value}
        </button>
      </Link>
      <div // GG Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=748518
        css={css`
          height: 2px;
          width: 100%;
        `}
      ></div>
    </div>
  )
}

export default GreenButton
