/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React from "react"
import Slider from "@material-ui/core/Slider"
import Input from "@material-ui/core/Input"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import GreenButton from "../../modules/greenButton"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

function Step1(props) {
  const prm = props.prm
  const setPrm = props.setPrm
  const doorsTexturesMap = props.doorsTexturesMap
  const setDoorsTexturesMap = props.setDoorsTexturesMap

  const colors = useTheme()

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  function handleChangeParts(e) {
    const value = parseInt(e.target.value)
    const parts = Array(value).fill(1)
    const currentPart = () => {
      if (prm.currentPart < value) {
        return prm.currentPart
      } else {
        return value - 1
      }
    }
    const currentDoorPart = () => {
      if (prm.currentDoorPart < value) {
        return prm.currentDoorPart
      } else {
        return value - 1
      }
    }
    const config = Array(value)
      .fill(1)
      .map((el, j) => {
        if (j < prm.config.length) {
          return prm.config[j]
        } else {
          return Array(5).fill(1)
        }
      })
    const content = Array(value)
      .fill(1)
      .map((el, j) => {
        if (j < prm.config.length) {
          return prm.content[j]
        } else {
          return Array(5).fill(0)
        }
      })
    const openDoorsRight = Array(value)
      .fill(1)
      .map((el, j) => {
        if (j < prm.config.length) {
          return prm.openDoorsRight[j]
        } else {
          return false
        }
      })
    const doorsColors = Array(value)
      .fill(1)
      .map((el, j) => {
        if (j < prm.config.length) {
          return prm.doorsColors[j]
        } else {
          return Array(1).fill(``)
        }
      })
    const doorsPictures = Array(value)
      .fill(1)
      .map((el, j) => {
        if (j < prm.config.length) {
          return prm.doorsPictures[j]
        } else {
          return Array(1).fill(`platinoveBila`)
        }
      })
    const currentDoorSlot = Array(value)
      .fill(1)
      .map((el, j) => {
        if (j < prm.config.length) {
          return prm.currentDoorSlot[j]
        } else {
          return 0
        }
      })
    const doorsTextures = Array(value)
      .fill(1)
      .map((el, i) => {
        if (i < prm.config.length) {
          return doorsTexturesMap[i]
        } else return [`https://${bucketURL}.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`]
      })
    setDoorsTexturesMap(doorsTextures)

    const doorsSlots = Array(value)
      .fill(1)
      .map((el, i) => {
        if (i < prm.config.length) {
          return prm.doorsSlots[i]
        } else return [1]
      })

    setPrm({
      ...prm,
      currentPart: currentPart(),
      currentDoorPart: currentDoorPart(),
      parts,
      config,
      content,
      openDoorsRight,
      doorsColors,
      doorsPictures,
      currentDoorSlot,
      doorsSlots
    })
  }

  function handleChangePartWidth(e) {
    const value = parseInt(e.target.value)
    const parts = Array(prm.parts.length)
      .fill(1)
      .map((el, j) => {
        if (j === prm.currentPart) return value
        else return prm.parts[j]
      })

    const sumOfPartsNew = parts.reduce((a, b) => a + b, 0)
    const doorsColors = Array(sumOfPartsNew)
      .fill(1)
      .map((el, i) => {
        if (i < sumOfParts) {
          return prm.doorsColors[i]
        } else return [`#ffffff`, `#ffffff`, `#ffffff`, `#ffffff`]
      })
    const currentDoorSlot = Array(sumOfPartsNew).fill(0)
    const openDoorsRight = Array(sumOfPartsNew).fill(false)
    const doorsTextures = Array(sumOfPartsNew)
      .fill(1)
      .map((el, i) => {
        if (i < sumOfParts) {
          return doorsTexturesMap[i]
        } else return []
      })
    const doorsPictures = Array(sumOfPartsNew)
      .fill(1)
      .map((el, j) => {
        if (j < sumOfPartsNew - 1) {
          return prm.doorsPictures[j]
        } else {
          return Array(1).fill(null)
        }
      })

    const doorsSlots = Array(sumOfPartsNew)
      .fill(1)
      .map((el, j) => {
        if (j < sumOfPartsNew - 1) {
          return prm.doorsSlots[j]
        } else {
          return Array(1).fill(1)
        }
      })
    setDoorsTexturesMap(doorsTextures)
    setPrm({
      ...prm,
      parts,
      doorsColors,
      currentDoorSlot,
      openDoorsRight,
      doorsPictures,
      doorsSlots
    })
  }

  const handleSliderHeightChange = (event, newValue) => {
    if (newValue >= 30 && newValue <= 278)
      setPrm({ ...prm, height: newValue / 100, inputHeight: newValue / 100 })
    else setPrm({ ...prm, inputHeight: newValue / 100 })
  }

  const handleInputHeightChange = (event) => {
    if (event.target.value >= 30 && event.target.value <= 278)
      setPrm({
        ...prm,
        inputHeight: Number(event.target.value / 100),
        height: Number(event.target.value / 100)
      })
    else
      setPrm({
        ...prm,
        inputHeight: Number(event.target.value / 100)
      })
  }

  const handleSliderRoundedWidthChange = (event, newValue) => {
    if (newValue >= 1 && newValue <= 50)
      setPrm({ ...prm, roundedWidth: newValue / 100, inputRoundedWidth: newValue / 100 })
    else setPrm({ ...prm, inputRoundedWidth: newValue / 100 })
  }

  const handleInputRoundedWidthChange = (event) => {
    if (event.target.value >= 1 && event.target.value <= 50)
      setPrm({
        ...prm,
        inputRoundedWidth: Number(event.target.value / 100),
        roundedWidth: Number(event.target.value / 100)
      })
    else
      setPrm({
        ...prm,
        inputRoundedWidth: Number(event.target.value / 100)
      })
  }

  const handleSliderSoklHeightChange = (event, newValue) => {
    if (newValue >= 2 && newValue <= 10)
      setPrm({ ...prm, soklHeight: newValue / 100, inputSoklHeight: newValue / 100 })
    else setPrm({ ...prm, inputSoklHeight: newValue / 100 })
  }

  const handleInputSoklHeightChange = (event) => {
    if (event.target.value >= 2 && event.target.value <= 10)
      setPrm({
        ...prm,
        inputSoklHeight: Number(event.target.value / 100),
        soklHeight: Number(event.target.value / 100)
      })
    else
      setPrm({
        ...prm,
        inputSoklHeight: Number(event.target.value / 100)
      })
  }

  const handleSliderDepthChange = (event, newValue) => {
    if (newValue >= 30 && newValue <= 90)
      setPrm({ ...prm, depth: newValue / 100, inputDepth: newValue / 100 })
    else setPrm({ ...prm, inputDepth: newValue / 100 })
  }

  const handleInputDepthChange = (event) => {
    if (event.target.value >= 30 && event.target.value <= 90)
      setPrm({
        ...prm,
        inputDepth: Number(event.target.value / 100),
        depth: Number(event.target.value / 100)
      })
    else
      setPrm({
        ...prm,
        inputDepth: Number(event.target.value / 100)
      })
  }

  const handleSliderWidthChange = (event, newValue) => {
    if (newValue / sumOfParts >= 30 && newValue / sumOfParts <= 200)
      setPrm({
        ...prm,
        width: newValue / 100 / sumOfParts,
        inputWidth: newValue / 100 / sumOfParts
      })
    else setPrm({ ...prm, inputWidth: newValue / 100 / sumOfParts })
  }

  const handleInputWidthChange = (event) => {
    if (event.target.value / sumOfParts >= 30 && event.target.value / sumOfParts <= 200)
      setPrm({
        ...prm,
        inputWidth: Number(event.target.value / 100 / sumOfParts),
        width: Number(event.target.value / 100 / sumOfParts)
      })
    else
      setPrm({
        ...prm,
        inputWidth: Number(event.target.value / 100 / sumOfParts)
      })
  }

  const handleBlurHeight = () => {
    if (prm.inputHeight < 0.3) {
      setPrm({ ...prm, inputHeight: 0.3 })
    } else if (prm.inputHeight > 2.78) {
      setPrm({ ...prm, inputHeight: 2.78 })
    }
  }

  const handleBlurRoundedWidth = () => {
    if (prm.inputRoundedWidth < 0.01) {
      setPrm({ ...prm, inputRoundedWidth: 0.01 })
    } else if (prm.inputRoundedWidth > 0.5) {
      setPrm({ ...prm, inputRoundedWidth: 0.5 })
    }
  }

  const handleBlurSoklHeight = () => {
    if (prm.inputSoklHeight < 0.02) {
      setPrm({ ...prm, inputSoklHeight: 0.02 })
    } else if (prm.inputSoklHeight > 0.1) {
      setPrm({ ...prm, inputSoklHeight: 0.1 })
    }
  }

  const handleBlurDepth = () => {
    if (prm.inputDepth < 0.3) {
      setPrm({ ...prm, inputDepth: 0.3 })
    } else if (prm.inputDepth > 0.9) {
      setPrm({ ...prm, inputDepth: 0.9 })
    }
  }

  const handleBlurWidth = () => {
    if (prm.inputWidth < 0.3) {
      setPrm({ ...prm, inputWidth: 0.3 })
    } else if (prm.inputWidth > 2) {
      setPrm({ ...prm, inputWidth: 2 })
    }
  }

  const handleChangeRadio = (e) => {
    setPrm({ ...prm, [e.target.name]: parseInt(e.target.value) })
  }

  const handleChangeRoundedRight = (e) => {
    const rightDesk = prm.roundedRight === 0 ? 0 : 1
    const config = prm.config
    config[prm.parts.length - 1] = [1, 1, 1, 1, 1]
    const content = prm.content
    content[prm.parts.length - 1] = [0, 0, 0, 0, 0]
    const parts = prm.parts
    parts[parts.length - 1] = 1
    const currentSlot = prm.currentSlot
    currentSlot[parts.length - 1] = 0
    setPrm({
      ...prm,
      [e.target.name]: parseInt(e.target.value),
      rightDesk,
      config,
      content,
      parts
    })
  }

  const handleChangeRoundedLeft = (e) => {
    const leftDesk = prm.roundedLeft === 0 ? 0 : 1
    const config = prm.config
    config[0] = [1, 1, 1, 1, 1]
    const content = prm.content
    content[0] = [0, 0, 0, 0, 0]
    const parts = prm.parts
    parts[0] = 1
    const currentSlot = prm.currentSlot
    currentSlot[0] = 0
    setPrm({
      ...prm,
      [e.target.name]: parseInt(e.target.value),
      leftDesk,
      config,
      content,
      parts
    })
  }

  const handleChangeRightDesk = (e) => {
    setPrm({
      ...prm,
      [e.target.name]: parseInt(e.target.value),
      roundedRight: 0
    })
  }

  const handleChangeLeftDesk = (e) => {
    setPrm({
      ...prm,
      [e.target.name]: parseInt(e.target.value),
      roundedLeft: 0
    })
  }

  const handleChangeBottomSokl = (e) => {
    let bottomDesk
    if (e.target.value === `1`) bottomDesk = 1
    else bottomDesk = prm.bottomDesk

    setPrm({ ...prm, bottomSokl: parseInt(e.target.value), bottomDesk })
  }

  const handleChangeBottomDesk = (e) => {
    let bottomSokl
    if (e.target.value === `0`) bottomSokl = 0
    else bottomSokl = prm.bottomSokl

    setPrm({ ...prm, bottomDesk: parseInt(e.target.value), bottomSokl })
  }

  const sumOfParts = prm.parts.reduce((a, b) => a + b, 0)

  return (
    <>
      <div>
        <h2>Zvolte počet dílů skříně:</h2>
        <div className='column'>
          <label>Počet dílů:</label>
          <FormControl>
            <Select value={prm.parts.length} onChange={(e) => handleChangeParts(e)}>
              {prm.roundedLeft === 0 && prm.roundedRight === 0 && <MenuItem value={1}>1</MenuItem>}
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
          </FormControl>
        </div>
        <h2>Zvolte šířku jednotlivých dílů:</h2>
        <div className='column'>
          <label>Šířka vybraného dílu:</label>
          <FormControl>
            <Select value={prm.parts[prm.currentPart]} onChange={(e) => handleChangePartWidth(e)}>
              <MenuItem value={1}>Jednodíl</MenuItem>
              {((prm.currentPart > 0 && prm.currentPart < prm.parts.length - 1) ||
                (prm.currentPart === prm.parts.length - 1 && prm.roundedRight === 0) ||
                (prm.currentPart === 0 && prm.roundedLeft === 0)) && (
                <MenuItem value={2}>Dvojdíl</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <h2>Zvolte základní rozměry skříně:</h2>
        <div
          className='column'
          css={css`
            margin-bottom: 0 !important;
            margin-top: 15px;
            .inputWrapper {
              color: ${colors.white};
            }
            label {
              align-self: flex-start;
            }
          `}
        >
          <label>Výška:</label>
          <div>
            <div className='inputWrapper'>
              <Input
                value={Math.round(prm.inputHeight * 100)}
                onChange={handleInputHeightChange}
                inputProps={{
                  step: 1,
                  min: 30,
                  max: 278,
                  type: `text`
                }}
                onBlur={handleBlurHeight}
              />
              cm
            </div>
            <Slider
              value={prm.inputHeight * 100}
              onChange={handleSliderHeightChange}
              aria-labelledby='input-slider'
              min={30}
              step={1}
              max={278}
            />
          </div>
          <label>Šířka:</label>
          <div>
            <div className='inputWrapper'>
              <Input
                value={Math.round(prm.inputWidth * 100 * sumOfParts)}
                onChange={handleInputWidthChange}
                inputProps={{
                  step: 1,
                  min: sumOfParts * 30,
                  max: sumOfParts * 200,
                  type: `text`
                }}
                onBlur={handleBlurWidth}
              />
              cm
            </div>
            <Slider
              value={prm.inputWidth * 100 * sumOfParts}
              onChange={handleSliderWidthChange}
              aria-labelledby='input-slider'
              min={sumOfParts * 30}
              step={1}
              max={sumOfParts * 200}
            />
          </div>
          <label>Hloubka:</label>
          <div>
            <div className='inputWrapper'>
              <Input
                value={Math.round(prm.inputDepth * 100)}
                onChange={handleInputDepthChange}
                inputProps={{
                  step: 0.01,
                  min: 0.3,
                  max: 0.9,
                  type: `text`
                }}
                onBlur={handleBlurDepth}
              />
              cm
            </div>
            <Slider
              value={prm.inputDepth * 100}
              onChange={handleSliderDepthChange}
              aria-labelledby='input-slider'
              min={30}
              step={1}
              max={90}
            />
          </div>
        </div>
        <h2>Sokl:</h2>
        <div className='column'>
          <label>Spodní sokl:</label>
          <RadioGroup name='bottomSokl' value={prm.bottomSokl} onChange={handleChangeBottomSokl}>
            <FormControlLabel value={1} control={<Radio />} label='Ano' />
            <FormControlLabel value={0} control={<Radio />} label='Ne' />
          </RadioGroup>
          {prm.bottomSokl === 1 && (
            <>
              <label>Výška soklu:</label>
              <div
                css={css`
                  margin-top: 20px;
                `}
              >
                <div
                  className='inputWrapper'
                  css={css`
                    color: ${colors.white};
                  `}
                >
                  <Input
                    value={Math.round(prm.inputSoklHeight * 100)}
                    onChange={handleInputSoklHeightChange}
                    inputProps={{
                      step: 1,
                      min: 2,
                      max: 10,
                      type: `text`
                    }}
                    onBlur={handleBlurSoklHeight}
                  />
                  cm
                </div>
                <Slider
                  value={prm.inputSoklHeight * 100}
                  onChange={handleSliderSoklHeightChange}
                  aria-labelledby='input-slider'
                  min={2}
                  step={1}
                  max={10}
                />
              </div>
            </>
          )}
        </div>
        {prm.parts.length !== 1 && (
          <>
            <h2>Otevřené boky:</h2>
            <div className='column'>
              <label>Levý bok:</label>
              <RadioGroup
                name='roundedLeft'
                value={prm.roundedLeft}
                onChange={handleChangeRoundedLeft}
              >
                <FormControlLabel value={1} control={<Radio />} label='Ano' />
                <FormControlLabel value={0} control={<Radio />} label='Ne' />
              </RadioGroup>
            </div>
            <div className='column'>
              <label>Pravý bok:</label>
              <RadioGroup
                name='roundedRight'
                value={prm.roundedRight}
                onChange={handleChangeRoundedRight}
              >
                <FormControlLabel value={1} control={<Radio />} label='Ano' />
                <FormControlLabel value={0} control={<Radio />} label='Ne' />
              </RadioGroup>
            </div>
            {prm.roundedLeft + prm.roundedRight > 0 && (
              <>
                <div className='column'>
                  <label>Typ otevřeného boku:</label>
                  <RadioGroup
                    name='roundedType'
                    value={prm.roundedType}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel value={1} control={<Radio />} label='Kulatý' />
                    <FormControlLabel value={2} control={<Radio />} label='Zkosený' />
                  </RadioGroup>
                </div>
                <div className='column'>
                  <label
                    css={css`
                      align-self: flex-start;
                    `}
                  >
                    Šířka otevřených boků:
                  </label>
                  <div>
                    <div
                      className='inputWrapper'
                      css={css`
                        color: ${colors.white};
                      `}
                    >
                      <Input
                        value={Math.round(prm.roundedWidth * 100)}
                        onChange={handleInputRoundedWidthChange}
                        inputProps={{
                          step: 0.01,
                          min: 0.1,
                          max: 0.5,
                          type: `text`
                        }}
                        onBlur={handleBlurRoundedWidth}
                      />
                      cm
                    </div>
                    <Slider
                      value={prm.inputRoundedWidth * 100}
                      onChange={handleSliderRoundedWidthChange}
                      aria-labelledby='input-slider'
                      min={10}
                      step={1}
                      max={50}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <h2>Stěny:</h2>
        <div className='column'>
          <label>Bílé vnitřní stěny:</label>
          <RadioGroup name='whiteInside' value={prm.whiteInside} onChange={handleChangeRadio}>
            <FormControlLabel value={1} control={<Radio />} label='Ano' />
            <FormControlLabel value={0} control={<Radio />} label='Ne' />
          </RadioGroup>
        </div>
        <div className='column'>
          <label>Levá stěna:</label>
          <RadioGroup name='leftDesk' value={prm.leftDesk} onChange={handleChangeLeftDesk}>
            <FormControlLabel value={1} control={<Radio />} label='Ano' />
            <FormControlLabel value={0} control={<Radio />} label='Ne' />
          </RadioGroup>
        </div>
        <div className='column'>
          <label>Pravá stěna:</label>
          <RadioGroup name='rightDesk' value={prm.rightDesk} onChange={handleChangeRightDesk}>
            <FormControlLabel value={1} control={<Radio />} label='Ano' />
            <FormControlLabel value={0} control={<Radio />} label='Ne' />
          </RadioGroup>
        </div>
        <div className='column'>
          <label>Zadní stěna:</label>
          <RadioGroup name='backDesk' value={prm.backDesk} onChange={handleChangeRadio}>
            <FormControlLabel value={1} control={<Radio />} label='Ano' />
            <FormControlLabel value={0} control={<Radio />} label='Ne' />
          </RadioGroup>
        </div>
        <div className='column'>
          <label>Horní stěna:</label>
          <RadioGroup name='topDesk' value={prm.topDesk} onChange={handleChangeRadio}>
            <FormControlLabel value={1} control={<Radio />} label='Ano' />
            <FormControlLabel value={0} control={<Radio />} label='Ne' />
          </RadioGroup>
        </div>
        <div className='column'>
          <label>Spodní stěna:</label>
          <RadioGroup name='bottomDesk' value={prm.bottomDesk} onChange={handleChangeBottomDesk}>
            <FormControlLabel value={1} control={<Radio />} label='Ano' />
            <FormControlLabel value={0} control={<Radio />} label='Ne' />
          </RadioGroup>
        </div>
      </div>
      <GreenButton value='Pokračovat na definici barev' link='./colors' />
    </>
  )
}

export default Step1
