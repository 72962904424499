/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { css, jsx } from "@emotion/core"
import GreenButton from "../../modules/greenButton"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { useParams } from "react-router-dom"
import Axios from "axios"
import { useTheme } from "emotion-theming"
import MirrorImg from "../../../img/mirror.jpg"
import DoorSlots from "./doorSlots"

function Step4(props) {
  const prm = props.prm
  const setPrm = props.setPrm
  const doorsTexturesMap = props.doorsTexturesMap
  const setDoorsTexturesMap = props.setDoorsTexturesMap
  const [textures, setTextures] = useState([])
  const [doorsTextures, setDoorsTextures] = useState([])
  const setTextureFrameURL = props.setTextureFrameURL
  const setCurrentPage = props.setCurrentPage

  const sumOfParts = prm.parts.reduce((a, b) => a + b, 0)

  const { owner } = useParams()

  const colors = useTheme()

  useEffect(() => {
    setCurrentPage(4)
    Axios.get(`/textures/all/${owner}/2`)
      .then((res) => {
        setTextures(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
    Axios.get(`/textures/all/${owner}/1`)
      .then((res) => {
        setDoorsTextures(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [owner, setCurrentPage])

  function handleCurrentSlot(e) {
    const position = parseInt(e.target.dataset.value)
    const currentDoorSlot = Array(prm.config.length)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentDoorPart) {
          return position
        } else return prm.currentDoorSlot[i]
      })
    setPrm({
      ...prm,
      currentDoorSlot: currentDoorSlot
    })
  }

  function handleOpenDoorsRight(e) {
    const value = () => {
      if (e.target.value === `true`) return true
      else return false
    }
    const openDoorsRight = Array(sumOfParts)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentDoorPart) {
          return value()
        } else return prm.openDoorsRight[i]
      })

    setPrm({
      ...prm,
      openDoorsRight: openDoorsRight
    })
  }

  const whereAreDoubleDoors = []

  prm.parts.map((value, i) => {
    if (value === 2) {
      return whereAreDoubleDoors.push(prm.parts.slice(0, i + 1).reduce((a, b) => a + b, 0))
    } else {
      return null
    }
  })

  prm.parts.map((value, i) => {
    if (value === 2) {
      return whereAreDoubleDoors.push(prm.parts.slice(0, i).reduce((a, b) => a + b, 0) + 1)
    } else {
      return null
    }
  })

  const handleChangeRadio = (e) => {
    setPrm({ ...prm, [e.target.name]: parseInt(e.target.value) })
  }

  const handleChangeDoorsType = (e) => {
    const value = parseInt(e.target.value)
    let doorsColors
    let doorsPictures
    let currentDoorSlot
    let doorsTextures
    if (value === 0) {
      doorsColors = Array(sumOfParts)
        .fill(1)
        .map((el, i) => {
          return Array(1)
            .fill(1)
            .map((el, j) => {
              return ``
            })
        })
      doorsPictures = Array(sumOfParts)
        .fill(1)
        .map((el, i) => {
          return Array(1)
            .fill(1)
            .map((el, j) => {
              return `platinoveBila`
            })
        })
      currentDoorSlot = Array(sumOfParts)
        .fill(1)
        .map((el, i) => {
          return 0
        })
      doorsTextures = Array(sumOfParts)
        .fill(1)
        .map((el, i) => {
          return Array(1)
            .fill(1)
            .map((el, j) => {
              return `https://${bucketURL}.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`
            })
        })
      setDoorsTexturesMap(doorsTextures)

      const doorsSlots = Array(sumOfParts)
        .fill(1)
        .map((el, i) => {
          return [1]
        })

      setPrm({
        ...prm,
        [e.target.name]: parseInt(e.target.value),
        isFrame: parseInt(e.target.value),
        doorsColors,
        doorsPictures,
        currentDoorSlot,
        doorsSlots
      })
    } else
      setPrm({
        ...prm,
        [e.target.name]: parseInt(e.target.value),
        isFrame: parseInt(e.target.value)
      })
  }

  const handleOpenDoors = (e) => {
    const value = () => {
      if (e.target.value === `true`) return true
      else return false
    }
    setPrm({ ...prm, [e.target.name]: value() })
  }

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  const doorSlotTextureChange = (texture) => {
    if (texture.color === `null`) {
      let doorsTexturesMapCopy = doorsTexturesMap.slice()
      doorsTexturesMapCopy[prm.currentDoorPart][prm.currentDoorSlot[prm.currentDoorPart]] =
        `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/` + texture.url
      setDoorsTexturesMap(doorsTexturesMapCopy)
      const doorsColors = prm.doorsColors
      doorsColors[prm.currentDoorPart][prm.currentDoorSlot[prm.currentDoorPart]] = ``
      const doorsPictures = prm.doorsPictures
      doorsPictures[prm.currentDoorPart][prm.currentDoorSlot[prm.currentDoorPart]] = texture._id
      setPrm({
        ...prm,
        doorsColors,
        doorsPictures
      })
    } else {
      const doorsColors = prm.doorsColors
      doorsColors[prm.currentDoorPart][prm.currentDoorSlot[prm.currentDoorPart]] = texture.color
      const doorsPictures = prm.doorsPictures
      doorsPictures[prm.currentDoorPart][prm.currentDoorSlot[prm.currentDoorPart]] = texture._id
      setPrm({
        ...prm,
        doorsColors,
        doorsPictures
      })
    }
  }

  return (
    <>
      <div>
        <h2>Dveře:</h2>
        {sumOfParts <= prm.roundedLeft + prm.roundedRight ? (
          <div
            css={css`
              font-size: 13px;
              font-weight: 500;
              padding: 10px;
              color: #fff;
              background: ${colors.red};
              text-transform: uppercase;
            `}
          >
            Pro kulaté boční police nelze definovat dveře.
          </div>
        ) : (
          <>
            <div className='column'>
              <label>Chci skříň se dveřmi:</label>
              <RadioGroup name='doors' value={prm.doors} onChange={handleChangeRadio}>
                <FormControlLabel value={1} control={<Radio />} label='Ano' />
                <FormControlLabel value={0} control={<Radio />} label='Ne' />
              </RadioGroup>
            </div>
            {prm.doors === 1 && (
              <>
                <h2>Zvolte způsob otevírání dveří:</h2>
                <div className='column'>
                  <label>Otevírání dveří:</label>
                  <RadioGroup
                    name='doorsType'
                    value={prm.doorsType}
                    onChange={handleChangeDoorsType}
                  >
                    <FormControlLabel value={0} control={<Radio />} label='Klasické' />
                    <FormControlLabel value={1} control={<Radio />} label='Posuvné' />
                  </RadioGroup>
                </div>
                {prm.isFrame === 1 && (
                  <>
                    <h2>Zvolte typ rámu dveří:</h2>
                    <div
                      css={css`
                        display: grid;
                        grid-template-columns: repeat(auto-fill, 90px);
                        grid-gap: 8px;
                        justify-content: space-between;
                      `}
                    >
                      <div
                        key={`factorPrirodni`}
                        css={css`
                          background: ${colors.grey};
                          color: ${colors.white};
                          text-align: center;
                          cursor: pointer;
                          border: 2px solid
                            ${prm.doorsFrameId === `factorPrirodni`
                              ? `${prm.currentColor}`
                              : `${colors.grey}`};
                        `}
                        onClick={() => {
                          setPrm({
                            ...prm,
                            doorsFrameId: `factorPrirodni`,
                            doorsFrameColor: `#EAEAEA`
                          })
                          setTextureFrameURL(null)
                        }}
                      >
                        <img
                          src={
                            `https://` +
                            bucketURL +
                            `.s3.eu-central-1.amazonaws.com/small-factorPrirodni.png?cacheblock=true`
                          }
                          alt={`Factor přírodní`}
                          css={css`
                            max-width: 86px;
                          `}
                        />
                        <div
                          css={css`
                            padding: 4px 0 7px 0;
                            font-size: 11px;
                            font-weight: 600;
                            text-transform: uppercase;
                            word-wrap: break-word;
                          `}
                        >
                          Factor přírodní
                        </div>
                      </div>
                      {textures.map((texture) => (
                        <div
                          key={texture._id}
                          css={css`
                            background: ${colors.grey};
                            color: ${colors.white};
                            text-align: center;
                            cursor: pointer;
                            border: 2px solid
                              ${prm.doorsFrameId === texture._id
                                ? `${prm.currentColor}`
                                : `${colors.grey}`};
                          `}
                          onClick={() => {
                            setPrm({
                              ...prm,
                              doorsFrameId: texture._id,
                              doorsFrameColor: ``
                            })
                            texture.color !== `null`
                              ? setPrm({
                                  ...prm,
                                  doorsFrameColor: texture.color,
                                  doorsFrameId: texture._id
                                })
                              : setTextureFrameURL(
                                  `https://` +
                                    bucketURL +
                                    `.s3.eu-central-1.amazonaws.com/` +
                                    texture.url
                                )
                            texture.color !== `null` && setTextureFrameURL(null)
                          }}
                        >
                          <img
                            src={
                              `https://` +
                              bucketURL +
                              `.s3.eu-central-1.amazonaws.com/small-` +
                              texture.url.substring(4) +
                              `?cacheblock=true`
                            }
                            alt={texture.name}
                            css={css`
                              max-width: 86px;
                            `}
                          />
                          <div
                            css={css`
                              padding: 4px 0 7px 0;
                              font-size: 11px;
                              font-weight: 600;
                              text-transform: uppercase;
                              word-wrap: break-word;
                            `}
                          >
                            {texture.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <h2>Otevřít dveře:</h2>
                <div className='column'>
                  <label>Otevřené dveře:</label>
                  <RadioGroup name='openDoors' value={prm.openDoors} onChange={handleOpenDoors}>
                    <FormControlLabel value={true} control={<Radio />} label='Otevřené' />
                    <FormControlLabel value={false} control={<Radio />} label='Zavřené' />
                  </RadioGroup>
                </div>
                {((prm.currentDoorPart === 0 && prm.roundedLeft === 0) ||
                  (prm.currentDoorPart !== 0 && prm.currentDoorPart !== sumOfParts - 1) ||
                  (prm.currentDoorPart === sumOfParts - 1 && prm.roundedRight === 0)) && (
                  <>
                    {prm.doorsType === 0 && (
                      <>
                        <h2>Zvolte na jakou stranu se mají vybrané dveře otevírat:</h2>
                        {whereAreDoubleDoors.includes(prm.currentDoorPart + 1) ? (
                          <div
                            css={css`
                              padding-left: 5px;
                              font-size: 12px;
                              color: ${colors.white};
                            `}
                          >
                            Pro dvojdíl nelze měnit umístění pantů.
                          </div>
                        ) : (
                          <div className='column'>
                            <label>Umístění pantů:</label>
                            <RadioGroup
                              value={prm.openDoorsRight[prm.currentDoorPart]}
                              onChange={handleOpenDoorsRight}
                            >
                              <FormControlLabel value={false} control={<Radio />} label='Vlevo' />
                              <FormControlLabel value={true} control={<Radio />} label='Vpravo' />
                            </RadioGroup>
                          </div>
                        )}
                      </>
                    )}
                    {prm.isFrame === 1 && (
                      <DoorSlots
                        prm={prm}
                        setPrm={setPrm}
                        doorsTexturesMap={doorsTexturesMap}
                        setDoorsTexturesMap={setDoorsTexturesMap}
                      />
                    )}
                    <h2>
                      Zvolte dekor dveří pro vybraný díl:
                      <br />
                      (díl vyberte kliknutím na něj ve 3D modelu)
                    </h2>
                    <div
                      css={css`
                        display: grid;
                        grid-template-columns: repeat(auto-fill, 90px);
                        grid-gap: 8px;
                        justify-content: space-between;
                        margin-bottom: 10px;
                      `}
                    >
                      <div
                        css={css`
                          background: ${colors.grey};
                          color: ${colors.white};
                          text-align: center;
                          cursor: pointer;
                          border: 2px solid
                            ${prm.doorsColors[prm.currentDoorPart][
                              prm.currentDoorSlot[prm.currentDoorPart]
                            ] === `mirror`
                              ? `${prm.currentColor}`
                              : `${colors.grey}`};
                        `}
                        onClick={() => doorSlotTextureChange({ color: `mirror`, _id: `mirror` })}
                      >
                        <img
                          src={MirrorImg}
                          alt='Zrcadlo'
                          css={css`
                            max-width: 86px;
                          `}
                        />
                        <div
                          css={css`
                            padding: 4px 0 7px 0;
                            font-size: 11px;
                            font-weight: 600;
                            text-transform: uppercase;
                            word-wrap: break-word;
                          `}
                        >
                          Zrcadlo
                        </div>
                      </div>
                      <div
                        key={`platinoveBila`}
                        css={css`
                          background: ${colors.grey};
                          color: ${colors.white};
                          text-align: center;
                          cursor: pointer;
                          border: 2px solid
                            ${prm.doorsPictures[prm.currentDoorPart][
                              prm.currentDoorSlot[prm.currentDoorPart]
                            ] === `platinoveBila`
                              ? `${prm.currentColor}`
                              : `${colors.grey}`};
                        `}
                        onClick={() =>
                          doorSlotTextureChange({
                            _id: `platinoveBila`,
                            url: `small-platinoveBila.jpeg`,
                            color: `null`,
                            name: `W980 ST2 Platinově bílá`
                          })
                        }
                      >
                        <img
                          src={
                            `https://` +
                            bucketURL +
                            `.s3.eu-central-1.amazonaws.com/small-platinoveBila.jpeg`
                          }
                          alt='W980 ST2 Platinově bílá'
                          css={css`
                            max-width: 86px;
                          `}
                        />
                        <div
                          css={css`
                            padding: 4px 0 7px 0;
                            font-size: 11px;
                            font-weight: 600;
                            text-transform: uppercase;
                            word-wrap: break-word;
                          `}
                        >
                          W980 ST2 Platinově bílá
                        </div>
                      </div>
                      {doorsTextures.map((texture) => {
                        return (
                          <div
                            key={texture._id}
                            css={css`
                              background: ${colors.grey};
                              color: ${colors.white};
                              text-align: center;
                              cursor: pointer;
                              border: 2px solid
                                ${prm.doorsPictures[prm.currentDoorPart][
                                  prm.currentDoorSlot[prm.currentDoorPart]
                                ] === texture._id
                                  ? `${prm.currentColor}`
                                  : `${colors.grey}`};
                            `}
                            onClick={() => doorSlotTextureChange(texture)}
                          >
                            <img
                              src={
                                `https://` +
                                bucketURL +
                                `.s3.eu-central-1.amazonaws.com/small-` +
                                texture.url.substring(4)
                              }
                              alt={texture.name}
                              css={css`
                                max-width: 86px;
                              `}
                            />
                            <div
                              css={css`
                                padding: 4px 0 7px 0;
                                font-size: 11px;
                                font-weight: 600;
                                text-transform: uppercase;
                                word-wrap: break-word;
                              `}
                            >
                              {texture.name}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <GreenButton value='Pokračovat na odesílací formulář' link='./summary' />
    </>
  )
}

export default Step4
