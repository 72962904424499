/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState } from "react"
import Wrapper from "./registerLoginWrapper"
import ErrorNotice from "../modules/errorNotice"
import SuccessNotice from "../modules/successNotice"
import Axios from "axios"
import { useParams } from "react-router-dom"

export default function NewPassword() {
  const [error, setError] = useState()
  const [password, setPassword] = useState(``)
  const [passwordCheck, setPasswordCheck] = useState(``)
  const [success, setSuccess] = useState()
  const { resetToken } = useParams()

  const submit = async (e) => {
    e.preventDefault()
    try {
      await Axios.post(`/users/newPassword/${resetToken}`, {
        password,
        passwordCheck
      })
      setPassword(``)
      setPasswordCheck(``)
      setSuccess(
        `Vaše heslo bylo úspěšně změněno, nyní se můžete přihlásit pomocí tohoto nového hesla.`
      )
      setError(``)
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg)
    }
  }

  return (
    <Wrapper maxWidth={580}>
      <h2>Vytvořte si nové heslo</h2>
      {success && <SuccessNotice message={success} />}
      {error && <ErrorNotice message={error} />}
      <form onSubmit={submit}>
        <label htmlFor='password'>Nové heslo:</label>
        <input
          id='password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label htmlFor='passwordCheck'>Nové heslo pro kontrolu:</label>
        <input
          id='passwordCheck'
          type='password'
          value={passwordCheck}
          onChange={(e) => setPasswordCheck(e.target.value)}
        />
        <input type='submit' value='Vytvořit nové heslo' />
      </form>
    </Wrapper>
  )
}
