/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState } from "react"
import Wrapper from "./registerLoginWrapper"
import ErrorNotice from "../modules/errorNotice"
import SuccessNotice from "../modules/successNotice"
import Axios from "axios"

export default function ForgotPassword() {
  const [email, setEmail] = useState(``)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const submit = async (e) => {
    e.preventDefault()
    try {
      await Axios.post(`/users/sendResetLink`, { email })
      setEmail(``)
      setSuccess(`Na Vámi uvedený e-mail byl zaslán odkaz pro vytvoření nového hesla. `)
      setError(``)
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg)
    }
  }

  return (
    <Wrapper maxWidth={450}>
      <h2>Obnova zapomenutého hesla</h2>
      {success && <SuccessNotice message={success} />}
      {error && <ErrorNotice message={error} />}
      <form onSubmit={submit}>
        <div
          to='/forgotPassword'
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            font-size: 14px;
            color: #fff;
            text-align: justify;
            cursor: pointer;
            margin-bottom: 20px;
          `}
        >
          Níže zadejte e-mail, pod kterým jste registrovali Váš účet. Na tento e-mail Vám následně
          bude odeslán odkaz, na kterém si budete moci vytvořit nové heslo.
        </div>
        <label htmlFor='login-email'>E-mail:</label>
        <input
          id='login-email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input type='submit' value='Poslat odkaz' />
      </form>
    </Wrapper>
  )
}
