/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { Switch, Route, useLocation } from "react-router-dom"
import { useTheme } from "emotion-theming"

export default function CanvasControlTop(props) {
  const fullscreen = props.fullscreen
  const setFullscreen = props.setFullscreen
  const prm = props.prm
  const setPrm = props.setPrm
  const admin = props.admin
  const orderDetail = props.orderDetail

  const location = useLocation()
  const colors = useTheme()
  return (
    <Switch>
      <Route path='/(app|admin|order)/'>
        {!admin && (
          <div
            css={css`
              position: absolute;
              top: 8px;
              right: 8px;
              ${orderDetail && `top: 85px; right: 10px;`}
              display: flex;
              @media only screen and (max-width: 776px) {
                right: 3px;
                top: ${orderDetail ? 68 : 8}px;
              }
            `}
          >
            <Switch>
              <Route path={[`/app/:owner/summary`, `/admin/orders/detail`, `/order`]}>
                {prm.doors && (
                  <>
                    <button
                      css={css`
                        background: ${!prm.showDoors ? `${colors.gold}` : `${colors.grey}`};
                        color: ${!prm.showDoors ? `${colors.grey}` : `${colors.white}`};
                        padding: 5px 10px;
                        z-index: 1;
                        cursor: pointer;
                        text-transform: uppercase;
                        font-size: 14px;
                        cursor: pointer;
                        transition: 200ms;
                        display: flex;
                        flex-direction: column;
                        font-weight: 700px;
                        border: 0;
                        margin-right: 10px;
                        &:hover {
                          background: ${!prm.showDoors ? `${colors.grey}` : `${colors.gold}`};
                          color: ${!prm.showDoors ? `${colors.white}` : `${colors.grey}`};
                          path {
                            fill: ${!prm.showDoors ? `${colors.white}` : `${colors.grey}`};
                          }
                        }
                        @media only screen and (max-width: 776px) {
                          margin-right: 4px;
                        }
                      `}
                      onClick={() =>
                        setPrm({
                          ...prm,
                          showDoors: !prm.showDoors
                        })
                      }
                    >
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          height='16'
                          x='0'
                          y='0'
                          enableBackground='new 0 0 492.5 492.5'
                          version='1.1'
                          viewBox='0 0 492.5 492.5'
                          xmlSpace='preserve'
                          css={css`
                            margin-right: 4px;
                            path {
                              transition: 200ms;
                              fill: ${!prm.showDoors ? `${colors.grey}` : `${colors.white}`};
                            }
                          `}
                        >
                          <path d='M184.646 0v21.72H99.704v433.358h31.403V53.123h53.539V492.5l208.15-37.422V37.5L184.646 0zm38.292 263.129c-6.997 0-12.67-7.381-12.67-16.486 0-9.104 5.673-16.485 12.67-16.485s12.67 7.381 12.67 16.485c0 9.105-5.673 16.486-12.67 16.486z'></path>
                        </svg>
                        {!prm.showDoors ? `Ukázat` : `Skrýt`} dveře
                      </div>
                    </button>
                  </>
                )}
              </Route>
            </Switch>
            <Switch>
              <Route
                path={[
                  `/app/:owner/doors`,
                  `/app/:owner/summary`,
                  `/admin/orders/detail`,
                  `/order`
                ]}
              >
                {prm.showDoors && prm.doors === 1 && (
                  <button
                    css={css`
                      background: ${prm.openDoors ? `${colors.gold}` : `${colors.grey}`};
                      color: ${prm.openDoors ? `${colors.grey}` : `${colors.white}`};
                      padding: 5px 10px;
                      z-index: 1;
                      cursor: pointer;
                      text-transform: uppercase;
                      font-size: 14px;
                      cursor: pointer;
                      transition: 200ms;
                      display: flex;
                      flex-direction: column;
                      font-weight: 700px;
                      border: 0;
                      margin-right: 10px;
                      &:hover {
                        background: ${prm.openDoors ? `${colors.grey}` : `${colors.gold}`};
                        color: ${prm.openDoors ? `${colors.white}` : `${colors.grey}`};
                        path {
                          fill: ${prm.openDoors ? `${colors.white}` : `${colors.grey}`};
                        }
                      }
                      @media only screen and (max-width: 776px) {
                        margin-right: 0;
                      }
                    `}
                    onClick={() =>
                      setPrm({
                        ...prm,
                        openDoors: !prm.openDoors
                      })
                    }
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        height='16'
                        x='0'
                        y='0'
                        enableBackground='new 0 0 492.5 492.5'
                        version='1.1'
                        viewBox='0 0 492.5 492.5'
                        xmlSpace='preserve'
                        css={css`
                          margin-right: 4px;
                          path {
                            transition: 200ms;
                            fill: ${prm.openDoors ? `${colors.grey}` : `${colors.white}`};
                          }
                        `}
                      >
                        <path d='M184.646 0v21.72H99.704v433.358h31.403V53.123h53.539V492.5l208.15-37.422V37.5L184.646 0zm38.292 263.129c-6.997 0-12.67-7.381-12.67-16.486 0-9.104 5.673-16.485 12.67-16.485s12.67 7.381 12.67 16.485c0 9.105-5.673 16.486-12.67 16.486z'></path>
                      </svg>
                      {prm.openDoors ? `Zavřít` : `Otevřít`} dveře
                      <span
                        css={css`
                          margin-left: 4px;
                          @media only screen and (max-width: 776px) {
                            display: none;
                          }
                        `}
                      >
                        {location.pathname.startsWith(`/app`) && `(D)`}
                      </span>
                    </div>
                  </button>
                )}
              </Route>
            </Switch>
            <Switch>
              <Route path='(/admin|/order)'></Route>
              <Route path='*'>
                <button
                  css={css`
                    background: ${fullscreen ? `${colors.gold}` : `${colors.grey}`};
                    color: ${fullscreen ? `${colors.grey}` : `${colors.white}`};
                    padding: 5px 10px;
                    z-index: 1;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-size: 14px;
                    cursor: pointer;
                    transition: 200ms;
                    display: flex;
                    flex-direction: column;
                    font-weight: 700px;
                    border: 0;
                    &:hover {
                      background: ${fullscreen ? `${colors.grey}` : `${colors.gold}`};
                      color: ${fullscreen ? `${colors.white}` : `${colors.grey}`};
                      path {
                        fill: ${fullscreen ? `${colors.white}` : `${colors.grey}`};
                      }
                    }
                    @media only screen and (max-width: 768px) {
                      display: none;
                    }
                  `}
                  onClick={() => setFullscreen(!fullscreen)}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      x='0'
                      y='0'
                      enableBackground='new 0 0 298.667 298.667'
                      version='1.1'
                      viewBox='0 0 298.667 298.667'
                      xmlSpace='preserve'
                      height='14'
                      css={css`
                        margin-right: 4px;
                        path {
                          transition: 200ms;
                          fill: ${fullscreen ? `${colors.grey}` : `${colors.white}`};
                        }
                      `}
                    >
                      <path d='M42.667 192L0 192 0 298.667 106.667 298.667 106.667 256 42.667 256z'></path>
                      <path d='M0 106.667L42.667 106.667 42.667 42.667 106.667 42.667 106.667 0 0 0z'></path>
                      <path d='M192 0L192 42.667 256 42.667 256 106.667 298.667 106.667 298.667 0z'></path>
                      <path d='M256 256L192 256 192 298.667 298.667 298.667 298.667 192 256 192z'></path>
                    </svg>
                    {fullscreen && `Opustit `}Fullscreen (F)
                  </div>
                </button>
              </Route>
            </Switch>
          </div>
        )}
      </Route>
    </Switch>
  )
}
