/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"
import configurations from "../designer/left/configurations.json"
import Model2DConfig from "../designer/left/model2DConfig"
import PartsIconActive from "../../img/parts-active.svg"

export default function Configuration() {
  const colors = useTheme()
  return (
    <>
      <h2
        css={css`
          display: flex;
          &:before {
            content: "";
            background-image: url(${PartsIconActive});
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            display: inline-flex;
          }
        `}
      >
        Konfigurace vnitřků skříně
      </h2>
      <div
        css={css`
          display: flex;
          background: #ccc;
          padding: 0 20px;
          flex-direction: column;
        `}
      >
        <Model2DConfig prm={configurations} loop={configurations} adminConfig={1} />
        <div
          css={css`
            background: ${colors.gold};
            margin-bottom: 20px;
            padding: 10px;
            color: ${colors.grey};
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
          `}
        >
          Nenašli jste požadovanou konfiguraci? Napište na {` `}
          <a
            href='info@3d-konfigurator-vestavenych-skrini.cz'
            css={css`
              color: ${colors.white};
            `}
          >
            info@3d-konfigurator-vestavenych-skrini.cz
          </a>
          {` `}
          ohledně možnosti vytvoření nové konfigurace.
        </div>
      </div>
    </>
  )
}
