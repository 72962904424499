/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React, { useEffect, useState } from "react"
import Designer from "../designer/right/designer"
import Enter from "../../img/enter.svg"
import Axios from "axios"
import { NavLink, useParams } from "react-router-dom"
import SummaryIconActive from "../../img/summary-active.svg"
import Loading from "../../img/loading.svg"
import Search from "./search"
import { useLocation } from "react-router-dom"

export default function OrderList() {
  const [orders, setOrders] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [nextPage, setNextPage] = useState()
  const [prevPage, setPrevPage] = useState()
  const [totalPages, setTotalPages] = useState()

  const { page } = useParams()

  const location = useLocation()

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      const tokenRes = await Axios.post(`/users/tokenIsValid`, null, {
        headers: { "x-auth-token": token }
      })
      if (tokenRes.data && !location.pathname.endsWith(`search`)) {
        const userRes = await Axios.get(`/orders/page/${page - 1}`, {
          headers: { "x-auth-token": token }
        })
        setNextPage(userRes.data.nextPage)
        setPrevPage(userRes.data.prevPage)
        setTotalPages(userRes.data.totalPages)
        setOrders(userRes.data.docs)
      }
      setLoading(false)
    }
    checkLoggedIn()
  }, [isLoading, location.pathname, page])

  const colors = useTheme()
  return (
    <>
      <h2
        css={css`
          display: flex;
          &:before {
            background-image: url(${SummaryIconActive});
            content: "";
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            display: inline-flex;
          }
        `}
      >
        Výpis objednávek
      </h2>
      {!isLoading ? (
        <>
          <Search setOrders={setOrders} />
          <div
            css={css`
              overflow-x: auto;
            `}
          >
            <div
              css={css`
                font-size: 13px;
                display: table;
                width: 100%;
                > div {
                  display: table-row;
                  background: #f2f2f2;
                  > div {
                    display: table-cell;
                    vertical-align: middle;
                    border-bottom: 1px solid ${colors.green};
                    padding: 5px;
                  }
                }
                > div:nth-of-type(even) {
                  background: #fff;
                }
              `}
            >
              <div
                css={css`
                  background: #cccccc !important;
                  > div {
                    margin-bottom: 10px;
                    font-weight: 600;
                    border-bottom: 2px solid ${colors.green};
                    padding-bottom: 5px;
                    font-size: 13px;
                    text-transform: uppercase;
                  }
                `}
              >
                <div>Čas objednání</div>
                <div>Objednatel</div>
                <div>E-mail</div>
                <div>Telefon</div>
                <div>Ulice</div>
                <div>Popisné číslo</div>
                <div>PSČ</div>
                <div
                  css={css`
                    min-width: 200px;
                    @media only screen and (max-width: 768px) {
                      min-width: 0;
                    }
                  `}
                >
                  Poznámka
                </div>
                <div
                  css={css`
                    width: 200px;
                    @media only screen and (max-width: 768px) {
                      display: none !important;
                    }
                  `}
                >
                  Náhled
                </div>
                <div
                  css={css`
                    width: 100px;
                    text-align: center;
                  `}
                >
                  Detail
                </div>
              </div>
              {orders.map((order) => {
                let minutes
                const dateDB = new Date(order.date)
                const month = dateDB.getMonth() + 1
                const date = dateDB.getDate() + `. ` + month + `. ` + dateDB.getFullYear()
                if (dateDB.getMinutes() < 10) minutes = `0` + dateDB.getMinutes()
                else minutes = dateDB.getMinutes()
                const time = dateDB.getHours() + `:` + minutes
                const dateTime = date + ` ` + time
                return (
                  <div
                    key={order._id}
                    css={css`
                      align-items: center;
                      .email {
                        word-wrap: break-word;
                        max-width: 200px;
                      }
                    `}
                  >
                    <div>{dateTime}</div>
                    <div>
                      {order.buyOnICO
                        ? order.customerCompanyName
                        : `${order.name} ${order.surName}`}
                    </div>
                    <div className='email'>{order.email}</div>
                    <div>{order.phone}</div>
                    <div>{order.street}</div>
                    <div>{order.cp}</div>
                    <div>{order.zip}</div>
                    <div>{order.note}</div>
                    <div
                      css={css`
                        @media only screen and (max-width: 768px) {
                          display: none !important;
                        }
                      `}
                    >
                      <Designer
                        prm={order}
                        setPrm={() => {}}
                        bgColor={order.bgColor}
                        textureURL={order.textureURL}
                        textureFrameURL={order.textureFrameURL}
                        doorsTextures={order.doorsTextures}
                        size='small'
                      />
                    </div>
                    <div
                      css={css`
                        text-align: center;
                      `}
                    >
                      <NavLink to={`detail/${order._id}`}>
                        <img src={Enter} alt='Detail objednávky' width='30' />
                      </NavLink>
                    </div>
                  </div>
                )
              })}
            </div>
            {orders.length === 0 && (
              <div
                css={css`
                  font-size: 14px;
                  margin-top: 10px;
                `}
              >
                Nebyla nalezena žádná objednávka.
              </div>
            )}
          </div>
          {!location.pathname.endsWith(`search`) && (
            <div
              css={css`
                margin-top: 10px;
                text-align: center;
                position: relative;
                a {
                  background: #fff;
                  color: ${colors.gold};
                  padding: 5px 10px;
                  text-decoration: none;
                  font-weight: 500;
                  font-size: 15px;
                }
              `}
            >
              <div
                css={css`
                  float: left;
                `}
              >
                {page !== `1` && (
                  <NavLink
                    to={`1`}
                    css={css`
                      margin-right: 4px;
                    `}
                  >
                    První stránka
                  </NavLink>
                )}
                {prevPage && <NavLink to={`${prevPage}`}>« Předchozí stránka</NavLink>}
              </div>
              <div
                css={css`
                  position: absolute;
                  left: 49%;
                  color: ${colors.white};
                `}
              >
                {page}/{totalPages}
              </div>
              <div
                css={css`
                  float: right;
                `}
              >
                {nextPage && <NavLink to={`${nextPage}`}>Následující stránka »</NavLink>}

                {page !== totalPages && (
                  <NavLink
                    to={`${totalPages}`}
                    css={css`
                      margin-left: 4px;
                    `}
                  >
                    Poslední stránka
                  </NavLink>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          css={css`
            position: relative;
            height: 400px;
          `}
        >
          <img
            src={Loading}
            alt='Loading...'
            css={css`
              position: absolute;
              top: calc(50%);
              left: calc(50% - 141px);
            `}
          />
        </div>
      )}
    </>
  )
}
