/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useEffect, useState } from "react"
import { useParams, NavLink } from "react-router-dom"
import Axios from "axios"
import { useTheme } from "emotion-theming"

export default function VerifyEmail() {
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const { token } = useParams()
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const verifyEmailRes = await Axios.post(`/users/verifyEmail/${token}`)
        if (verifyEmailRes) setSuccess(true)
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg)
      }
    }
    verifyEmail()
  }, [token])

  const colors = useTheme()

  return (
    <div
      css={css`grid-area:g;
          display:flex;
          flex-direction:column;
          max-width: 1400px;
          margin: 0 auto;
          width: 100%;
          padding: 0 10px;
        }`}
    >
      <h2>Verifikace e-mailu</h2>
      <div
        css={css`
          color: ${colors.red};
          font-weight: 500;
        `}
      >
        {error}
      </div>
      {success && (
        <div
          css={css`
            color: ${colors.green};
            font-weight: 500;
            a {
              color: ${colors.green};
            }
          `}
        >
          Verifikace e-mailu proběhla úspěšně nyní se můžete{` `}
          <NavLink to='/login'>přihlásit</NavLink>!
        </div>
      )}
    </div>
  )
}
