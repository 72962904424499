/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useEffect, useState } from "react"
import Table from "./components/table"
import Axios from "axios"
import { useTheme } from "emotion-theming"
import { Link } from "react-router-dom"
import UserIcon from "../../img/user.svg"

export default function User() {
  const [user, setUser] = useState(true)
  const [error, setError] = useState()
  const [errorChangePass, setErrorChangePass] = useState()
  const [errorChangeTerms, setErrorChangeTerms] = useState()
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(true)
  const [newPassword, setNewPassword] = useState()
  const [newPasswordCheck, setNewPasswordCheck] = useState()
  const [changePassword, setChangePassword] = useState(false)
  const [newTerms, setNewTerms] = useState()
  const [changeTerms, setChangeTerms] = useState(false)

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        let token = localStorage.getItem(`auth-token`)
        if (token === null) {
          localStorage.setItem(`auth-token`, ``)
          token = ``
        }
        const tokenRes = await Axios.post(`/users/tokenIsValid`, null, {
          headers: { "x-auth-token": token }
        })
        if (tokenRes.data) {
          const userRes = await Axios.get(`/users/user`, {
            headers: { "x-auth-token": token }
          })
          setUser(userRes.data)
          setLoading(false)
        }
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg)
      }
    }
    checkLoggedIn()
  }, [])

  const submitNewPassword = async (e) => {
    try {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      await Axios.post(
        `/users/changePassword`,
        { newPassword, newPasswordCheck },
        {
          headers: { "x-auth-token": token }
        }
      )
      setErrorChangePass()
      setSuccess(`Heslo bylo úspěšně změněno.`)
      setTimeout(function() {
        setSuccess()
        setChangePassword(false)
      }, 5000)
    } catch (err) {
      if (err.response.data.msg) {
        setErrorChangePass(err.response.data.msg)
      }
    }
  }

  const submitNewTerms = async (e) => {
    try {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      await Axios.post(
        `/users/changeTerms`,
        { newTerms },
        {
          headers: { "x-auth-token": token }
        }
      )
      setErrorChangeTerms()
      setSuccess(`Obchodní podmínky byly úspěšně změněny.`)
      setTimeout(function() {
        setSuccess()
        setChangeTerms(false)
      }, 5000)
      setUser({ ...user, terms: newTerms })
    } catch (err) {
      if (err.response.data.msg) {
        setErrorChangeTerms(err.response.data.msg)
      }
    }
  }

  const colors = useTheme()
  return (
    <>
      <h2
        css={css`
          display: flex;
          &:before {
            content: "";
            background-image: url(${UserIcon});
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            display: inline-flex;
          }
        `}
      >
        Uživatelský účet
      </h2>
      {error}
      <div
        css={css`
          max-width: 40%;
          @media only screen and (max-width: 768px) {
            max-width: 100%;
          }
        `}
      >
        <Table>
          <div>
            <div className='label'>Uživatelské jméno:</div>
            <div className='value'>{user.displayName}</div>
          </div>
          <div>
            <div className='label'>E-mail:</div>
            <div className='value'>{user.email}</div>
          </div>
          <div>
            <div className='label'>Název společnosti:</div>
            <div className='value'>{user.company}</div>
          </div>
          <div>
            <div className='label'>IČ:</div>
            <div className='value'>{user.ic}</div>
          </div>
          <div>
            <div className='label'>Heslo:</div>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              ************
              <div
                css={css`
                  font-size: 12px;
                  color: ${colors.gold};
                  text-transform: uppercase;
                  text-decoration: underline;
                  cursor: pointer;
                  font-weight: bold;
                `}
                onClick={() => setChangePassword(!changePassword)}
              >
                {!changePassword ? `Změnit` : `Zavřít`}
              </div>
            </div>
          </div>
          {changePassword && (
            <div>
              {(success || errorChangePass) && (
                <div
                  css={css`
                    background: ${errorChangePass ? colors.red : `#60a26b`};
                    grid-column-start: 1;
                    grid-column-end: 3;
                    color: #fff;
                    padding: 10px;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    position: relative;
                  `}
                >
                  {errorChangePass}
                  {success}
                </div>
              )}
              <div className='label'>Nové heslo:</div>
              <input
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <div className='label'>Nové heslo pro kontrolu:</div>
              <input
                type='password'
                value={newPasswordCheck}
                onChange={(e) => setNewPasswordCheck(e.target.value)}
              />
              <input type='submit' value='Změnit heslo' onClick={submitNewPassword} />
            </div>
          )}
          <div>
            <div className='label'>Odkaz na obch. podmínky:</div>
            <div className='value'>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                {!loading && (
                  <Link
                    to={user.terms}
                    css={css`
                      color: ${colors.gold};
                    `}
                  >
                    {user.terms}
                  </Link>
                )}
                <div
                  css={css`
                    font-size: 12px;
                    color: ${colors.gold};
                    text-transform: uppercase;
                    text-decoration: underline;
                    cursor: pointer;
                    font-weight: bold;
                  `}
                  onClick={() => setChangeTerms(!changeTerms)}
                >
                  {!changeTerms ? `Změnit` : `Zavřít`}
                </div>
              </div>
            </div>
          </div>
          {changeTerms && (
            <div>
              {(success || errorChangeTerms) && (
                <div
                  css={css`
                    background: ${errorChangeTerms ? colors.red : `#60a26b`};
                    grid-column-start: 1;
                    grid-column-end: 3;
                    color: #fff;
                    padding: 10px;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    position: relative;
                  `}
                >
                  {errorChangeTerms}
                  {success}
                </div>
              )}
              <div className='label'>Nové obchodní podmínky:</div>
              <input type='text' value={newTerms} onChange={(e) => setNewTerms(e.target.value)} />
              <input type='submit' value='Změnit obchodní podmínky' onClick={submitNewTerms} />
            </div>
          )}
        </Table>
      </div>
    </>
  )
}
