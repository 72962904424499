/** @jsx jsx */
import React from "react"
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import CurrentPartSelect from "./currentPartSelect"
import { Switch, Route } from "react-router-dom"

function CanvasControl(props) {
  const prm = props.prm
  const setPrm = props.setPrm

  function handleChange(e) {
    const value = e.target.name === `showCurrent` ? e.target.checked : parseInt(e.target.value)
    setPrm({ ...prm, [e.target.name]: value })
  }

  const handleColorChange = (color) => {
    setPrm({ ...prm, currentColor: color })
  }

  const WhiteCheckbox = withStyles({
    root: {
      color: `#ffffff`,
      padding: 4,
      "&$checked": {
        color: `#ffffff`
      }
    },
    checked: {}
  })((props) => <Checkbox color='default' {...props} />)

  const colors = useTheme()

  return (
    <div
      css={css`
        background: ${colors.grey};
        grid-area: f;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${colors.white};
        padding: 3px 15px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        border: 4px solid ${colors.gold};
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 1px;
        margin-right: 27px;
        @media only screen and (max-width: 1366px) {
          font-size: 10px;
        }
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          border-radius: 0;
          padding: 0 15px;
          align-items: center;
          margin-right: 0;
          border-right-width: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          @media only screen and (max-width: 768px) {
            display: none;
          }
        `}
      >
        <label
          css={css`
            margin-right: 8px;
          `}
        >
          Zvolte aktuální díl:
        </label>
        <CurrentPartSelect prm={prm} setPrm={setPrm} />
        <div
          css={css`
            display: inline-flex;
            @media only screen and (max-width: 992px) {
              display: none;
            }
          `}
        >
          (můžete také zvolit kliknutím přímo ve 3D modelu)
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <label
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
            @media only screen and (max-width: 768px) {
              margin: 0;
            }
          `}
        >
          <WhiteCheckbox
            checked={prm.showCurrent}
            onChange={(e) => handleChange(e)}
            name='showCurrent'
            size='small'
          />
          zvýraznit aktuálně
          <Switch>
            <Route path='/app/:owner/doors'>vybrané dveře</Route>
            <Route path='*'>vybranou skříň</Route>
          </Switch>
          {` `}
          <span
            css={css`
              margin-left: 4px;
              @media only screen and (max-width: 776px) {
                display: none;
              }
            `}
          >
            (H)
          </span>
        </label>
      </div>
    </div>
  )
}

export default CanvasControl
