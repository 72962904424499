/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState, useEffect } from "react"
import { useTheme } from "emotion-theming"
import Axios from "axios"
import VerticalArrow from "../../img/vertical-arrow.svg"
import Mirror from "../../img/mirror.jpg"
import printBgColor from "../../img/print-bg-color.png"

export default function DoorsConfig(props) {
  const order = props.order
  const [slotContentName, setSlotContentName] = useState([])
  const [slotContentImg, setSlotContentImg] = useState([])
  const [loadingSlotContent, setLoadingSlotContent] = useState(true)

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  const whereAreDoubleDoorsRight = order.parts.map((part, i) => {
    if (part === 2) {
      let sumOfPartsTillTargetPart = 0
      order.parts.forEach((part, j) => {
        if (j <= i) {
          sumOfPartsTillTargetPart = sumOfPartsTillTargetPart + part
        }
      })
      return sumOfPartsTillTargetPart
    } else {
      return null
    }
  })

  useEffect(() => {
    const fetchSlotContent = async () => {
      let i
      let j
      let slotContentNameCopy = []
      let slotContentImgCopy = []
      for (i = 0; i < order.doorsPictures.length; i++) {
        slotContentNameCopy[i] = []
        slotContentImgCopy[i] = []
        for (j = 0; j < order.doorsPictures[i].length; j++) {
          if (
            order.doorsPictures[i][j] !== null &&
            order.doorsPictures[i][j] !== `mirror` &&
            order.doorsPictures[i][j] !== `platinoveBila`
          ) {
            const apiCall = await Axios.get(`/textures/single/${order.doorsPictures[i][j]}`)
            slotContentNameCopy[i][j] = apiCall.data[0].name
            slotContentImgCopy[i][j] =
              `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/` + apiCall.data[0].url
          } else {
            slotContentNameCopy[i][j] = null
            slotContentImgCopy[i][j] = null
          }
        }
      }
      setSlotContentName(slotContentNameCopy)
      setSlotContentImg(slotContentImgCopy)
      setLoadingSlotContent(false)
    }
    fetchSlotContent()
  }, [bucketURL, order.doorsPictures])

  const sumOfParts = order.parts.reduce((a, b) => a + b, 0)
  let ratio
  let ratioPrint
  ratio = 1.5
  if (sumOfParts * ratio * order.width > 7) ratioPrint = 7 / sumOfParts / order.width

  const colors = useTheme()
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      {order.doorsSlots.map((doors, i) => {
        return (
          <div key={`i${i}`}>
            {!(
              (i === 0 && order.roundedLeft === 1) ||
              (i === sumOfParts - 1 && order.roundedRight === 1)
            ) && (
              <div
                css={css`
                  width: ${order.width * 100 * ratio}px;
                  @media print {
                    width: ${order.width * 100 * ratioPrint}px;
                  }
                  text-transform: uppercase;
                  font-size: 13px;
                  font-weight: 600;
                `}
              >
                {doors.map((door, j) => {
                  const sumOfSlots = doors.reduce((a, b) => a + b, 0)
                  return (
                    <>
                      <div
                        css={css`
                          height: ${(door / sumOfSlots) * (order.height * 100) * ratio}px;
                          width: ${order.width * 100 * ratio}px;
                          @media print {
                            height: ${(door / sumOfSlots) * (order.height * 100) * ratioPrint}px;
                            width: ${order.width * 100 * ratioPrint}px;
                          }
                          border-top: 2px solid ${order.doorsFrameColor};
                          border-bottom: 2px solid ${order.doorsFrameColor};
                          ${j !== doors.length - 1 && `border-bottom: 0;`};
                          border-right: 2px solid ${order.doorsFrameColor};
                          ${(i === 0 || (i === 1 && order.roundedLeft === 1)) &&
                            `border-left: 2px solid ${order.doorsFrameColor};`}
                          ${(i === sumOfParts - 1 ||
                            (i === sumOfParts - 2 && order.roundedRight === 1)) &&
                            `border-right: 2px solid ${order.doorsFrameColor};`};
                          text-align: center;
                          background: ${props.showParameters ? `transparent` : door};
                          position: relative;
                          z-index: 3;
                          justify-content: space-between;
                          display: flex;
                          flex-direction: column;
                          text-transform: uppercase;
                          font-size: 13px;
                          font-weight: 400;
                          .label {
                            font-weight: 600;
                          }
                        `}
                        key={`i${i}j${j}`}
                      >
                        <div
                          css={css`
                            ${order.doorsColors[i][j] !== null && !props.showParameters
                              ? `background: ${order.doorsColors[i][j]};`
                              : `background-image: url(${!loadingSlotContent &&
                                  slotContentImg[i][j]});`};
                            ${order.doorsColors[i][j] === `mirror` &&
                              `background-image: url(${Mirror});`};
                            background-size: contain;
                            color: ${colors.grey};
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            padding: 1px 0;
                            font-size: 11px;
                            font-weight: 600;
                            text-transform: uppercase;
                            overflow: hidden;
                          `}
                        >
                          <img
                            css={css`
                              height: 100%;
                              width: 100%;
                            `}
                            src={
                              order.doorsColors[i][j] === `mirror`
                                ? Mirror
                                : order.doorsTextures[i][j]
                            }
                            alt='Dekor dveří'
                          />
                        </div>
                        {props.showParameters &&
                          order.doorsPictures[i][j] !== null &&
                          order.doorsPictures[i][j] !== `platinoveBila` && (
                            <div
                              css={css`
                                background: ${colors.grey};
                                width: 100%;
                                color: ${colors.white};
                                padding: 1px 0;
                                font-size: 11px;
                                font-weight: 600;
                                text-transform: uppercase;
                                text-align: center;
                                border-top: 2px solid ${colors.grey};
                                border-bottom: 2px solid ${colors.grey};
                                position: absolute;
                                bottom: 0;
                              `}
                            >
                              <div
                                css={css`
                                  position: relative;
                                  z-index: 1;
                                `}
                              >
                                {loadingSlotContent ? `Načítám...` : slotContentName[i][j]}
                              </div>
                              <img
                                src={printBgColor}
                                alt='Název dekoru'
                                css={css`
                                  width: 100%;
                                  height: 100%;
                                  position: absolute;
                                  left: 0;
                                  top: 0;
                                  display: none;
                                  @media print {
                                    display: block;
                                  }
                                `}
                              />
                            </div>
                          )}
                        {props.showParameters && order.doorsColors[i][j] === `mirror` && (
                          <div
                            css={css`
                              background: ${colors.grey};
                              width: 100%;
                              color: ${colors.white};
                              padding: 1px 0;
                              font-size: 11px;
                              font-weight: 600;
                              text-transform: uppercase;
                              text-align: center;
                              border-top: 2px solid ${colors.grey};
                              border-bottom: 2px solid ${colors.grey};
                              position: absolute;
                              bottom: 0;
                            `}
                          >
                            <div
                              css={css`
                                position: relative;
                                z-index: 1;
                              `}
                            >
                              {`Zrcadlo`}
                            </div>
                            <img
                              src={printBgColor}
                              alt='Název dekoru'
                              css={css`
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                display: none;
                                @media print {
                                  display: block;
                                }
                              `}
                            />
                          </div>
                        )}
                        {props.showParameters && order.doorsPictures[i][j] === `platinoveBila` && (
                          <div
                            css={css`
                              background: ${colors.grey};
                              width: 100%;
                              color: ${colors.white};
                              padding: 1px 0;
                              font-size: 11px;
                              font-weight: 600;
                              text-transform: uppercase;
                              text-align: center;
                              border-top: 2px solid ${colors.grey};
                              border-bottom: 2px solid ${colors.grey};
                              position: absolute;
                              bottom: 0;
                            `}
                          >
                            <div
                              css={css`
                                position: relative;
                                z-index: 1;
                              `}
                            >
                              {`W980 ST2 Platinově bílá`}
                            </div>
                            <img
                              src={printBgColor}
                              alt='Název dekoru'
                              css={css`
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                display: none;
                                @media print {
                                  display: block;
                                }
                              `}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )
                })}
                {props.showParameters === 1 && order.doorsType === 0 && (
                  <div
                    css={css`
                      text-align: center;
                      background: #f2f2f2;
                      border: 1px solid #ccc;
                      padding: 1px 0;
                      font-size: 11px;
                      font-weight: 600;
                      text-transform: uppercase;
                      ${i === 0 && `border-left-width: 2px;`}
                      ${i === sumOfParts - 1 && `border-right-width: 2px;`}
                    `}
                  >
                    {order.doorsType === 0 &&
                      order.openDoorsRight[i] === false &&
                      !whereAreDoubleDoorsRight.includes(i + 1) &&
                      `Panty: vlevo`}
                    {order.doorsType === 0 &&
                      (order.openDoorsRight[i] === true ||
                        whereAreDoubleDoorsRight.includes(i + 1)) &&
                      `Panty: vpravo`}
                  </div>
                )}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
