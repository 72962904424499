/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import Model2DConfig from "./model2DConfig"
import Model2DContentSelect from "./model2DContentSelect"

export default function Model2D(props) {
  const prm = props.prm
  const realHeight = props.realHeight
  const sumOfSlots = props.sumOfSlots
  const ratio = props.ratio
  const setConfigError = props.setConfigError
  const setConfigErrorNumber = props.setConfigErrorNumber

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-grow: 1;
          margin: 10px 0 20px;
        `}
      >
        <Model2DConfig
          defaultPrm={props.prm}
          prm={props.prm}
          setPrm={props.setPrm}
          realHeight={realHeight}
          sumOfSlots={sumOfSlots}
          ratio={ratio}
          loop={1}
          setConfigError={setConfigError}
          setConfigErrorNumber={setConfigErrorNumber}
        />
      </div>
      {((prm.currentPart === 0 && prm.roundedLeft === 0) ||
        (prm.currentPart !== 0 && prm.currentPart !== prm.parts.length - 1) ||
        (prm.currentPart === prm.parts.length - 1 && prm.roundedRight === 0)) && (
        <Model2DContentSelect prm={props.prm} setPrm={props.setPrm} />
      )}
    </div>
  )
}
