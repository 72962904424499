/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState, useContext, useEffect } from "react"
import { useTheme } from "emotion-theming"
import { SketchPicker } from "react-color"
import Axios from "axios"
import UserContext from "../../context/UserContext"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import AddNew from "../../img/addNew.svg"
import Loading from "../../img/loading.svg"
import Texture from "../../img/texture.jpg"
import Csv from "../../img/csv.svg"
import RightArrow from "../../img/right-arrow.svg"
import DownArrow from "../../img/down-arrow.svg"

export default function Textures() {
  const [picker, setPicker] = useState(false)
  const [onlyColor, setOnlyColor] = useState(false)
  const [color, setColor] = useState(`#ffffff`)
  const [error, setError] = useState()
  const [errorCSV, setErrorCSV] = useState()
  const [success, setSuccess] = useState()
  const [successCSV, setSuccessCSV] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingCSV, setLoadingCSV] = useState(false)
  const [image, setImage] = useState({})
  const [csv, setCSV] = useState({})
  const [textures, setTextures] = useState([])

  const { userData } = useContext(UserContext)

  const handleColorChange = (color) => {
    setColor(color.hex)
  }

  function handleOnlyColorChange(e) {
    setOnlyColor(!onlyColor)
  }

  const deleteTexture = async (url) => {
    try {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      const tokenRes = await Axios.post(`/users/tokenIsValid`, null, {
        headers: { "x-auth-token": token }
      })
      if (tokenRes.data) {
        await Axios.delete(`/textures/delete/${url}`, {
          headers: { "x-auth-token": token }
        })
        Axios.get(`/textures/all/${userData.user.displayName}/1`)
          .then((res) => {
            setTextures(res.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } catch (err) {
      console.log(err.response.data.msg)
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    try {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      let color2
      const name = e.target[`name`].value
      const owner = userData.user.displayName
      if (!onlyColor) color2 = null
      else color2 = color
      const price = e.target[`price`].value
      const percent = e.target[`percent`].value
      const newTexture = new FormData()
      newTexture.append(`name`, name)
      newTexture.append(`color`, color2)
      newTexture.append(`owner`, owner)
      newTexture.append(`onlyColor`, onlyColor)
      newTexture.append(`image`, image)
      newTexture.append(`category`, 1)
      newTexture.append(`price`, price)
      newTexture.append(`percent`, percent)
      setError()
      setLoading(true)
      await Axios.post(`/textures/newTexture`, newTexture, {
        headers: { "x-auth-token": token }
      })
      setLoading(false)
      setError()
      setImage()
      setColor(`#ffffff`)
      document.getElementById(`name`).value = ``
      document.getElementById(`url`).value = ``
      document.getElementById(`price`).value = ``
      document.getElementById(`percent`).value = ``
      setSuccess(`Dekor byl úspěšně přidán.`)
      setTimeout(function() {
        setSuccess()
      }, 5000)
      Axios.get(`/textures/all/${userData.user.displayName}/1`)
        .then((res) => {
          setTextures(res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (err) {
      if (err.response.data.msg) {
        setError(err.response.data.msg)
        setLoading(false)
      }
    }
  }

  const downloadCSV = async () => {
    var csv = await Axios.get(`/textures/all/${userData.user.id}/1/csv`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        console.log(error)
      })
    if (csv == null) return

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,` + csv
    }
    const data = encodeURI(csv)
    const link = document.createElement(`a`)
    link.setAttribute(`href`, data)
    link.setAttribute(`download`, `dekory-konfigurator-web.csv`)
    link.click()
  }

  const uploadCSV = async () => {
    try {
      const newTexture = new FormData()
      newTexture.append(`csv`, csv)
      setErrorCSV()
      setLoadingCSV(true)
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      await Axios.post(`/textures/editViaCSV`, newTexture, {
        headers: { "x-auth-token": token }
      })
      setLoadingCSV(false)
      setSuccessCSV(`Import dat proběhl v pořádku.`)
      document.getElementById(`csv`).value = ``
      setTimeout(function() {
        setSuccessCSV()
      }, 5000)
      Axios.get(`/textures/all/${userData.user.displayName}/1`)
        .then((res) => {
          setTextures(res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (err) {
      setErrorCSV(err.response.data.msg)
    }
  }

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  const WhiteCheckbox = withStyles({
    root: {
      color: `#ffffff`,
      padding: 4,
      "&$checked": {
        color: `#ffffff`
      }
    },
    checked: {}
  })((props) => <Checkbox color='default' {...props} />)

  useEffect(() => {
    Axios.get(`/textures/all/${userData.user.displayName}/1`)
      .then((res) => {
        setTextures(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [userData.user.displayName])

  const colors = useTheme()

  return (
    <>
      <h2
        css={css`
          display: flex;
          &:before {
            content: "";
            background-image: url(${Texture});
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            display: inline-flex;
          }
        `}
      >
        Správa dekorů
      </h2>
      {textures.length === 0 && (
        <div
          css={css`
            background: #ccc;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 20px;
          `}
        >
          Zatím nemáte nadefinované žádné dekory.
        </div>
      )}
      {textures.length > 0 && (
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, 90px);
            grid-gap: 10px;
            justify-content: space-between;
            margin-bottom: 20px;
            background: #ccc;
            padding: 10px;
          `}
        >
          {textures.map((texture) => (
            <div
              key={texture._id}
              css={css`
                background: ${colors.grey};
                color: ${colors.white};
                text-align: center;
                border: 2px solid ${colors.grey};
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              `}
            >
              <div>
                <div
                  css={css`
                    cursor: pointer;
                    background: ${colors.red};
                  `}
                  onClick={() => deleteTexture(texture.url)}
                >
                  Odstranit
                </div>
                <img
                  src={
                    `https://` +
                    bucketURL +
                    `.s3.eu-central-1.amazonaws.com/small-` +
                    texture.url.substring(4)
                  }
                  alt={texture.name}
                  css={css`
                    max-width: 86px;
                  `}
                />
              </div>
              <div
                css={css`
                  padding: 4px 0 7px 0;
                  font-size: 11px;
                  font-weight: 600;
                  text-transform: uppercase;
                  ${texture.color && `border-bottom: 5px solid ${texture.color}`}
                `}
              >
                {texture.name}
                <div
                  css={css`
                    background: ${colors.gold};
                    margin-top: 10px;
                  `}
                >
                  {texture.price} Kč/m²
                </div>
                <div
                  css={css`
                    background: lightgreen;
                    color: ${colors.grey};
                  `}
                >
                  {texture.percent} %
                </div>
              </div>
            </div>
          ))}
          {!error && loading && (
            <img
              src={Loading}
              alt='Loading...'
              height='128'
              css={css`
                position: relative;
                left: -20px;
              `}
            />
          )}
        </div>
      )}
      <div
        css={css`
          background: #ccc;
          padding: 10px;
          margin-bottom: 20px;
        `}
      >
        <h3
          css={css`
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            margin-top: 0;
            border-bottom: 2px solid #000;
            padding-bottom: 5px;
            color: #000;
          `}
        >
          Hromadná editace dekorů
        </h3>
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            button,
            label,
            img,
            input {
              margin-top: 4px;
              margin-bottom: 4px;
            }
            button {
              all: unset;
              display: flex;
              align-items: center;
              background: none;
              border-radius: 5px;
              cursor: pointer;
              margin-right: 10px;
              font-weight: 600;
              font-size: 13px;
              text-transform: uppercase;
              &:hover {
                text-decoration: underline;
              }
            }
            label {
              font-weight: 600;
              font-size: 13px;
              text-transform: uppercase;
              margin-left: 10px;
            }
          `}
        >
          <button onClick={downloadCSV}>
            <img
              src={DownArrow}
              height='16'
              alt='Vygenerovat .csv'
              css={css`
                margin-right: 4px;
              `}
            />
            <img
              src={Csv}
              height='24'
              alt='Vygenerovat .csv'
              css={css`
                margin-right: 2px;
              `}
            />
            Stáhnout .csv soubor s dekory
          </button>
          <img src={RightArrow} width='18' alt='Šipka doprava' />
          <label for='csv'>Vybrat upravený .csv soubor z disku:</label>
          <input
            type='file'
            id='csv'
            css={css`
              width: 200px;
              height: auto;
            `}
            onChange={(e) => setCSV(e.target.files[0])}
          />
          <button onClick={uploadCSV}>
            <img
              src={DownArrow}
              height='16'
              alt='Vygenerovat .csv'
              css={css`
                margin-right: 4px;
                transform: rotate(180deg);
              `}
            />
            <img
              src={Csv}
              height='24'
              alt='Nahrát .csv'
              css={css`
                margin-right: 4px;
              `}
            />
            Nahrát upravený .csv soubor
          </button>
          {errorCSV && (
            <div
              css={css`
                background: #ff6c6c;
                color: #fff;
                padding: 4px 8px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
              `}
            >
              {errorCSV}
            </div>
          )}
          {loadingCSV && !errorCSV && (
            <div
              css={css`
                background: #f0ad4e;
                color: #fff;
                padding: 4px 8px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
              `}
            >
              Soubor se zpracovává...
            </div>
          )}
          {successCSV && (
            <div
              css={css`
                background: #60a26b;
                color: #fff;
                padding: 4px 8px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
              `}
            >
              {successCSV}
            </div>
          )}
        </div>
      </div>
      <form
        css={css`
          display: grid;
          max-width: 600px;
          width: 100%;
          grid-template-columns: auto auto;
          grid-row-gap: 10px;
          align-items: center;
          padding: 10px;
          background: #ccc;
          label {
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
          }
          input[type="file"] {
            padding: 0;
          }
          @media only screen and (max-width: 768px) {
            max-width: 100%;
            label,
            input {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }
        `}
        onSubmit={submit}
        method='post'
        encType='multipart/form-data'
      >
        <div
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            border-bottom: 2px solid #000;
            align-items: center;
            padding-bottom: 5px;
            img {
              margin-right: 5px;
            }
          `}
        >
          <img src={AddNew} width='25' alt='Add new texture' />
          Přidání nového dekoru:
        </div>
        {(success || loading || error) && (
          <div
            css={css`
              background: ${loading ? `#f0ad4e` : `#60a26b`};
              ${error && `background: ${colors.red};`}
              grid-column-start: 1;
              grid-column-end: 3;
              color: #fff;
              padding: 10px;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              position: relative;
            `}
          >
            {error}
            {success}
            {loading && (
              <>
                Dekor se nahrává...
                <img
                  src={Loading}
                  alt='Loading...'
                  height='70'
                  css={css`
                    position: absolute;
                    right: -17px;
                    top: -17px;
                  `}
                />
              </>
            )}
          </div>
        )}
        <label>Název dekoru:</label>
        <input name='name' id='name' type='text' />
        <label>Nákupní cena za m² bez DPH:</label>
        <input name='price' id='price' type='text' />
        <label>Marže v %:</label>
        <input name='percent' id='percent' type='text' />
        <label>Textura, příp. náhledový obrázek:</label>
        <input name='url' id='url' type='file' onChange={(e) => setImage(e.target.files[0])} />
        <label
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            display: flex;
            align-items: center;
            span {
              padding-left: 0;
              padding-right: 5px;
            }
          `}
        >
          <WhiteCheckbox
            checked={onlyColor}
            onChange={(e) => handleOnlyColorChange(e)}
            name='onlyColor'
            size='small'
          />
          Náhledový obrázek nelze zároveň použít jako texturu, proto chci nadefinovat pouze barvu
          vystihující nahraný náhledový obrázek
        </label>
        {onlyColor && (
          <>
            <label>Barva dekoru:</label>
            <div
              css={css`
                position: relative;
              `}
            >
              {picker === true && (
                <div
                  css={css`
                    position: absolute;
                    z-index: 2;
                    top: 40px;
                  `}
                >
                  <div
                    css={css`
                      position: fixed;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                    `}
                    onClick={() => setPicker(false)}
                  ></div>
                  <SketchPicker color={color} onChange={handleColorChange} />
                </div>
              )}
              <input type='text' name='color' value={color} onClick={() => setPicker(true)} />
            </div>
          </>
        )}
        <input
          type='submit'
          value='Přidat dekor'
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            cursor: pointer;
            &:hover {
              color: white;
              background: ${colors.green};
            }
          `}
        />
      </form>
    </>
  )
}
