/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React, { useContext, useEffect, useState } from "react"
import UserContext from "../../context/UserContext"
import { Switch, Route, useHistory, NavLink, useLocation } from "react-router-dom"
import OrderDetail from "../admin/orderDetail"
import OrderList from "../admin/orderList"
import Textures from "../admin/textures"
import DoorsFrames from "../admin/doorsFrames"
import DoorsTextures from "../admin/doorsTextures"
import Configuration from "../admin/configuration"
import LogoPage from "../admin/logoPage"
import Favicon from "../admin/favicon"
import Fakturoid from "../admin/fakturoid"
import ColorIconActive from "../../img/art-active.svg"
import Logout from "../../img/logout.svg"
import SummaryIconActive from "../../img/summary-active.svg"
import Texture from "../../img/texture.jpg"
import DoorsIconActive from "../../img/doors-active.svg"
import UserIcon from "../../img/user.svg"
import Colors from "../admin/colors"
import User from "../admin/user"
import Logo from "../../img/logo.png"
import Link from "../../img/link.svg"
import Menu from "../../img/menu.svg"
import PartsIconActive from "../../img/parts-active.svg"

export default function Admin() {
  const history = useHistory()
  const { userData, setUserData } = useContext(UserContext)
  const colors = useTheme()
  const domain = window.location.origin

  const [showMenu, setShowMenu] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)

  const logout = () => {
    setUserData({ token: undefined, user: undefined })
    localStorage.setItem(`auth-token`, ``)
    history.push(`/login`)
  }

  useEffect(() => {
    const menuVisibility = () => {
      if (window.innerWidth < 769 && !menuOpen) setShowMenu(false)
      else {
        setShowMenu(true)
      }
    }
    menuVisibility()
    window.addEventListener(`resize`, menuVisibility)

    return () => {
      window.removeEventListener(`resize`, menuVisibility)
    }
  }, [menuOpen])

  const location = useLocation()

  return (
    <div
      css={css`
        display: flex;
        flex-grow: 1;
        padding: 0 16px;
        border-top: 1px solid ${colors.gold};
        padding-top: 20px;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          padding: 0 5px;
        }
        ${location.pathname.startsWith(`/admin`) &&
          `@media only screen and (max-width: 768px) {
          margin-top: 0
        }`}
        @media print {
          padding: 0;
          border-top: 0;
        }
      `}
    >
      {userData.user && (
        <>
          <div
            css={css`
              flex-shrink: 0;
              max-width: 340px;
              ul li,
              li a {
                width: 100%;
                list-style-type: none;
                color: #fff;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
                margin: 10px 0;
                text-decoration: none;
                &.active {
                  cursor: pointer;
                  background: #fff;
                  font-weight: bold;
                  color: #232429;
                }
              }
              li a,
              li span {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 15px;
              }
              .secondLevel {
                li,
                a {
                  margin: 0;
                }
                a {
                  padding-left: 20px;
                }
              }
              @media only screen and (max-width: 768px) {
                max-width: 100%;
                ul,
                ul li,
                li a {
                  margin-top: 0;
                }
                ul li,
                li a {
                  margin-bottom: 2px;
                }
                ul {
                  margin-bottom: 0;
                }
              }
              @media print {
                display: none;
              }
            `}
          >
            <div
              css={css`
                background: ${colors.green};
                color: #fff;
                padding: 10px;
                font-weight: bold;
                text-transform: uppercase;
                justify-content: space-between;
                display: flex;
                font-size: 14px;
                align-items: center;
                margin-top: 4px;
                cursor: pointer;
                display: none;
                @media only screen and (max-width: 768px) {
                  display: flex;
                }
              `}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span>{showMenu ? `Skrýt menu` : `Ukázat menu`}</span>
              <img src={Menu} alt='Menu' height='24' />
            </div>
            {showMenu && (
              <ul
                css={css`
                  padding-left: 0;
                `}
              >
                <li>
                  <NavLink
                    to='/admin/orders/1'
                    className={location.pathname.startsWith(`/admin/orders`) && `active`}
                    css={css`
                      &:before {
                        content: "";
                        background-image: url(${SummaryIconActive});
                        display: inline-flex;
                        background-size: 25px 25px;
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                      }
                    `}
                  >
                    Objednávky
                  </NavLink>
                </li>
                <li className='secondLevel'>
                  <div
                    css={css`
                      width: 100%;
                      padding: 10px 15px 8px 15px;
                      cursor: initial;
                      color: #fff;
                      background: #000;
                    `}
                  >
                    Administrace aplikace
                  </div>
                  <ul
                    css={css`
                      padding-left: 0;
                      li,
                      a {
                        margin: 0;
                      }
                      a {
                        padding-left: 30px;
                        border-bottom: 1px solid #fff;
                      }
                    `}
                  >
                    <li>
                      <NavLink
                        to='/admin/configuration'
                        activeClassName='active'
                        css={css`
                          &:before {
                            content: "";
                            background-image: url(${PartsIconActive});
                            display: inline-flex;
                            background-size: 25px 25px;
                            width: 25px;
                            height: 25px;
                            margin-right: 10px;
                          }
                        `}
                      >
                        Nastavení vnitřků skříně
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/admin/textures'
                        activeClassName='active'
                        css={css`
                          &:before {
                            content: "";
                            background-image: url(${Texture});
                            display: inline-flex;
                            background-size: 25px 25px;
                            width: 25px;
                            height: 25px;
                            margin-right: 10px;
                          }
                        `}
                      >
                        Nastavení dekorů
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/admin/doorsFrames'
                        activeClassName='active'
                        css={css`
                          &:before {
                            content: "";
                            background-image: url(${DoorsIconActive});
                            display: inline-flex;
                            background-size: 25px 25px;
                            width: 25px;
                            height: 25px;
                            margin-right: 10px;
                          }
                        `}
                      >
                        Nastavení rámů dveří
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/admin/doorsTextures'
                        activeClassName='active'
                        css={css`
                          &:before {
                            content: "";
                            background-image: url(${DoorsIconActive});
                            display: inline-flex;
                            background-size: 25px 25px;
                            width: 25px;
                            height: 25px;
                            margin-right: 10px;
                          }
                        `}
                      >
                        Nastavení dekorů dveří
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/admin/colors'
                        activeClassName='active'
                        css={css`
                          &:before {
                            content: "";
                            background-image: url(${ColorIconActive});
                            display: inline-flex;
                            background-size: 25px 25px;
                            width: 25px;
                            height: 25px;
                            margin-right: 10px;
                          }
                        `}
                      >
                        Barevné schéma konfigurátoru
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink
                    to='/admin/user'
                    activeClassName='active'
                    css={css`
                      &:before {
                        content: "";
                        background-image: url(${UserIcon});
                        display: inline-flex;
                        background-size: 25px 25px;
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                      }
                    `}
                  >
                    Uživatelský účet
                  </NavLink>
                </li>
                <li
                  onClick={logout}
                  css={css`
                    background: #000;
                  `}
                >
                  <span
                    css={css`
                      &:before {
                        content: "";
                        background-image: url(${Logout});
                        display: inline-flex;
                        background-size: 23px 23px;
                        width: 23px;
                        height: 23px;
                        margin-right: 9px;
                        margin-left: 3px;
                      }
                    `}
                  >
                    Odhlásit se
                  </span>
                </li>
              </ul>
            )}
            <div
              css={css`
                margin-top: 30px;
                margin-bottom: 16px;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
                background: #fff;
                border: 1px solid ${colors.green};
                padding: 10px 15px;
                @media only screen and (max-width: 768px) {
                  margin-top: 10px;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  img {
                    margin-right: 5px;
                  }
                `}
              >
                <img src={Link} height='16' alt='Odkaz' />
                URL konfigurátoru:
              </div>
              <NavLink
                to={`/app/${userData.user.displayName}`}
                activeClassName='active'
                css={css`
                  text-transform: none;
                  margin-top: 10px;
                  display: flex;
                  color: #000;
                  font-weight: 600;
                `}
                target='_blank'
                rel='noopener noreferrer'
              >
                {domain}/#/app/
                {userData.user.displayName}
              </NavLink>
            </div>
          </div>
          <div
            css={css`
              margin: 0 0 16px 16px;
              padding-top: 0;
              min-width: 0;
              flex-grow: 1;
              h2 {
                text-transform: uppercase;
                margin-top: 10px;
                margin-bottom: 20px;
                color: #fff;
              }
              @media only screen and (max-width: 768px) {
                margin-left: 0;
                margin-top: 10px;
              }
              @media print {
                margin: 0;
                padding: 0;
              }
            `}
          >
            <Switch>
              <Route exact path='/admin/orders/:page'>
                <OrderList />
              </Route>
              <Route path='/admin/orders/detail/:id'>
                <OrderDetail />
              </Route>
              <Route exact path='/admin/textures'>
                <Textures />
              </Route>
              <Route exact path='/admin/doorsFrames'>
                <DoorsFrames />
              </Route>
              <Route exact path='/admin/doorsTextures'>
                <DoorsTextures />
              </Route>
              <Route exact path='/admin/configuration'>
                <Configuration />
              </Route>
              <Route exact path='/admin/colors'>
                <Colors />
              </Route>
              <Route exact path='/admin/logo'>
                <LogoPage />
              </Route>
              <Route exact path='/admin/favicon'>
                <Favicon />
              </Route>
              <Route exact path='/admin/user'>
                <User />
              </Route>
              {/*
              <Route exact path='/admin/fakturoid'>
                <Fakturoid />
              </Route>
              */}
            </Switch>
          </div>
        </>
      )}
    </div>
  )
}
