/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Axios from "axios"
import GreenButton from "../../modules/greenButton"
import { useParams } from "react-router-dom"

function Step2(props) {
  const prm = props.prm
  const setPrm = props.setPrm
  const setTextureURL = props.setTextureURL
  const [textures, setTextures] = useState([])
  const setCurrentPage = props.setCurrentPage

  const { owner } = useParams()

  useEffect(() => {
    setCurrentPage(2)
    Axios.get(`/textures/all/${owner}/1`)
      .then((res) => {
        setTextures(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [owner, setCurrentPage])

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  const colors = useTheme()
  return (
    <>
      <div>
        <h2>Zvolte dekor:</h2>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, 90px);
            grid-gap: 8px;
            justify-content: space-between;
          `}
        >
          <div
            key={`platinoveBila`}
            css={css`
              background: ${colors.grey};
              color: ${colors.white};
              text-align: center;
              cursor: pointer;
              border: 2px solid
                ${prm.textureId === `platinoveBila` ? `${prm.currentColor}` : `${colors.grey}`};
            `}
            onClick={() => {
              setPrm({
                ...prm,
                textureId: `platinoveBila`
              })
              setTextureURL(
                `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`
              )
            }}
          >
            <img
              src={
                `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/small-platinoveBila.jpeg`
              }
              alt={`W980 ST2 Platinově bílá`}
              css={css`
                max-width: 86px;
              `}
            />
            <div
              css={css`
                padding: 4px 0 7px 0;
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
                word-wrap: break-word;
              `}
            >
              W980 ST2 Platinově bílá
            </div>
          </div>
          {textures.map((texture) => (
            <div
              key={texture._id}
              css={css`
                background: ${colors.grey};
                color: ${colors.white};
                text-align: center;
                cursor: pointer;
                border: 2px solid
                  ${prm.textureId === texture._id ? `${prm.currentColor}` : `${colors.grey}`};
              `}
              onClick={() => {
                setPrm({
                  ...prm,
                  textureId: texture._id
                })
                texture.color !== `null`
                  ? setPrm({
                      ...prm,
                      textureId: texture._id,
                      color: texture.color
                    })
                  : setTextureURL(
                      `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/` + texture.url
                    )
                texture.color !== `null` && setTextureURL(null)
              }}
            >
              <img
                src={`https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/` + texture.url}
                alt={texture.name}
                css={css`
                  max-width: 86px;
                `}
              />
              <div
                css={css`
                  padding: 4px 0 7px 0;
                  font-size: 11px;
                  font-weight: 600;
                  text-transform: uppercase;
                  word-wrap: break-word;
                `}
              >
                {texture.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <GreenButton value='Pokračovat na definici vybavení' link='./parts' />
    </>
  )
}

export default Step2
