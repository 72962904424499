/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"

export default function CookiesPage() {
  return (
    <div
      css={css`grid-area:g;
        display:flex;
        flex-direction:column;
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 20px;
        line-height: 28px;
        ul{
          margin-bottom: 40px;
          margin-top: 40px;
        }
        h2{
          text-align: center;
          color: #ff6200;
          margin-bottom: 40px;
        }
        li{
          list-style-type: none;
          color: #fff;
        }
        p{
          color: #fff;
        }
      }`}
    >
      <h2>Informace ukládané do Vašeho prohlížeče („Cookies“)</h2>
      <p>
        Informace, které jsou o Vás uchovávány těmito webovými stránkami ve Vašem prohlížeči, jsou
        pouze tzv. technické informace. Těmito informacemi rozumějte takové informace, které je
        nutné ukládat, aby mohly stránky využívat některých běžných funkcí webu.
      </p>
      <p>
        V případě tohoto webu se jedná konkrétně o informace ukládané do tzv. lokálního uložiště
        prohlížeče (localStorage), přičemž je o Vás ukládány pouze tato informace:
      </p>
      <ul>
        <li>1) Zda jste již "odklikl(a)" tzv. cookies lištu.</li>
      </ul>
      <p>Tento web pak neukládá žádné informace využívané třetími stranami.</p>
    </div>
  )
}
