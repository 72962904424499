/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React, { useState } from "react"
import Axios from "axios"
import { NavLink, useHistory, useLocation } from "react-router-dom"

export default function Search(props) {
  const setOrders = props.setOrders

  const [searchedTerm, setSearchedTerm] = useState()
  const [error, setError] = useState()
  const [column, setColumn] = useState(`id`)

  let history = useHistory()
  let location = useLocation()

  const searchBy = (column) => {
    const search = async () => {
      try {
        history.push(`/admin/orders/search`)
        let token = localStorage.getItem(`auth-token`)
        if (token === null) {
          localStorage.setItem(`auth-token`, ``)
          token = ``
        }
        const tokenRes = await Axios.post(`/users/tokenIsValid`, null, {
          headers: { "x-auth-token": token }
        })
        if (tokenRes.data) {
          let userRes
          if (column === `id`) {
            userRes = await Axios.get(`/orders/search/id/${searchedTerm}`, {
              headers: { "x-auth-token": token }
            })
          } else if (column === `email`) {
            userRes = await Axios.get(`/orders/search/email/${searchedTerm}`, {
              headers: { "x-auth-token": token }
            })
          }
          setOrders(userRes.data)
        }
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg)
        setOrders([])
      }
    }
    search()
  }

  const colors = useTheme()

  return (
    <div
      css={css`
        margin-bottom: 10px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          font-size: 13px;
          select,
          input,
          button {
            height: 38px;
          }
          select,
          input {
            margin-left: 4px;
          }
        `}
      >
        <span
          css={css`
            margin-right: 10px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #fff;
          `}
        >
          Vyhledat objednávku:
        </span>
        <div
          css={css`
            span {
              color: #fff;
            }
          `}
        >
          <span>dle</span>
          <select
            value={column}
            onChange={(e) => {
              setColumn(e.target.value)
            }}
            css={css`
              margin-right: 10px;
            `}
          >
            <option value='id'>čísla objednávky</option>
            <option value='email'>e-mailu</option>
          </select>
          <span>{column === `id` && `číslo objednávky:`}</span>
          <span>{column === `email` && `E-mail:`}</span>
          <input
            type='text'
            value={searchedTerm}
            css={css`
              width: 200px;
            `}
            onChange={(e) => {
              setSearchedTerm(e.target.value)
            }}
          />
          <button
            onClick={() => searchBy(column)}
            css={css`
              border: 0;
              background: ${colors.gold};
              color: #fff;
              text-transform: uppercase;
              margin-left: 6px;
              cursor: pointer;
              &:hover {
                color: #fff;
              }
            `}
          >
            Vyhledat
          </button>
        </div>
      </div>
      {location.pathname.endsWith(`search`) && (
        <NavLink
          to={`1`}
          css={css`
            font-size: 13px;
            color: ${colors.gold};
            &:hover {
              color: ${colors.gold};
            }
          `}
        >
          Zpět na výpis všech objednávek
        </NavLink>
      )}
    </div>
  )
}
