/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { Switch, Route } from "react-router-dom"
import MenuItem from "@material-ui/core/MenuItem"

export default function CurrentPartSelect(props) {
  const prm = props.prm
  const setPrm = props.setPrm
  const sumOfParts = prm.parts.reduce((a, b) => a + b, 0)

  function handleChange(e) {
    const value = e.target.name === `showCurrent` ? e.target.checked : parseInt(e.target.value)
    setPrm({ ...prm, [e.target.name]: value })
  }
  return (
    <div
      css={css`
        margin-right: 8px;
        .MuiFormControl-root {
          min-width: 70px;
        }
        .MuiInputBase-root {
          text-transform: lowercase;
        }
        @media only screen and (max-width: 768px) {
          margin-right: 5px;
        }
      `}
    >
      <FormControl>
        <Switch>
          <Route exact path='/app/:owner/doors'>
            <Select
              name='currentDoorPart'
              value={prm.currentDoorPart}
              onChange={(e) => handleChange(e)}
            >
              {[...Array(sumOfParts)].map((el, i) => (
                <MenuItem value={i} key={i}>
                  {`${i + 1}. díl`}
                  {` `}
                </MenuItem>
              ))}
            </Select>
          </Route>
          <Route path='*'>
            <Select name='currentPart' value={prm.currentPart} onChange={(e) => handleChange(e)}>
              {[...Array(prm.parts.length)].map((el, i) => (
                <MenuItem value={i} key={i}>
                  {`${i + 1}. díl`}
                  {` `}
                </MenuItem>
              ))}
            </Select>
          </Route>
        </Switch>
      </FormControl>
    </div>
  )
}
