/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Axios from "axios"
import { useParams, Link } from "react-router-dom"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import ErrorNotice from "../../modules/errorNotice"
import SuccessNotice from "../../modules/successNotice"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"

function Step5(props) {
  const prm = props.prm
  const appId = props.appId
  const bgColor = props.bgColor
  const textureURL = props.textureURL
  const textureFrameURL = props.textureFrameURL
  const doorsTextures = props.doorsTextures

  const [termsLink, setTermsLink] = useState()
  const [error, setError] = useState()
  const [send, setSend] = useState(0)

  const { owner } = useParams()

  const [personalInfo, setPersonalInfo] = useState({
    name: localStorage.name,
    surName: localStorage.surName,
    email: localStorage.email,
    customerCompanyName: localStorage.customerCompanyName,
    ico: localStorage.ico,
    dic: localStorage.dic,
    street: localStorage.street,
    cp: localStorage.cp,
    city: localStorage.city,
    zip: localStorage.zip,
    street2: localStorage.street2,
    cp2: localStorage.cp2,
    city2: localStorage.city2,
    zip2: localStorage.zip2,
    phone: localStorage.phone,
    note: localStorage.note,
    terms: localStorage.terms === `true` ? true : false,
    measurement: localStorage.measurement === `true` ? true : false,
    construction: localStorage.construction === `true` ? true : false,
    transport: localStorage.transport ? parseInt(localStorage.transport) : 0,
    buyOnICO: localStorage.buyOnICO === `true` ? true : false,
    secondAddress: localStorage.secondAddress === `true` ? true : false
  })

  const createOrder = async (newOrder) => {
    try {
      await Axios.post(`/orders/saveOrder`, newOrder)
      setPersonalInfo({
        name: ``,
        surName: ``,
        email: ``,
        customerCompanyName: ``,
        ico: ``,
        dic: ``,
        street: ``,
        cp: ``,
        city: ``,
        zip: ``,
        street2: ``,
        cp2: ``,
        city2: ``,
        zip2: ``,
        phone: ``,
        note: ``,
        terms: false,
        measurement: false,
        construction: false,
        transport: 0,
        buyOnICO: false,
        secondAddress: false
      })
      localStorage.removeItem(`name`)
      localStorage.removeItem(`surName`)
      localStorage.removeItem(`email`)
      localStorage.removeItem(`customerCompanyName`)
      localStorage.removeItem(`ico`)
      localStorage.removeItem(`dic`)
      localStorage.removeItem(`street`)
      localStorage.removeItem(`cp`)
      localStorage.removeItem(`city`)
      localStorage.removeItem(`zip`)
      localStorage.removeItem(`street2`)
      localStorage.removeItem(`cp2`)
      localStorage.removeItem(`city2`)
      localStorage.removeItem(`zip2`)
      localStorage.removeItem(`phone`)
      localStorage.removeItem(`note`)
      localStorage.removeItem(`terms`)
      localStorage.removeItem(`measurement`)
      localStorage.removeItem(`construction`)
      localStorage.removeItem(`transport`)
      localStorage.removeItem(`buyOnICO`)
      localStorage.removeItem(`secondAddress`)
      setError()
      setSend(1)
    } catch (err) {
      if (err.response.data.msg) {
        setError(err.response.data.msg)
      }
    }
  }

  const colors = useTheme()

  const WhiteCheckbox = withStyles({
    root: {
      color: `${colors.grey}`,
      padding: 4,
      "&$checked": {
        color: `${colors.grey}`
      }
    },
    checked: {}
  })((props) => <Checkbox color='default' {...props} />)

  useEffect(() => {
    const checkLoggedIn = async () => {
      const userRes = await Axios.get(`/users/all/${owner}`)
      setTermsLink(userRes.data.terms)
    }
    checkLoggedIn()
  }, [owner])

  function handleSubmit(e) {
    const date = new Date()
    createOrder({
      ...prm,
      ...personalInfo,
      showCurrent: false,
      owner: appId,
      bgColor,
      textureURL,
      textureFrameURL,
      doorsTextures,
      date,
      ownerName: owner,
      primaryColor: colors.green,
      secondaryColor: colors.gold
    })
    const leftScroll = document.getElementById(`leftScroll`)
    if (leftScroll) leftScroll.childNodes[0].scrollTop = 0
    e.preventDefault()
  }

  function handleChange(e) {
    const value = e.target.value
    setPersonalInfo({ ...personalInfo, [e.target.name]: value })
    localStorage.setItem([e.target.name], value)
  }

  function handleChangeTerms(e) {
    setPersonalInfo({ ...personalInfo, terms: !personalInfo.terms })
    localStorage.setItem(`terms`, !personalInfo.terms)
  }

  function handleChangeMeasurement(e) {
    setPersonalInfo({ ...personalInfo, measurement: !personalInfo.measurement })
    localStorage.setItem(`measurement`, !personalInfo.measurement)
  }

  function handleChangeConstruction(e) {
    setPersonalInfo({ ...personalInfo, construction: !personalInfo.construction })
    localStorage.setItem(`construction`, !personalInfo.construction)
  }

  const handleChangeRadio = (e) => {
    setPersonalInfo({ ...personalInfo, [e.target.name]: parseInt(e.target.value) })
    localStorage.setItem(`transport`, parseInt(e.target.value))
  }

  function handleChangeBuyOnIco(e) {
    setPersonalInfo({ ...personalInfo, buyOnICO: !personalInfo.buyOnICO })
    localStorage.setItem(`buyOnICO`, !personalInfo.buyOnICO)
  }

  function handleChangeSecondAddress(e) {
    setPersonalInfo({ ...personalInfo, secondAddress: !personalInfo.secondAddress })
    localStorage.setItem(`secondAddress`, !personalInfo.secondAddress)
  }

  return (
    <div
      css={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <h2>Odeslání poptávky</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        css={css`
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex-grow: 1;
          .star {
            color: #ff6c6c;
          }
          div > .column {
            grid-template-columns: 30% 70%;
          }
        `}
      >
        <div>
          {error && <ErrorNotice message={error} marginBottom={10} />}
          {send === 1 && (
            <SuccessNotice message='Poptávka byla odeslána. Děkujeme!' marginBottom={10} />
          )}
          <div
            className='column'
            css={css`
              grid-row-gap: 10px;
            `}
          >
            <div
              css={css`
                grid-column-start: 1;
                grid-column-end: 3;
                font-size: 13px;
                font-weight: 500;
                color: ${colors.grey};
                display: flex;
                align-items: center;
                margin-left: -4px;
              `}
            >
              <WhiteCheckbox
                size='small'
                checked={personalInfo.buyOnICO}
                onChange={(e) => handleChangeBuyOnIco(!personalInfo.buyOnICO)}
              />
              <span
                onClick={(e) => handleChangeBuyOnIco(!personalInfo.buyOnICO)}
                css={css`
                  cursor: pointer;
                `}
              >
                Nakupuji na firmu
              </span>
            </div>
            {personalInfo.buyOnICO && (
              <>
                <label>
                  Název firmy: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='customerCompanyName'
                  value={personalInfo.customerCompanyName}
                  onChange={(e) => handleChange(e)}
                />
                <label>
                  IČO: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='ico'
                  value={personalInfo.ico}
                  onChange={(e) => handleChange(e)}
                />
                <label>DIČ:</label>
                <input
                  type='dic'
                  name='dic'
                  value={personalInfo.dic}
                  onChange={(e) => handleChange(e)}
                />
              </>
            )}
            {!personalInfo.buyOnICO && (
              <>
                <label>
                  Jméno: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='name'
                  value={personalInfo.name}
                  onChange={(e) => handleChange(e)}
                />
                <label>
                  Příjmení: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='surName'
                  value={personalInfo.surName}
                  onChange={(e) => handleChange(e)}
                />
              </>
            )}
            <label>
              E-mail: <span className='star'>*</span>
            </label>
            <input
              type='email'
              name='email'
              value={personalInfo.email}
              onChange={(e) => handleChange(e)}
            />
            <div
              css={css`
                grid-column-start: 1;
                grid-column-end: 3;
                font-size: 13px;
                font-weight: 500;
                color: ${colors.grey};
                display: flex;
                align-items: center;
                margin-left: -4px;
              `}
            >
              <span
                css={css`
                  padding-left: 5px;
                  font-size: 14px;
                  font-weight: bold;
                `}
              >
                {personalInfo.secondAddress ? `Fakturační adresa:` : `Adresa:`}
              </span>
            </div>
            <label>
              Ulice: <span className='star'>*</span>
            </label>
            <input
              type='text'
              name='street'
              value={personalInfo.street}
              onChange={(e) => handleChange(e)}
            />
            <label>
              Č. p.: <span className='star'>*</span>
            </label>
            <input
              type='text'
              name='cp'
              value={personalInfo.cp}
              onChange={(e) => handleChange(e)}
            />
            <label>
              Město: <span className='star'>*</span>
            </label>
            <input
              type='text'
              name='city'
              value={personalInfo.city}
              onChange={(e) => handleChange(e)}
            />
            <label>
              PSČ: <span className='star'>*</span>
            </label>
            <input
              type='text'
              name='zip'
              value={personalInfo.zip}
              onChange={(e) => handleChange(e)}
            />
            <div
              css={css`
                grid-column-start: 1;
                grid-column-end: 3;
                font-size: 13px;
                font-weight: 500;
                color: ${colors.grey};
                display: flex;
                align-items: center;
                margin-left: -4px;
              `}
            >
              <WhiteCheckbox
                size='small'
                checked={personalInfo.secondAddress}
                onChange={(e) => handleChangeSecondAddress(!personalInfo.secondAddress)}
              />
              <span
                onClick={(e) => handleChangeSecondAddress(!personalInfo.secondAddress)}
                css={css`
                  cursor: pointer;
                `}
              >
                Dodací adresa je jiná než fakturační adresa
              </span>
            </div>
            {personalInfo.secondAddress && (
              <>
                <div
                  css={css`
                    grid-column-start: 1;
                    grid-column-end: 3;
                    font-size: 13px;
                    font-weight: 500;
                    color: ${colors.grey};
                    display: flex;
                    align-items: center;
                    margin-left: -4px;
                  `}
                >
                  <span
                    css={css`
                      padding-left: 5px;
                      font-size: 14px;
                      font-weight: bold;
                    `}
                  >
                    Dodací adresa:
                  </span>
                </div>
                <label>
                  Ulice: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='street2'
                  value={personalInfo.street2}
                  onChange={(e) => handleChange(e)}
                />
                <label>
                  Č. p.: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='cp2'
                  value={personalInfo.cp2}
                  onChange={(e) => handleChange(e)}
                />
                <label>
                  Město: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='city2'
                  value={personalInfo.city2}
                  onChange={(e) => handleChange(e)}
                />
                <label>
                  PSČ: <span className='star'>*</span>
                </label>
                <input
                  type='text'
                  name='zip2'
                  value={personalInfo.zip2}
                  onChange={(e) => handleChange(e)}
                />
              </>
            )}
            <div
              css={css`
                height: 10px;
              `}
            ></div>
            <div></div>
            <label>
              Telefon: <span className='star'>*</span>
            </label>
            <input
              type='tel'
              name='phone'
              value={personalInfo.phone}
              onChange={(e) => handleChange(e)}
            />
            <label
              css={css`
                align-self: flex-start;
              `}
            >
              Poznámka:
            </label>
            <textarea
              name='note'
              value={personalInfo.note}
              onChange={(e) => handleChange(e)}
              css={css`
                height: 72px;
                padding: 10px;
              `}
            />
            <div
              css={css`
                grid-column-start: 1;
                grid-column-end: 3;
                font-size: 13px;
                font-weight: 500;
                color: ${colors.grey};
                display: flex;
                align-items: center;
                margin-left: -4px;
              `}
            >
              <WhiteCheckbox
                size='small'
                checked={personalInfo.measurement}
                onChange={(e) => handleChangeMeasurement(!personalInfo.measurement)}
              />
              <span
                onClick={(e) => handleChangeMeasurement(!personalInfo.measurement)}
                css={css`
                  cursor: pointer;
                `}
              >
                Chci zaměření na místě
              </span>
            </div>
            <div
              css={css`
                grid-column-start: 1;
                grid-column-end: 3;
                font-size: 13px;
                font-weight: 500;
                color: ${colors.grey};
                display: flex;
                align-items: center;
                margin-left: -4px;
              `}
            >
              <WhiteCheckbox
                size='small'
                checked={personalInfo.construction}
                onChange={(e) => handleChangeConstruction(!personalInfo.construction)}
              />
              <span
                onClick={(e) => handleChangeConstruction(!personalInfo.construction)}
                css={css`
                  cursor: pointer;
                `}
              >
                Chci sestavit skříň na místě
              </span>
            </div>
            <div
              css={css`
                grid-column-start: 1;
                grid-column-end: 3;
                display: flex;
                align-items: center;
                .MuiFormGroup-root {
                  flex-grow: 1;
                  justify-content: flex-end;
                }
                .MuiFormControlLabel-root {
                  flex-basis: unset;
                  padding: 0 6px;
                }
              `}
            >
              <label>Doprava:</label>
              <RadioGroup
                name='transport'
                value={personalInfo.transport}
                onChange={(e) => handleChangeRadio(e)}
              >
                <FormControlLabel value={0} control={<Radio />} label='Chci doručení na místo' />
                <FormControlLabel value={1} control={<Radio />} label='Osobní odběr' />
              </RadioGroup>
            </div>
            <div
              css={css`
                font-size: 11px;
                font-weight: 600;
                background: ${colors.grey};
                color: ${colors.gold};
                grid-column-start: 1;
                grid-column-end: 3;
                padding: 5px 10px;
                margin-top: 5px;
              `}
            >
              Po odeslání poptávky budou zpracovávány Vámi výše uvedené osobní údaje. Více informací
              o tom, jak je nakládáno s Vašimi osobními údaji, naleznete{` `}
              <Link
                to='https://www.lj-kuchyne.cz/ochrana-osobnich-udaju'
                target='_blank'
                rel='noopener noreferrer'
                css={css`
                  color: #fff;
                `}
              >
                zde
              </Link>
              .
            </div>
            <div
              css={css`
                grid-column-start: 1;
                grid-column-end: 3;
                font-size: 13px;
                font-weight: 500;
                color: ${colors.grey};
                display: flex;
                align-items: center;
                margin-left: -4px;
              `}
            >
              <WhiteCheckbox
                size='small'
                checked={personalInfo.terms}
                onChange={(e) => handleChangeTerms(!personalInfo.terms)}
              />
              <span
                onClick={(e) => handleChangeTerms(!personalInfo.terms)}
                css={css`
                  cursor: pointer;
                `}
              >
                Souhlasím s
              </span>
              <a
                href={termsLink}
                css={css`
                  position: relative;
                  cursor: pointer;
                  color: ${colors.grey};
                  margin-left: 5px;
                  text-decoration: underline;
                  &:hover {
                    color: ${colors.white};
                  }
                `}
                target='_blank'
                rel='noopener noreferrer'
              >
                obchodními podmínkami
              </a>
            </div>
          </div>
        </div>
        <div>
          <input
            type='submit'
            value='Odeslat poptávku'
            css={css`
              width: 100%;
              height: 50px;
              background: ${colors.green};
              color: ${colors.white};
              text-transform: uppercase;
              font-weight: 600;
              border-radius: 3px;
              cursor: pointer;
              border: 3px solid ${colors.green};
              transition: 200ms;
              font-weight: 700;
              font-size: 15px !important;
            `}
          />
          <div // GG Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=748518
            css={css`
              height: 2px;
              width: 100%;
            `}
          ></div>
        </div>
      </form>
    </div>
  )
}

export default Step5
