/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState, useEffect } from "react"
import Step1 from "./components/designer/left/step1"
import Step2 from "./components/designer/left/step2"
import Step3 from "./components/designer/left/step3"
import Step4 from "./components/designer/left/step4"
import Step5 from "./components/designer/left/step5"
import LeftCol from "./components/designer/left/leftCol"
import Designer from "./components/designer/right/designer"
import Demo from "./components/designer/demo"
import CanvasControl from "./components/designer/right/canvasControl"
import Menu from "./components/designer/left/menu"
import Header from "./components/homepage/header"
import Footer from "./components/homepage/footer"
import Cookies from "./components/homepage/cookies"
import Page404 from "./components/pages/page404"
import CookiesPage from "./components/pages/cookies"
import Login from "./components/pages/login"
import Register from "./components/pages/register"
import ForgotPassword from "./components/pages/forgotPassword"
import NewPassword from "./components/pages/newPassword"
import VerifyEmail from "./components/pages/verifyEmail"
import Terms from "./components/pages/terms"
import PrivacyPolicy from "./components/pages/privacyPolicy"
import PrivacyPolicyOrders from "./components/pages/privacyPolicyOrders"
import UnsupportedBrowser from "./components/pages/unsupportedBrowser"
import Admin from "./components/pages/Admin"
import ScrollTop from "./components/modules/scrollTop"
import "react-image-lightbox/style.css"
import OrderDetail from "./components/admin/orderDetail"
import { Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom"
import UserContext from "./context/UserContext"
import Axios from "axios"
import { ThemeProvider } from "emotion-theming"

function App() {
  const [bgColor, setBgColor] = useState(`#ffffff`)
  const [appId, setAppId] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [cookiesBar, setCookiesBar] = useState(localStorage.getItem(`cookieBar`))
  const [configError, setConfigError] = useState()
  const [configErrorNumber, setConfigErrorNumber] = useState()

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (
      navigator.userAgent.match(/Trident\/7\./) ||
      navigator.userAgent.match(/Trident\/6\./) ||
      navigator.userAgent.match(/Trident\/5\./) ||
      navigator.userAgent.match(/Trident\/4\./)
    ) {
      window.document.getElementById(`app`).classList.add(`ie`)
      if (location.pathname.startsWith(`/app`)) history.push(`/unsupported-browser`)
    }
  }, [history, location, location.pathname])

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  const [prm, setPrm] = useState({
    appId: ``,
    width: 0.6666,
    inputWidth: 0.6666,
    roundedWidth: 0.35,
    inputRoundedWidth: 0.2,
    inputHeight: 2.5,
    height: 2.5,
    depth: 0.6,
    inputDepth: 0.6,
    color: `#493627`,
    whiteInside: 1,
    textureId: `platinoveBila`,
    backDesk: 0,
    bottomDesk: 1,
    topDesk: 1,
    leftDesk: 1,
    rightDesk: 1,
    roundedType: 1,
    roundedRight: 0,
    roundedLeft: 0,
    bottomSokl: 0,
    soklHeight: 0.04,
    inputSoklHeight: 0.04,
    deskWidth: 0.018,
    inputDeskWidth: 0.02,
    showTopSpace: false,
    topSpaceHeight: 0.4,
    currentPart: 0,
    currentDoorPart: 0,
    showCurrent: true,
    currentColor: `#ffa000`,
    doors: 1,
    showDoors: true,
    openDoors: false,
    showDimensions: false,
    isFrame: 1,
    doorsType: 1, // 0: classic, 1: sliding
    doorsFrameColor: `#EAEAEA`,
    doorsFrameId: `factorPrirodni`,
    openDoorsRight: [false, false, false],
    doorsColors: [[``], [``], [``]],
    doorsSlots: [[1], [1], [1]],
    doorsPictures: [[`platinoveBila`], [`platinoveBila`], [`platinoveBila`]],
    currentDoorSlot: [0, 0, 0],
    parts: [1, 1, 1],
    config: [
      [1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1]
    ],
    content: [
      // 0:empty, 1:drawer, 2:hangerHolder
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0]
    ],
    currentSlot: [0, 0, 0]
  })

  const [doorsTextures, setDoorsTextures] = useState([
    [`https://${bucketURL}.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`],
    [`https://${bucketURL}.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`],
    [`https://${bucketURL}.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`]
  ])

  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined
  })

  useEffect(() => {
    localStorage.removeItem(`name`)
    localStorage.removeItem(`surName`)
    localStorage.removeItem(`email`)
    localStorage.removeItem(`street`)
    localStorage.removeItem(`cp`)
    localStorage.removeItem(`city`)
    localStorage.removeItem(`zip`)
    localStorage.removeItem(`phone`)
    localStorage.removeItem(`note`)
    localStorage.removeItem(`measurement`)
    localStorage.removeItem(`construction`)
    localStorage.removeItem(`transport`)
    const checkLoggedIn = async () => {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      const tokenRes = await Axios.post(`/users/tokenIsValid`, null, {
        headers: { "x-auth-token": token }
      })
      if (tokenRes.data) {
        const userRes = await Axios.get(`/users/`, {
          headers: { "x-auth-token": token }
        })
        setUserData({
          token,
          user: userRes.data
        })
      }
    }
    checkLoggedIn()
  }, [])

  const [textureURL, setTextureURL] = useState(
    `https://${bucketURL}.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`
  )

  const [textureFrameURL, setTextureFrameURL] = useState(null)

  const [fullscreen, setFullscreen] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.target.type !== `text` &&
        event.target.type !== `tel` &&
        event.target.type !== `email` &&
        event.target.type !== `textarea`
      ) {
        if (location.pathname.startsWith(`/app`) && event.keyCode === 70) setFullscreen(!fullscreen)
        if (location.pathname !== `/` && event.keyCode === 72)
          setPrm({ ...prm, showCurrent: !prm.showCurrent })
        if (location.pathname !== `/` && event.keyCode === 82)
          setPrm({ ...prm, showDimensions: !prm.showDimensions })
        if (
          (location.pathname.endsWith(`/doors`) || location.pathname.endsWith(`/summary`)) &&
          event.keyCode === 68
        )
          setPrm({ ...prm, openDoors: !prm.openDoors })
      }
    }
    window.addEventListener(`keydown`, handleKeyDown)

    return () => {
      window.removeEventListener(`keydown`, handleKeyDown)
    }
  }, [fullscreen, location.pathname, prm])

  const colors = {
    green: `#1a3a3a`,
    gold: `#ff6200`,
    white: `#e7e2f6`,
    grey: `#3c3941`,
    red: `#FF6C6C`,
    label: `#000000`,
    color6: `#e7e2f6`
  }

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      <ScrollTop />
      <div
        className='App'
        id='app'
        css={css`
          max-width: 100%;
          margin: auto;
          ${location.pathname.startsWith(`/app`) ? `height: 100vh` : `min-height: 100vh`};
          background: #232429;
          transition: 200ms;
          display: ${location.pathname.startsWith(`/admin`) ? `flex` : `grid`};
          ${location.pathname.startsWith(`/admin`) && `flex-direction: column;`};
          grid-template-areas: ${location.pathname.startsWith(`/app`)
            ? `"a a a"
            "e c c"
            "b c c"
            "b f f"
            "d d d";
            grid-template-rows: ${fullscreen ? 0 : 88}px 60px auto 52px ${fullscreen ? 0 : 608}px;
            grid-template-columns: 455px auto auto;`
            : `"a"
            "g"
            "d";
            grid-template-rows: 88px 1fr 608px;
            grid-template-columns: auto;
            `};
          ${location.pathname.endsWith(`/summary`) &&
            `grid-template-areas: "a a a"
              "e c c"
              "b c c"
              "b c c"
              "d d d";`};
          @media only screen and (max-width: 1199px) {
            grid-template-rows: ${fullscreen ? 0 : 121}px 60px auto 52px ${fullscreen ? 0 : 608}px;
          }
          @media only screen and (max-width: 768px) {
            grid-template-columns: auto;
            grid-template-areas: ${location.pathname.startsWith(`/app`)
              ? `
                "a"
              "f"
              "c"
              "e"
              "b";
              grid-template-rows: auto auto 36% auto 1fr;`
              : `"a" "g"; grid-template-rows: 60px 1fr`};
            max-width: 100%;
          }
          select {
            font-size: 13px;
          }
          input,
          textarea {
            font-size: 13px;
          }
          @media print {
            grid-template-areas: "g";
          }
        `}
      >
        <Switch>
          <Redirect from='/:url*(/+)' to={location.pathname.slice(0, -1)} />
          <Redirect exact from='/' to='/app/3d/dimensions' />
          <Redirect exact from='/admin' to='/admin/orders/1' />
          <Redirect exact from='/app/:owner' to='/app/:owner/dimensions' />
          <Route path='(/app|/order)/:owner'>
            <Demo setBgColor={setBgColor} setAppId={setAppId}>
              <Switch>
                <Route path='/order/:owner/:id'>
                  <Header />
                </Route>
                <Route path='/app/:owner'>
                  <Header fullscreen={fullscreen} />
                </Route>
              </Switch>
              <Switch>
                <Route path='/order/:owner/:id'>
                  <div
                    css={css`
                      grid-area: g;
                      padding: 10px 30px 20px;
                      @media only screen and (max-width: 768px) {
                        padding: 0 10px;
                      }
                      @media print {
                        padding: 0;
                      }
                    `}
                  >
                    <ThemeProvider theme={colors}>
                      <OrderDetail />
                    </ThemeProvider>
                  </div>
                </Route>
                <Route path='*'>
                  <Menu
                    currentPage={currentPage}
                    configError={configError}
                    configErrorNumber={configErrorNumber}
                    prm={prm}
                    setPrm={setPrm}
                  />
                  <LeftCol>
                    <Switch>
                      <Route exact path='/app/:owner/dimensions'>
                        <Step1
                          prm={prm}
                          setPrm={setPrm}
                          doorsTexturesMap={doorsTextures}
                          setDoorsTexturesMap={setDoorsTextures}
                        />
                      </Route>
                      <Route exact path='/app/:owner/colors'>
                        <Step2
                          prm={prm}
                          setPrm={setPrm}
                          textureURL={textureURL}
                          setTextureURL={setTextureURL}
                          bgColor={bgColor}
                          setBgColor={setBgColor}
                          setCurrentPage={setCurrentPage}
                        />
                      </Route>
                      <Route exact path='/app/:owner/parts'>
                        <Step3
                          prm={prm}
                          setPrm={setPrm}
                          setCurrentPage={setCurrentPage}
                          setConfigError={setConfigError}
                          configError={configError}
                          configErrorNumber={configErrorNumber}
                          setConfigErrorNumber={setConfigErrorNumber}
                        />
                      </Route>
                      <Route exact path='/app/:owner/doors'>
                        <Step4
                          prm={prm}
                          setPrm={setPrm}
                          textureFrameURL={textureFrameURL}
                          setTextureFrameURL={setTextureFrameURL}
                          doorsTexturesMap={doorsTextures}
                          setDoorsTexturesMap={setDoorsTextures}
                          setCurrentPage={setCurrentPage}
                        />
                      </Route>
                      <Route exact path='/app/:owner/summary'>
                        <Step5
                          prm={prm}
                          appId={appId}
                          bgColor={bgColor}
                          textureURL={textureURL}
                          textureFrameURL={textureFrameURL}
                          doorsTextures={doorsTextures}
                          setCurrentPage={setCurrentPage}
                        />
                      </Route>
                    </Switch>
                  </LeftCol>
                  <Designer
                    prm={prm}
                    setPrm={setPrm}
                    bgColor={bgColor}
                    textureURL={textureURL}
                    textureFrameURL={textureFrameURL}
                    doorsTextures={doorsTextures}
                    setDoorsTextures={setDoorsTextures}
                    fullscreen={fullscreen}
                    setFullscreen={setFullscreen}
                  ></Designer>
                  <Switch>
                    <Route path='/app/:owner/summary'></Route>
                    <Route path='*'>
                      <CanvasControl prm={prm} setPrm={setPrm} />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
              <Footer fullscreen={fullscreen} />
            </Demo>
          </Route>
          <Route path='*'>
            <Header />
            <Switch>
              <Route exact path='/login'>
                <Login />
              </Route>
              <Route exact path='/register'>
                <Register />
              </Route>
              <Route exact path='/forgotPassword'>
                <ForgotPassword />
              </Route>
              <Route exact path='/obchodni-podminky'>
                <Terms />
              </Route>
              <Route exact path='/ochrana-osobnich-udaju'>
                <PrivacyPolicy />
              </Route>
              <Route exact path='/ochrana-osobnich-udaju-objednavky'>
                <PrivacyPolicyOrders />
              </Route>
              <Route exact path='/unsupported-browser'>
                <UnsupportedBrowser />
              </Route>
              <Route exact path='/cookies'>
                <CookiesPage />
              </Route>
              <Route exact path='/verifyEmail/:token'>
                <VerifyEmail />
              </Route>
              <Route exact path='/newPassword/:resetToken'>
                <NewPassword />
              </Route>
              <Route path='/admin'>
                <Admin />
              </Route>
              <Route path='*'>
                <Page404 />
              </Route>
            </Switch>
            <Footer />
          </Route>
        </Switch>
        {cookiesBar !== `false` && <Cookies setCookiesBar={setCookiesBar} />}
      </div>
    </UserContext.Provider>
  )
}

export default App
