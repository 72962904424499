import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "./main.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { HashRouter as Router } from "react-router-dom"
import { ThemeProvider } from "emotion-theming"

const colors = {
  green: `#ff6200`,
  gold: `#ff6200`,
  white: `#e7e2f6`,
  grey: `#232429`,
  red: `#FF6C6C`,
  label: `#000000`,
  color6: `#e7e2f6`
}

window.$bucket = `lj-kuchyne`

ReactDOM.render(
  <Router>
    <ThemeProvider theme={colors}>
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById(`root`)
)

if (module.hot) {
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
