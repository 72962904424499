/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import Model2DConfig from "./model2DConfig"
import configurations from "./configurations.json"

export default function PartConfiguration(props) {
  return (
    <Model2DConfig
      defaultPrm={props.prm}
      prm={configurations}
      setPrm={props.setPrm}
      realHeight={props.realHeight}
      sumOfSlots={props.sumOfSlots}
      ratio={props.ratio}
      loop={configurations}
      numbers={true}
      modal={props.modal}
    />
  )
}
