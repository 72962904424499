/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React, { useState, useEffect, useContext } from "react"
import GreenButton from "../../modules/greenButton"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Model2D from "./model2D"
import PartConfiguration from "./partConfiguration"
import Slider from "@material-ui/core/Slider"
import Input from "@material-ui/core/Input"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { Scrollbars } from "react-custom-scrollbars"
import Axios from "axios"
import UserContext from "../../../context/UserContext"
import { useParams } from "react-router-dom"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"

function Step3(props) {
  const prm = props.prm
  const setPrm = props.setPrm
  const setCurrentPage = props.setCurrentPage
  const configError = props.configError
  const [error, setError] = useState()
  const setConfigError = props.setConfigError
  const configErrorNumber = props.configErrorNumber
  const setConfigErrorNumber = props.setConfigErrorNumber
  const [loaded, setLoaded] = useState(false)
  const [configurationNumbers, setConfigurationNumbers] = useState([])

  const [open, setOpen] = useState(false)

  const { userData } = useContext(UserContext)
  const { owner } = useParams()

  const [expand, setExpand] = useState(false)

  const colors = useTheme()

  function handleChangeDesks(e) {
    const value = parseInt(e.target.value) + 1
    const config = Array(prm.config.length)
      .fill(1)
      .map((el, j) => {
        if (j === prm.currentPart) {
          return Array(value).fill(1)
        } else return prm.config[j]
      })
    const content = Array(prm.config.length)
      .fill(1)
      .map((el, j) => {
        if (j === prm.currentPart) {
          return Array(value).fill(0)
        } else return prm.content[j]
      })
    const currentSlot = prm.currentSlot.slice()
    currentSlot[prm.currentPart] = 0
    setPrm({
      ...prm,
      config: config,
      content: content,
      currentSlot: currentSlot
    })
  }

  const handleSliderTopSpaceHeightChange = (event, newValue) => {
    setPrm({ ...prm, topSpaceHeight: newValue / 100 })
  }

  const handleInputTopSpaceHeightChange = (event) => {
    setPrm({
      ...prm,
      topSpaceHeight: Number(event.target.value / 100)
    })
  }

  const str2bool = (value) => {
    if (value && typeof value === `string`) {
      if (value.toLowerCase() === `true`) return true
      if (value.toLowerCase() === `false`) return false
    }
    return value
  }

  const handleChangeRadio = (e) => {
    setPrm({ ...prm, [e.target.name]: str2bool(e.target.value) })
  }

  const displayName = userData.user ? userData.user.displayName : owner

  useEffect(() => {
    setCurrentPage(3)
    const loadConfigurationNumbers = async () => {
      try {
        const loadedConfigurationNumbers = await Axios.get(
          `/users/configurationNumbers/${displayName}`
        )
        setConfigurationNumbers(loadedConfigurationNumbers.data.configuration)
        setLoaded(true)
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg)
      }
    }
    loadConfigurationNumbers()
  }, [displayName, setCurrentPage])

  return (
    <>
      <div>
        <h2>Chcete horní úložný prostor přes celou šířku skříně?</h2>
        <div className='column'>
          <label>Horní úložný prostor:</label>
          <RadioGroup name='showTopSpace' value={prm.showTopSpace} onChange={handleChangeRadio}>
            <FormControlLabel value={true} control={<Radio />} label='Ano' />
            <FormControlLabel value={false} control={<Radio />} label='Ne' />
          </RadioGroup>
          {prm.showTopSpace && (
            <>
              <label>Výška tohoto prostoru:</label>
              <div>
                <Slider
                  value={prm.topSpaceHeight * 100}
                  onChange={handleSliderTopSpaceHeightChange}
                  aria-labelledby='input-slider'
                  min={30}
                  step={1}
                  max={80}
                />
                <div className='inputWrapper'>
                  <Input
                    value={Math.round(prm.topSpaceHeight * 100)}
                    onChange={handleInputTopSpaceHeightChange}
                    inputProps={{
                      step: 1,
                      min: 30,
                      max: 80,
                      type: `text`
                    }}
                  />
                  cm
                </div>
              </div>
            </>
          )}
        </div>
        <h2>Zvolte počet polic:</h2>
        <div className='column'>
          <label>Počet polic:</label>
          <FormControl>
            <Select value={prm.config[prm.currentPart].length - 1} onChange={handleChangeDesks}>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
            </Select>
          </FormControl>
        </div>
        {((prm.currentPart > 0 && prm.currentPart < prm.parts.length - 1) ||
          (prm.currentPart === prm.parts.length - 1 && prm.roundedRight === 0) ||
          (prm.currentPart === 0 && prm.roundedLeft === 0)) && (
          <>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              center
              styles={{
                modal: {
                  backgroundColor: colors.gold,
                  color: colors.grey,
                  paddingTop: 60,
                  width: `50%`,
                  maxWidth: `50%`
                }
              }}
            >
              <PartConfiguration prm={prm} setPrm={setPrm} modal={true} />
            </Modal>
            <div
              css={css`
                text-align: center;
                grid-column-start: 1;
                grid-column-end: 3;
                color: ${colors.white};
              `}
            >
              nebo
            </div>
            <h2>Zvolte vnitřní rozložení skříně:</h2>
            {error}
            {expand ? (
              <div
                css={css`
                  > div {
                    flex-wrap: wrap;
                    > div {
                      margin-bottom: 5px;
                    }
                  }
                `}
              >
                <PartConfiguration prm={prm} setPrm={setPrm} />
              </div>
            ) : (
              <Scrollbars
                style={{ height: 280 }}
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    css={css`
                      bottom: 2px;
                      left: 0;
                      width: 100%;
                      height: 7px !important;
                      > div {
                        width: ${loaded &&
                          (415 / (configurationNumbers.length * 68.5)) * 415}px !important;
                        background-color: ${colors.white} !important;
                      }
                    `}
                  />
                )}
              >
                <PartConfiguration prm={prm} setPrm={setPrm} />
              </Scrollbars>
            )}
            <div
              css={css`
                display: flex;
              `}
            >
              <button
                onClick={() => {
                  setExpand(!expand)
                }}
                css={css`
                  font-size: 12px;
                  cursor: pointer;
                  background-color: ${colors.grey};
                  color: ${colors.white};
                  padding: 5px 10px;
                  margin-top: ${expand ? 0 : 5}px;
                  border: 0;
                  display: flex;
                  align-items: center;
                  svg {
                    transform: rotate(${expand ? 180 : 0}deg);
                  }
                  &:hover {
                    color: ${colors.gold};
                    svg path {
                      fill: ${colors.gold};
                    }
                  }
                  @media only screen and (max-width: 776px) {
                    display: none;
                  }
                `}
              >
                {expand ? `Sbalit` : `Rozbalit`} seznam vnitřků
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  enableBackground='new 0 0 512 512'
                  viewBox='0 0 220.682 220.682'
                  css={css`
                    margin-left: 5px;
                  `}
                >
                  <g xmlns='http://www.w3.org/2000/svg' fill='#e7e2f6'>
                    <path
                      d='M110.341 220.682L210.043 120.98 181.758 92.695 110.341 164.113 38.924 92.695 10.639 120.98z'
                      data-original='#000000'
                    ></path>
                    <path
                      d='M210.043 28.284L181.758 0 110.341 71.418 38.924 0 10.639 28.284 110.341 127.986z'
                      data-original='#000000'
                    ></path>
                  </g>
                </svg>
              </button>
              <button
                onClick={() => setOpen(true)}
                css={css`
                  font-size: 12px;
                  cursor: pointer;
                  background-color: ${colors.grey};
                  color: ${colors.white};
                  padding: 5px 10px;
                  border: 0;
                  margin-top: ${expand ? 0 : 5}px;
                  margin-left: 6px;
                  @media only screen and (max-width: 776px) {
                    display: none;
                  }
                `}
              >
                Otevřít ve vyskakovacím okně
              </button>
            </div>
          </>
        )}
        {((prm.currentPart === 0 && prm.roundedLeft === 0) ||
          (prm.currentPart !== 0 && prm.currentPart !== prm.parts.length - 1) ||
          (prm.currentPart === prm.parts.length - 1 && prm.roundedRight === 0)) && (
          <h2>Přizpůsobte si vnitřní vybavení skříně:</h2>
        )}
        <Model2D
          prm={prm}
          setPrm={setPrm}
          setConfigError={setConfigError}
          setConfigErrorNumber={setConfigErrorNumber}
        />
      </div>
      <GreenButton
        value='Pokračovat na definici dveří'
        link='./doors'
        configError={configError}
        configErrorNumber={configErrorNumber}
        setConfigErrorNumber={setConfigErrorNumber}
        prm={prm}
        setPrm={setPrm}
      />
    </>
  )
}

export default Step3
