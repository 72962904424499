/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"

export default function ErrorNotice(props) {
  const colors = useTheme()
  return (
    <div
      css={css`
        color: #fff;
        background: ${colors.red};
        padding: 5px 10px;
        text-align: center;
        grid-column-start: 1;
        grid-column-end: 3;
        bottom: 0px;
        margin-bottom: ${props.marginBottom ? `${props.marginBottom}px` : `40px`};
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
      `}
    >
      {props.message}
    </div>
  )
}
