/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React from "react"
import HangerHolder from "../../../img/hanger.svg"
import Radio from "@material-ui/core/Radio"

export default function Model2DContentSelect(props) {
  const prm = props.prm
  const setPrm = props.setPrm

  function handleSlotContent(e) {
    const content = Array(prm.config.length)
      .fill(1)
      .map((el, i) => {
        if (i === prm.currentPart) {
          return Array(prm.config[prm.currentPart].length)
            .fill(1)
            .map((el, j) => {
              if (Array.isArray(prm.content[i][j]) && prm.currentSlot[prm.currentPart][2] === j) {
                return Array(2)
                  .fill(1)
                  .map((el, k) => {
                    if (prm.currentSlot[prm.currentPart][0] === k)
                      return Array(prm.content[i][j][k].length)
                        .fill(1)
                        .map((el, l) => {
                          if (prm.currentSlot[prm.currentPart][1] === l)
                            return parseInt(e.target.value)
                          else return prm.content[i][j][k][l]
                        })
                    else return prm.content[i][j][k]
                  })
              } else if (j === prm.currentSlot[prm.currentPart]) {
                return parseInt(e.target.value)
              } else return prm.content[i][j]
            })
        } else {
          return prm.content[i]
        }
      })
    setPrm({ ...prm, content: content })
  }

  const colors = useTheme()

  return (
    <div
      css={css`
        justify-content: flex-start;
        padding-right: 10px;
        label {
          display: flex;
          cursor: pointer;
          padding-left: 10px;
          align-items: center;
          margin-top: 10px;
          input {
            margin-right: 10px;
          }
          img {
            border: 2px solid ${colors.grey};
            margin-bottom: 2px;
            max-width: 60px;
            padding: 0 6px 10px;
          }
        }
      `}
    >
      <label htmlFor='hangerHolderIcon'>
        <Radio
          onChange={handleSlotContent}
          checked={
            Array.isArray(prm.currentSlot[prm.currentPart])
              ? prm.content[prm.currentPart][prm.currentSlot[prm.currentPart][2]][
                  prm.currentSlot[prm.currentPart][0]
                ][prm.currentSlot[prm.currentPart][1]] === 2
              : prm.content[prm.currentPart][prm.currentSlot[prm.currentPart]] === 2
          }
          value={2}
          name='selectedContent'
          id='hangerHolderIcon'
        />
        <img src={HangerHolder} alt='Hanger holder' />
      </label>
      <label htmlFor='drawerIcon'>
        <Radio
          onChange={handleSlotContent}
          checked={
            Array.isArray(prm.currentSlot[prm.currentPart])
              ? prm.content[prm.currentPart][prm.currentSlot[prm.currentPart][2]][
                  prm.currentSlot[prm.currentPart][0]
                ][prm.currentSlot[prm.currentPart][1]] === 1
              : prm.content[prm.currentPart][prm.currentSlot[prm.currentPart]] === 1
          }
          value={1}
          name='selectedContent'
          id='drawerIcon'
        />
        <div
          css={css`
            background-color: ${colors.white};
            border: 2px solid ${colors.grey};
            height: 32px;
            width: 60px;
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={css`
              width: 10px;
              height: 5px;
              margin-top: 5px;
              background: #3c3941;
            `}
          ></div>
        </div>
      </label>
      <label
        htmlFor='shelfIcon'
        css={css`
          font-size: 13px;
          text-align: center;
          text-transform: uppercase;
        `}
      >
        <Radio
          onChange={handleSlotContent}
          checked={
            Array.isArray(prm.currentSlot[prm.currentPart])
              ? prm.content[prm.currentPart][prm.currentSlot[prm.currentPart][2]][
                  prm.currentSlot[prm.currentPart][0]
                ][prm.currentSlot[prm.currentPart][1]] === 0
              : prm.content[prm.currentPart][prm.currentSlot[prm.currentPart]] === 0
          }
          value={0}
          name='selectedContent'
          id='shelfIcon'
        />
        <div
          css={css`
            height: 32px;
            width: 60px;
            border: 2px solid ${colors.grey};
          `}
        ></div>
      </label>
    </div>
  )
}
