/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import Error404 from "../../img/404.svg"

export default function Page404() {
  return (
    <div
      css={css`
                grid-area: g;
                align-items: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                }
              `}
    >
      <img
        src={Error404}
        css={css`
          margin-bottom: 20px;
          width: 200px;
        `}
        alt='Error 404'
      />
      <h2>Error 404 - tato stránka neexistuje</h2>
    </div>
  )
}
