/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState } from "react"
import { useTheme } from "emotion-theming"
import RegisterLoginWrapper from "./registerLoginWrapper"
import Axios from "axios"
import ErrorNotice from "../modules/errorNotice"
import SuccessNotice from "../modules/successNotice"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"

export default function Register() {
  const [email, setEmail] = useState(``)
  const [displayName, setDisplayName] = useState(``)
  const [company, setCompany] = useState(``)
  const [ic, setIc] = useState(``)
  const [password, setPassword] = useState(``)
  const [passwordCheck, setPasswordCheck] = useState(``)
  const [terms, setTerms] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const submit = async (e) => {
    e.preventDefault()
    try {
      const newUser = {
        email,
        password,
        passwordCheck,
        displayName,
        company,
        ic
        //  terms,
      }
      await Axios.post(`/users/register`, newUser)
      setSuccess(
        `Úspěšně jste se zaregistrovali, nyní už jen zkontrolujte Vaši e-mailovou schránku,
        kam Vám byl právě zaslán odkaz pro ověření Vaší e-mailové adresy.`
      )
      setEmail(``)
      setDisplayName(``)
      setPassword(``)
      setPasswordCheck(``)
      setCompany(``)
      setIc(``)
      setTerms(false)
      setError()
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg)
    }
  }

  const colors = useTheme()

  const WhiteCheckbox = withStyles({
    root: {
      color: `#ffffff`,
      padding: 4,
      "&$checked": {
        color: `#ffffff`
      }
    },
    checked: {}
  })((props) => <Checkbox color='default' {...props} />)

  return (
    <RegisterLoginWrapper maxWidth={610}>
      <h2>Registrace</h2>
      {success && <SuccessNotice message={success} />}
      {error && <ErrorNotice message={error} />}
      <form
        onSubmit={submit}
        css={css`
          .star {
            color: #ff6c6c;
          }
        `}
      >
        <label htmlFor='register-company'>
          Název firmy:<span className='star'> *</span>
        </label>
        <input
          id='register-company'
          type='text'
          onChange={(e) => setCompany(e.target.value)}
          value={company}
        />
        <label htmlFor='register-display-name'>
          Uživatelské jméno: <span className='star'>*</span>
        </label>
        <input
          type='text'
          id='register-display-name'
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
        />
        <label htmlFor='register-display-name'>URL adresa aplikace:</label>
        <div
          css={css`
            text-align: left;
            font-size: 11px;
            color: #fff;
            @media only screen and (max-width: 768px) {
              grid-column-start: 1;
              grid-column-end: 3;
              margin-bottom: 10px;
            }
          `}
        >
          konfigurator.lj-kuchyne.cz/#/app/
          <b>{displayName ? displayName : `uzivatelske-jmeno`}</b>.
        </div>
        <label htmlFor='register-email'>
          E-mail:<span className='star'> *</span>
        </label>
        <input
          id='register-email'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <label htmlFor='register-ic'>IČ:</label>
        <input id='register-ic' type='ic' onChange={(e) => setIc(e.target.value)} value={ic} />
        <label htmlFor='register-password'>
          Heslo:<span className='star'> *</span>
        </label>
        <input
          id='register-password'
          type='password'
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <label htmlFor='register-verify-password'>
          Heslo pro kontrolu:<span className='star'> *</span>
        </label>
        <input
          id='register-verify-password'
          type='password'
          onChange={(e) => setPasswordCheck(e.target.value)}
          value={passwordCheck}
        />
        {/*  <div
          css={css`
            align-items: center;
            display: flex;
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
            color: #fff;
            margin-left: -7px;
            grid-column-start: 2;
            grid-column-end: 3;
            @media only screen and (max-width: 768px) {
              grid-column-start: 1;
              margin-bottom: 0;
            }
          `}
        >
          <WhiteCheckbox
            size='small'
            name='terms'
            checked={terms}
            onChange={(e) => setTerms(!terms)}
          />
          <div>
            Souhlasím s{` `}
            <a
              href='/#/obchodni-podminky'
              target='_blank'
              rel='noopener noreferrer'
              css={css`
                position: relative;
                z-index: 2;
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
                color: #fff;
              `}
            >
              obchodními podmínkami
            </a>
          </div>
        </div>*/}
        <div
          css={css`
            font-size: 11px;
            font-weight: 600;
            background: ${colors.gold};
            color: ${colors.grey};
            grid-column-start: 1;
            grid-column-end: 3;
            padding: 5px 10px;
            a {
              color: #fff;
              &:visited {
                color: #fff;
              }
            }
            @media only screen and (max-width: 768px) {
              grid-column-start: 1;
              margin-bottom: 0;
            }
          `}
        >
          Po registraci budou zpracovávány Vámi uvedené osobní údaje. Více informací o tom, jak je
          nakládáno s Vašimi osobními údaji, naleznete
          {` `}
          <a href='/#/ochrana-osobnich-udaju' target='_blank' rel='noopener noreferrer'>
            zde
          </a>
          .
        </div>
        <input type='submit' value='Zaregistrovat se' />
      </form>
    </RegisterLoginWrapper>
  )
}
