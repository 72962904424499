/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"

export default function PrivacyPolicy() {
  const colors = useTheme()
  return (
    <div
      css={css`
        grid-area: g;
        display: flex;
        flex-direction: column;
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 20px;
        padding-bottom: 80px;
        line-height: 28px;
        text-align: justify;
        h2 {
          text-align: center;
        }
        h3 {
          margin-top: 26px;
        }
        a {
          color: ${colors.gold}};
        }
      `}
    >
      <div>
        <h2>Zásady ochrany osobních údajů</h2>
        <p
          css={css`
            color: ${colors.red};
          `}
        >
          Zde budou po spuštění ostrého provozu aplikace uveřejněny zásady ochrany osobních údajů
          prodejce. Nyní se aplikace nachází v režimu <b>BETA testování</b>!
        </p>
      </div>
    </div>
  )
}
