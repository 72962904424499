/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState, useContext, useEffect } from "react"
import { useTheme } from "emotion-theming"
import { SketchPicker } from "react-color"
import Axios from "axios"
import UserContext from "../../context/UserContext"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import AddNew from "../../img/addNew.svg"
import Loading from "../../img/loading.svg"
import DoorsIconActive from "../../img/doors-active.svg"

export default function DoorsFrames() {
  const [picker, setPicker] = useState(false)
  const [onlyColor, setOnlyColor] = useState(false)
  const [color, setColor] = useState(`#ffffff`)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState({})
  const [textures, setTextures] = useState([])

  const { userData } = useContext(UserContext)

  const handleColorChange = (color) => {
    setColor(color.hex)
  }

  function handleOnlyColorChange(e) {
    setOnlyColor(!onlyColor)
  }

  const deleteTexture = async (url) => {
    try {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      const tokenRes = await Axios.post(`/users/tokenIsValid`, null, {
        headers: { "x-auth-token": token }
      })
      if (tokenRes.data) {
        await Axios.delete(`/textures/delete/${url}`, {
          headers: { "x-auth-token": token }
        })
        Axios.get(`/textures/all/${userData.user.displayName}/2`)
          .then((res) => {
            setTextures(res.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } catch (err) {
      console.log(err.response.data.msg)
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    try {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      let color2
      const name = e.target[`name`].value
      const owner = userData.user.displayName
      if (!onlyColor) color2 = null
      else color2 = color
      const newTexture = new FormData()
      newTexture.append(`name`, name)
      newTexture.append(`color`, color2)
      newTexture.append(`owner`, owner)
      newTexture.append(`onlyColor`, onlyColor)
      newTexture.append(`image`, image)
      newTexture.append(`category`, 2)
      setError()
      setLoading(true)
      await Axios.post(`/textures/newTexture`, newTexture, {
        headers: { "x-auth-token": token }
      })
      setLoading(false)
      setError()
      setImage()
      setColor(`#ffffff`)
      document.getElementById(`name`).value = ``
      document.getElementById(`url`).value = ``
      setSuccess(`Rám byl úspěšně přidán.`)
      setTimeout(function() {
        setSuccess()
      }, 5000)
      Axios.get(`/textures/all/${userData.user.displayName}/2`)
        .then((res) => {
          setTextures(res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (err) {
      if (err.response.data.msg) {
        setError(err.response.data.msg)
        setLoading(false)
      }
    }
  }

  const WhiteCheckbox = withStyles({
    root: {
      color: `#ffffff`,
      padding: 4,
      "&$checked": {
        color: `#ffffff`
      }
    },
    checked: {}
  })((props) => <Checkbox color='default' {...props} />)

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  useEffect(() => {
    Axios.get(`/textures/all/${userData.user.displayName}/2`)
      .then((res) => {
        setTextures(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [userData.user.displayName])

  const colors = useTheme()
  return (
    <>
      <h2
        css={css`
          display: flex;
          &:before {
            content: "";
            background-image: url(${DoorsIconActive});
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            display: inline-flex;
          }
        `}
      >
        Správa rámů dveří
      </h2>
      {textures.length === 0 && (
        <div
          css={css`
            background: #ccc;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 20px;
          `}
        >
          Zatím nemáte nadefinované žádné rámy dveří.
        </div>
      )}
      {textures.length > 0 && (
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, 90px);
            grid-gap: 10px;
            justify-content: space-between;
            margin-bottom: 20px;
            background: #ccc;
            padding: 10px;
          `}
        >
          {textures.map((texture) => (
            <div
              key={texture._id}
              css={css`
                background: ${colors.grey};
                color: ${colors.white};
                text-align: center;
                border: 2px solid ${colors.grey};
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              `}
            >
              <div>
                <div
                  css={css`
                    cursor: pointer;
                    background: ${colors.red};
                  `}
                  onClick={() => deleteTexture(texture.url)}
                >
                  Odstranit
                </div>
                <img
                  src={
                    `https://` +
                    bucketURL +
                    `.s3.eu-central-1.amazonaws.com/small-` +
                    texture.url.substring(4)
                  }
                  alt={texture.name}
                  css={css`
                    max-width: 86px;
                  `}
                />
              </div>
              <div
                css={css`
                  padding: 4px 0 7px 0;
                  font-size: 11px;
                  font-weight: 600;
                  text-transform: uppercase;
                  ${texture.color && `border-bottom: 5px solid ${texture.color}`}
                `}
              >
                {texture.name}
              </div>
            </div>
          ))}
          {!error && loading && (
            <img
              src={Loading}
              alt='Loading...'
              height='128'
              css={css`
                position: relative;
                left: -20px;
              `}
            />
          )}
        </div>
      )}
      <form
        css={css`
          display: grid;
          max-width: 600px;
          width: 100%;
          grid-template-columns: auto auto;
          grid-row-gap: 10px;
          align-items: center;
          padding: 10px;
          background: #ccc;
          label {
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
          }
          input[type="file"] {
            padding: 0;
          }
          @media only screen and (max-width: 768px) {
            max-width: 100%;
            label,
            input {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }
        `}
        onSubmit={submit}
        method='post'
        encType='multipart/form-data'
      >
        <div
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            border-bottom: 2px solid #000;
            align-items: center;
            padding-bottom: 5px;
            img {
              margin-right: 5px;
            }
          `}
        >
          <img src={AddNew} width='25' alt='Add new texture' />
          Přidání nového rámu dveří:
        </div>
        {(success || loading || error) && (
          <div
            css={css`
              background: ${loading ? `#f0ad4e` : `#60a26b`};
              ${error && `background: ${colors.red};`}
              grid-column-start: 1;
              grid-column-end: 3;
              color: #fff;
              padding: 10px;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              position: relative;
            `}
          >
            {error}
            {success}
            {loading && (
              <>
                Rám dveří se nahrává...
                <img
                  src={Loading}
                  alt='Loading...'
                  height='70'
                  css={css`
                    position: absolute;
                    right: -17px;
                    top: -17px;
                  `}
                />
              </>
            )}
          </div>
        )}
        <label>Název rámu dveří:</label>
        <input name='name' id='name' type='text' />
        <label>Textura, příp. náhledový obrázek:</label>
        <input name='url' id='url' type='file' onChange={(e) => setImage(e.target.files[0])} />
        <label
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            display: flex;
            align-items: center;
            span {
              padding-left: 0;
              padding-right: 5px;
            }
          `}
        >
          <WhiteCheckbox
            checked={onlyColor}
            onChange={(e) => handleOnlyColorChange(e)}
            name='onlyColor'
            size='small'
          />
          Náhledový obrázek nelze zároveň použít jako texturu, proto chci nadefinovat pouze barvu
          vystihující nahraný náhledový obrázek
        </label>
        {onlyColor && (
          <>
            <label>Barva rámu dveří:</label>
            <div
              css={css`
                position: relative;
              `}
            >
              {picker === true && (
                <div
                  css={css`
                    position: absolute;
                    z-index: 2;
                    top: 40px;
                  `}
                >
                  <div
                    css={css`
                      position: fixed;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                    `}
                    onClick={() => setPicker(false)}
                  ></div>
                  <SketchPicker color={color} onChange={handleColorChange} />
                </div>
              )}
              <input type='text' name='color' value={color} onClick={() => setPicker(true)} />
            </div>
          </>
        )}
        <input
          type='submit'
          value='Přidat dekor'
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            cursor: pointer;
            &:hover {
              color: white;
              background: ${colors.green};
            }
          `}
        />
      </form>
    </>
  )
}
