/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"

export default function PrivacyPolicy() {
  const colors = useTheme()
  return (
    <div
      css={css`
        grid-area: g;
        display: flex;
        flex-direction: column;
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 20px;
        padding-bottom: 80px;
        line-height: 28px;
        text-align: justify;
        h2 {
          text-align: center;
        }
        h3 {
          margin-top: 26px;
        }
        a {
          color: ${colors.gold}};
        }
      `}
    >
      <div>
        <h2>Zásady ochrany osobních údajů</h2>
        Znění platné od 1. 1. 2021
        <h3
          css={css`
            margin-top: 20px !important;
          `}
        >
          1. Zpracovatel osobních údajů
        </h3>
        <p
          css={css`
            margin-top: 0;
          `}
        >
          Zpracovatelem osobních údajů o Vaší osobě, dále jen <b>„uživatel“</b>, využívaných touto
          aplikací „3D konfigurátor vestavěných skříní“ umístěnou na adrese{` `}
          <a href='http://www.3d-konfigurator-vestavenych-skrini.cz'>
            www.3d-konfigurator-vestavenych-skrini.cz
          </a>
          {` `}
          dále jen
          {` `}
          <b> „aplikace“</b>
          {` `}
          je:{` `}
          <b>David Holada, IČ: 02294648, Hulice 101, 257 63 Trhový Štěpánov</b>, dále jen{` `}
          <b> „zpracovatel“</b>. Pro případnou komunikaci se zpracovatelem se prosím obracejte na
          tuto e-mailovou adresu:{` `}
          <a href='mailto:info@3d-konfigurator-vestavenych-skrini.cz'>
            info@3d-konfigurator-vestavenych-skrini.cz
          </a>
          .
        </p>
        <h3>2. Definice osobních údajů</h3>
        Osobními údaji, které zpracovatel prostřednictvím aplikace zpracovává o uživateli se
        rozumějí tyto:
        <ul
          css={css`
            margin-bottom: 0;
          `}
        >
          <li>Uživatelské jméno</li>
          <li>E-mailová adresa</li>
        </ul>
        <h4>2.1 Osobní údaje ukládané do prohlížeče</h4>
        Zpracovatel o uživateli neukládá žádné osobní údaje do webového prohlížeče. Do prohlížeče
        aplikace o uživateli ukládá pouze tzv. technické informace nutné k zajištění základních
        funkcí aplikace. Více k tomuto tématu lze nalézt na samostatné stránce{` `}
        <a href='/#/cookies'>zde</a>.<h3>3. Zpracovávání osobních údajů</h3>
        <h4
          css={css`
            margin-top: 0;
          `}
        >
          3.1 Registrace
        </h4>
        Při registraci jsou uživatelem poskytnuté osobní údaje uloženy do databáze aplikace.
        E-mailová adresa je následně použita, aby aplikace mohla zaslat ověřovací, případně
        potvrzovací e-mailovou zprávu. Uživatelské jméno aplikace využívá pro vytvoření unikátní URL
        adresy, na které se nachází uživatelův vlastní 3D konfigurátor.
        <h4>3.2 3D konfigurátor</h4>
        Uvnitř samotné aplikace 3D konfigurátoru figuruje pouze neveřejně využívána uživatelova
        e-mailová adresa z toho důvodu, aby aplikace mohla určit příjemce případných objednávek od
        uživatelových zákazníků.
        <h4>3.3 Administrace</h4>V administraci aplikace jsou ukládány uživatelovi osobní údaje
        údaje zejména pro identifikaci jednotlivých objednávek v sekci „Objednávky“. V sekci „Můj
        účet“ jsou pak zobrazeny všechny uživatelovi osobní údaje zejména pro účely jejich kontroly,
        případně změny. Pro zpracování osobních údajů zákazníků vytvářejících objednávky přes
        aplikaci platí odlišné podmínky zpracování osobních údajů. Zákazníci mají možnost se s
        těmito podmínkami obeznámit v posledním kroku aplikace před odesláním objednávky.
        <h4>3.4 Komunikace zpracovatele vůči uživateli</h4>
        Pro účely komunikace se zákazníkem využívá zpracovatel všech identifikační údajů uživatele,
        které uživatel poskytnul při registraci.
        <h4>3.5 Odesílání hromadných e-mailových sdělení</h4>
        Zpracovatel považuje za svůj tzv. oprávněný zájem rozeslat všem uživatelům aplikace sdělení
        v případě např. představení nových funkcionalit aplikace, změn obchodních podmínek či změn
        zásad ochrany osobních údajů.
        {/*<h4>3.5 Fakturace</h4>*/}
        <h3>4. Předávání osobních údajů do zemí mimo EU</h3>
        Uživatelovi osobní údaje jsou předávány poskytovateli zabezpečeného databázového uložiště
        MongoDB se sídlem v USA, bez něhož by aplikace nemohla fungovat a které vyhovuje požadavkům
        GDPR, viz
        {` `}
        <a href='https://www.mongodb.com/cloud/trust/compliance/gdpr'>
          https://www.mongodb.com/cloud/trust/compliance/gdpr
        </a>
        . K rozesílání automatických e-mailů pak aplikace využívá službu e-mailové servery služby
        Amazon AWS se sídlem taktéž v USA. I tato služba vyhovuje požadavkům GDPR, viz
        {` `}
        <a href='https://aws.amazon.com/compliance/gdpr-center/'>
          https://aws.amazon.com/compliance/gdpr-center/
        </a>
        .<h3>5. Předávání osobních údajů třetím stranám</h3>
        Kromě předávání dle bodu 4. těchto zásad nedochází k žádnému jinému předávání osobních údajů
        uživatele třetím stranám.
        <h3>6. Změny zásad ochrany osobních údajů</h3>
        Tyto zásady ochrany osobních údajů mohou být v průběhu času doplněny např. v důsledku
        přidávání nových funkcionalit. Tyto zásady se také budou s velkou pravděpodobností měnit při
        přechodu z beta verze aplikace do ostrého provozu. O všech takových změnách bude uživatel
        vždy včas informován e-mailem. Pokud s novými zásadami nebude uživatel souhlasit, má nárok
        bez jakýchkoliv sankcí ukončit využívání služeb aplikace.
        <h3>7. Změny zásad ochrany osobních údajů</h3>
        Tyto zásady ochrany osobních údajů mohou být v průběhu času doplněny např. v důsledku
        přidávání nových funkcionalit. Tyto zásady se také budou s velkou pravděpodobností měnit při
        přechodu z beta verze aplikace do ostrého provozu. O všech takových změnách bude uživatel
        vždy včas informován e-mailem. Pokud s novými zásadami nebude uživatel souhlasit, má nárok
        bez jakýchkoliv sankcí ukončit využívání služeb aplikace.
        <h3>8. Doba uložení osobních údajů</h3>
        Osobní údaje zákazníka uchovává zpracovatel po dobu trvání smlouvy, resp. po dobu trvání
        uživatelského účtu. Po zrušení účtu však ještě údaje zůstávají dostupné pro zpracovatele 8
        dní v rámci automatických záloh.
        <h3>8. Vaše práva při zpracování osobních údajů</h3>
        <h4>8.1 Právo na přístup</h4>
        Uživatel má právo na přístup k osobním údajům, které o něm zpracovatel zpracovává, příp.
        jakým způsobem je zpracovává. Tyto informace pokrývají zejména body 2., 3., 4., a 5. těchto
        zásad.
        <h4>8.2 Právo na opravu</h4>
        Pokud se uživatel domnívá, že zpracovatel zpracovává jeho nepřesné údaje, má právo jej na to
        upozornit a požadovat od zpracovatele opravu těchto nepřesných údajů.
        <h4>8.3 Právo na výmaz</h4>
        Uživatel má právo požadovat výmaz, případně omezení zpracování jeho osobních údajů.
        <h4>8.4 Právo na přenositelnost</h4>
        Uživatel má právo na přenositelnost osobních údajů, které o něm zpracovatel ukládá. Na
        žádost uživatele, zpracovatel předá uživateli tyto jeho osobní údaje ve strojově čitelném
        formátu, případně předá tyto údaje přímo jinému správci.
        <h4>8.5 Právo vznést námitku</h4>
        Uživatel má právo vznést námitku proti zpracování svých osobních údajů, např. co se týče
        zpracovatelova oprávněného zájmu při zpracování uživatelových osobních údajů.
        <h4>8.6 Právo podat stížnost</h4>
        Uživatel má právo podat stížnost u dozorového úřadu: Úřad pro ochranu osobních údajů se
        sídlem Pplk. Sochora 27, 170 00 Praha 7.
        <br />
        <br />
        Na všechny případné, v tomto bodě uvedené, uživatelovi požadavky bude zpracovatel reagovat
        bez zbytečného odkladu, nejvýše však ve lhůtě 30 dnů.
      </div>
    </div>
  )
}
