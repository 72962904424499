/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"

export default function Terms() {
  const colors = useTheme()
  return (
    <div
      css={css`grid-area:g;
        display:flex;
        flex-direction:column;
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 20px;
        h2{
          text-align: center;
        }
      }`}
    >
      <h2>Obchodní podmínky</h2>
      <p
        css={css`
          color: ${colors.red};
        `}
      >
        Zde budou po spuštění ostrého provozu aplikace uveřejněny obchodní podmínky prodejce. Nyní
        se aplikace nachází pouze v <b>BETA testování</b> a odesláním objednávky nedochází k
        uzavření kupní smlouvy!
      </p>
    </div>
  )
}
