/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"

export default function SuccessNotice(props) {
  return (
    <div
      css={css`
        color: #fff;
        background: #60a26b;
        padding: 5px 20px;
        text-align: center;
        font-size: 14px;
        grid-column-start: 1;
        grid-column-end: 3;
        bottom: 0px;
        margin-bottom: ${props.marginBottom ? `${props.marginBottom}px` : `40px`};
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
      `}
    >
      {props.message}
    </div>
  )
}
