/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"

export default function unsupportedBrowser() {
  return (
    <div
      css={css`
        grid-area: g;
        margin: 0 auto;
        width: 100%;
        color: rgb(255, 108, 108);
        position: absolute;
        top: 60px;
        padding: 20px;
      `}
    >
      <p
        css={css`
          max-width: 1400px;
          margin: auto;
          padding: 0 20px;
        `}
      >
        Tato aplikace bohužel <b>nepodporuje</b> Vámi používaný prohlížeč <b>Internet Explorer</b>.
        Pro správnou funkcionalitu aplikace prosím využijte některý z těchto podporovaných
        prohlížečů:
        <b> Chrome, Firefox, Edge</b>, atp.
      </p>
    </div>
  )
}
