/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Axios from "axios"
import Designer from "../designer/right/designer"
import Loading from "../../img/loading.svg"
import SummaryIconActive from "../../img/summary-active.svg"
import Print from "../../img/print.svg"
import CanvasControlTop from "../designer/right/canvasControlTop"
import Table from "./components/table"
import Model2DConfig from "../designer/left/model2DConfig"
import DoorsConfig from "./doorsConfig"

export default function OrderDetail() {
  const { id } = useParams()

  const [order, setOrder] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState()

  const [bgColor, setBgColor] = useState()
  const [textureURL, setTextureURL] = useState()
  const [dekorURL, setDekorURL] = useState()
  const [dekorColor, setDekorColor] = useState()
  const [textureFrameURL, setTextureFrameURL] = useState()
  const [textureName, setTextureName] = useState()
  const [doorsFrameName, setDoorsFrameName] = useState()
  const [doorsFrameURL, setDoorsFrameURL] = useState()
  const [doorsFrameColor, setDoorsFrameColor] = useState()
  const [doorsTextures, setDoorsTextures] = useState()

  const colors = useTheme()

  const orderDesigner = {
    ...order,
    currentSlot: [0, 0, 0, 0, 0, 0, 0],
    currentColor: `#ffa000`
  }

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  useEffect(() => {
    const loadOrder = async () => {
      try {
        const userRes = await Axios.get(`/orders/all/${id}`)
        setOrder(userRes.data)
        setBgColor(userRes.data.bgColor)
        setTextureURL(userRes.data.textureURL)
        setTextureFrameURL(userRes.data.textureFrameURL)
        setDoorsTextures(userRes.data.doorsTextures)
        if (userRes.data.textureId !== null) {
          if (userRes.data.textureId === `platinoveBila`) {
            setTextureName(`W980 ST2 Platinově bílá`)
            setDekorURL(
              `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/big-platinoveBila.jpeg`
            )
            setDekorColor(`null`)
          } else {
            const texture = await Axios.get(`/textures/single/${userRes.data.textureId}`)
            setTextureName(texture.data[0].name)
            setDekorURL(
              `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/` + texture.data[0].url
            )
            setDekorColor(texture.data[0].color)
          }
        }
        if (userRes.data.doorsFrameId === `factorPrirodni`) {
          setDoorsFrameName(`Faktor přírodní`)
          setDoorsFrameColor(`#EAEAEA`)
          setDoorsFrameURL(
            `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/small-factorPrirodni.png`
          )
        } else if (userRes.data.doorsFrameId !== null) {
          const doorsFrame = await Axios.get(`/textures/single/${userRes.data.doorsFrameId}`)
          setDoorsFrameName(doorsFrame.data[0].name)
          setDoorsFrameURL(
            `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/` + doorsFrame.data[0].url
          )
          setDoorsFrameColor(doorsFrame.data[0].color)
        }
        setLoading(false)
      } catch (err) {
        err && setError(err)
      }
    }
    loadOrder()
  }, [bucketURL, id])

  let numberOfParts
  if (!isLoading) {
    numberOfParts = order.parts.reduce((a, b) => a + b, 0)
  }

  return (
    <>
      <h2
        css={css`
          display: flex;
          color: ${colors.white};
          text-transform: uppercase;
          margin-bottom: 20px;
          &:before {
            content: "";
            background-image: url(${SummaryIconActive});
            background-size: 30px 30px;
            height: 30px;
            width: 30px;
            margin-right: 12px;
            display: inline-flex;
            @media print {
              display: none;
            }
          }
          @media print {
            border-bottom: 2px solid ${colors.gold};
            padding-bottom: 10px;
          }
        `}
      >
        Detail objednávky
      </h2>
      {error && <div>{error}</div>}
      {!isLoading ? (
        <>
          <div
            css={css`
              display: flex;
              @media only screen and (max-width: 768px) {
                flex-direction: column;
              }
            `}
          >
            <div
              css={css`
                background: #ccc;
                padding: 10px;
                width: 50%;
                @media only screen and (max-width: 768px) {
                  width: 100%;
                  padding: 5px;
                }
                @media print {
                  padding: 0;
                }
              `}
            >
              <Table>
                <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  <div className='label'>ID objednávky:</div>
                  <div className='value'>{order._id}</div>
                </div>
              </Table>
              <Table>
                <div>
                  <div className='label'>Objednatel:</div>
                  <div className='value'>
                    {order.buyOnICO ? order.customerCompanyName : `${order.name} ${order.surName}`}
                  </div>
                </div>
                {order.buyOnICO && (
                  <>
                    <div>
                      <div className='label'>IČO:</div>
                      <div className='value'>{order.ico}</div>
                    </div>
                    <div>
                      <div className='label'>DIČ:</div>
                      <div className='value'>{order.dic}</div>
                    </div>
                  </>
                )}
                <div>
                  <div className='label'>E-mail:</div>
                  <div className='value'>{order.email}</div>
                </div>
                <div>
                  <div className='label'>Telefon:</div>
                  <div className='value'>{order.phone}</div>
                </div>
                {order.note && (
                  <div
                    css={css`
                      grid-template-columns: auto !important;
                    `}
                  >
                    <div className='label'>Poznámka:</div>
                    <div className='value'>{order.note}</div>
                  </div>
                )}
              </Table>
              <Table>
                <div
                  css={css`
                    font-weight: 600;
                    margin-top: 20px;
                    font-size: 18px;
                  `}
                >
                  {order.secondAddress ? `Fakturační adresa:` : `Adresa:`}
                </div>
                <div>
                  <div className='label'>Ulice a č. p.:</div>
                  <div className='value'>
                    {order.street}
                    {` `}
                    {order.cp}
                  </div>
                </div>
                <div>
                  <div className='label'>Město:</div>
                  <div className='value'>{order.city}</div>
                </div>
                <div>
                  <div className='label'>PSČ:</div>
                  <div className='value'>{order.zip}</div>
                </div>
              </Table>
              <Table>
                {order.secondAddress && (
                  <>
                    <div
                      css={css`
                        font-weight: 600;
                        margin-top: 20px;
                        font-size: 18px;
                      `}
                    >
                      Dodací adresa:
                    </div>
                    <div>
                      <div className='label'>Ulice a č. p.:</div>
                      <div className='value'>
                        {order.street2}
                        {` `}
                        {order.cp2}
                      </div>
                    </div>
                    <div>
                      <div className='label'>Město:</div>
                      <div className='value'>{order.city2}</div>
                    </div>
                    <div>
                      <div className='label'>PSČ:</div>
                      <div className='value'>{order.zip2}</div>
                    </div>
                  </>
                )}
                <div
                  css={css`
                    font-weight: 600;
                    margin-top: 20px;
                    font-size: 18px;
                  `}
                >
                  <div
                    css={css`
                      grid-column-start: 1;
                      grid-column-end: 3;
                    `}
                  >
                    Zaměření, montáž, doprava:
                  </div>
                </div>
                <div>
                  <div className='label'>Zaměření na místě:</div>
                  <div className='value'>{order.measurement ? `Ano` : `Ne`}</div>
                </div>
                <div>
                  <div className='label'>Sestavení na místě:</div>
                  <div className='value'>{order.construction ? `Ano` : `Ne`}</div>
                </div>
                <div>
                  <div className='label'>Doprava:</div>
                  <div className='value'>
                    {order.transport === 1 ? `Doručení na místo` : `Osobní odběr`}
                  </div>
                </div>
              </Table>
            </div>
            <div
              css={css`
                background: #ccc;
                padding: 10px;
                width: 50%;
                @media only screen and (max-width: 768px) {
                  width: 100%;
                  padding: 5px;
                }
                @media print {
                  padding: 0;
                  padding-left: 10px;
                }
              `}
            >
              <div
                css={css`
                  font-weight: 600;
                  font-size: 18px;
                  text-transform: uppercase;
                  padding: 10px;
                  display: inline-flex;
                  background: #f2f2f2;
                  justify-content: space-between;
                  width: 100%;
                  position: relative;
                  @media print {
                    border: 2px solid ${colors.grey};
                    border-bottom: 0;
                    width: 100%;
                  }
                `}
              >
                Specifikace skříně:
                <button
                  css={css`
                    font-size: 13px;
                    text-transform: uppercase;
                    padding: 5px 10px;
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    border-radius: 4px;
                    border: 1px solid ${colors.grey};
                    &:hover {
                      background: #7ed321;
                    }
                    @media print {
                      display: none;
                    }
                  `}
                  onClick={() => {
                    document.title = `objednavka-cislo-${order._id}`
                    window.print()
                    document.title = `3D konfigurátor vestavěných skříní`
                  }}
                >
                  <img
                    src={Print}
                    alt='Vytisknout'
                    width='16'
                    css={css`
                      margin-right: 4px;
                      position: relative;
                    `}
                  />
                  {` `}
                  Vytisknout
                </button>
              </div>
              <Table grid='6040'>
                <div>
                  <div className='label'>Šířka:</div>
                  <div className='value'>{Math.round(order.width * numberOfParts * 100)} cm</div>
                </div>
                <div>
                  <div className='label'>Výška:</div>
                  <div className='value'>{Math.round(order.height * 100)} cm</div>
                </div>
                {order.showTopSpace === 1 && (
                  <div>
                    <div className='label'>Výška horního úložného prostoru:</div>
                    <div className='value'>{Math.round(order.topSpaceHeight * 100)} cm</div>
                  </div>
                )}
                <div>
                  <div className='label'>Hloubka:</div>
                  <div className='value'>{Math.round(order.depth * 100)} cm</div>
                </div>
                <div>
                  <div className='label'>Sokl:</div>
                  <div className='value'>
                    {order.bottomSokl === 1
                      ? `${(order.soklHeight * 100).toFixed(0)}  cm`
                      : `bez soklu`}
                  </div>
                </div>
                {textureName ? (
                  <div>
                    <div className='label'>Textura skříně:</div>
                    <div
                      className='value'
                      css={css`
                        display: flex;
                        flex-direction: column;
                        max-width: 100px;
                        text-align: center;
                        background: ${colors.grey};
                        border: 2px solid ${colors.grey};
                      `}
                    >
                      <img src={dekorURL + `?cacheblock=true`} alt='Dekor' />
                      <span
                        css={css`
                          padding: 5px 0;
                          font-size: 11px;
                          font-weight: 600;
                          text-transform: uppercase;
                          color: ${colors.white};
                          ${dekorColor !== `null` && `border-bottom: 5px solid ${dekorColor}`};
                          border-top: 2px solid ${colors.grey};
                        `}
                      >
                        {textureName}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className='label'>Barva skříně:</div>
                    <div
                      className='value'
                      css={css`
                        display: flex;
                      `}
                    >
                      {order.color}
                      <div
                        css={css`
                          margin-left: 5px;
                          background: ${order.color};
                          width: 15px;
                          height: 15px;
                          border: 2px solid ${colors.grey};
                        `}
                      ></div>
                    </div>
                  </div>
                )}
                <div>
                  <div className='label'>Bílé vnitřní stěny:</div>
                  <div className='value'>{order.whiteInside === 1 ? `Ano` : `Ne`}</div>
                </div>
                {order.roundedType !== 0 && order.roundedLeft + order.roundedRight > 0 && (
                  <div>
                    <div className='label'>Otevřené boky:</div>
                    <div className='value'>{order.roundedType === 1 ? `Kulaté` : `Zkosené`}</div>
                  </div>
                )}
                <div>
                  <div className='label'>Levá stěna:</div>
                  <div className='value'>{order.leftDesk === 1 ? `Ano` : `Ne`}</div>
                </div>
                <div>
                  <div className='label'>Pravá stěna:</div>
                  <div className='value'>{order.rightDesk === 1 ? `Ano` : `Ne`}</div>
                </div>
                <div>
                  <div className='label'>Zadní stěna:</div>
                  <div className='value'>{order.backDesk === 1 ? `Ano` : `Ne`}</div>
                </div>
                <div>
                  <div className='label'>Vrchní stěna:</div>
                  <div className='value'>{order.topDesk === 1 ? `Ano` : `Ne`}</div>
                </div>
                <div>
                  <div className='label'>Spodní stěna:</div>
                  <div className='value'>{order.bottomDesk === 1 ? `Ano` : `Ne`}</div>
                </div>
                <div>
                  <div className='label'>Dveře:</div>
                  <div className='value'>{order.doors === 1 ? `Ano` : `Ne`}</div>
                </div>
              </Table>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              background: #ccc;
              flex-direction: column;
              padding: 10px;
              padding-bottom: 0;
              @media only screen and (max-width: 768px) {
                padding: 5px;
              }
              @media print {
                padding: 0;
              }
            `}
          >
            {order.doors !== 0 && (
              <>
                <div>
                  <div
                    css={css`
                      font-weight: 600;
                      font-size: 18px;
                      text-transform: uppercase;
                      padding: 10px;
                      display: inline-flex;
                      background: #f2f2f2;
                      margin-top: 20px;
                      @media print {
                        padding: 0;
                        padding-bottom: 10px;
                      }
                    `}
                  >
                    Dveře:
                  </div>
                </div>
                <Table type={`doors`}>
                  <div>
                    <div className='label'>Typ dveří:</div>
                    <div className='value'>{order.doorsType === 0 ? `Klasické` : `Posuvné`}</div>
                  </div>
                  <div>
                    <div className='label'>
                      {order.isFrame === 1 ? `Rám dveří:` : `Dveře bez rámu`}
                    </div>
                    {order.isFrame === 1 &&
                      (doorsFrameName ? (
                        <div
                          className='value'
                          css={css`
                            display: flex;
                            max-width: 90px;
                            flex-direction: column;
                            text-align: center;
                            background: ${colors.grey};
                          `}
                        >
                          <img
                            src={doorsFrameURL + `?cacheblock=true`}
                            alt='Dekor'
                            css={css`
                              border: 2px solid ${colors.grey};
                              border-bottom: 0;
                              @media print {
                                border-bottom: 5px solid ${doorsFrameColor};
                              }
                            `}
                          />
                          <span
                            css={css`
                              padding: 5px 0;
                              font-size: 11px;
                              font-weight: 600;
                              text-transform: uppercase;
                              color: ${colors.white};
                              ${doorsFrameColor !== `null` &&
                                `border-bottom: 5px solid ${doorsFrameColor}`};
                              @media print {
                                border: 2px solid ${colors.grey};
                              }
                            `}
                          >
                            {doorsFrameName}
                          </span>
                        </div>
                      ) : (
                        <div
                          className='value'
                          css={css`
                            display: flex;
                          `}
                        >
                          {order.doorsFrameColor}
                          <div
                            css={css`
                              margin-left: 5px;
                              background: ${order.doorsFrameColor};
                              width: 15px;
                              height: 15px;
                              border: 2px solid ${colors.grey};
                            `}
                          ></div>
                        </div>
                      ))}
                  </div>
                </Table>
              </>
            )}
          </div>
          {order.doors !== 0 && (
            <div
              css={css`
                display: flex;
                background: #ccc;
                flex-direction: column;
                padding: 0 10px;
                @media only screen and (max-width: 768px) {
                  padding: 5px;
                }
                @media print {
                  padding: 0;
                  display: block;
                  page-break-inside: avoid;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  background: #fff;
                  padding: 10px;
                  @media print {
                    flex-direction: column;
                  }
                `}
              >
                <div
                  css={css`
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 600;
                    min-width: 170px;
                    margin-bottom: 10px;
                  `}
                >
                  Konfigurace dveří:
                </div>
                {!isLoading && (
                  <div
                    css={css`
                      display: flex;
                      overflow-y: auto;
                      padding-bottom: 10px;
                      width: 100%;
                      max-width: calc(100vw - 60px);
                      @media print {
                        overflow-y: visible;
                      }
                    `}
                  >
                    <DoorsConfig order={order} showParameters={1} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            css={css`
              display: flex;
              background: #ccc;
              flex-direction: column;
              padding: 0 10px;
              padding-top: 40px;
              @media only screen and (max-width: 768px) {
                padding: 5px;
              }
              @media print {
                padding: 0;
              }
            `}
          >
            <div>
              <div
                css={css`
                  font-weight: 600;
                  font-size: 18px;
                  text-transform: uppercase;
                  padding: 10px;
                  display: inline-flex;
                  background: #f2f2f2;
                `}
              >
                Vnitřní uspořádání skříně:
              </div>
            </div>
            <div
              css={css`
                background: #fff;
                padding: 20px 10px;
              `}
            >
              {!isLoading && (
                <Model2DConfig
                  defaultPrm={order}
                  prm={order}
                  setPrm={() => {}}
                  loop={order.config.length}
                  adminOrderDetail={1}
                  setConfigError={() => {}}
                  setConfigErrorNumber={() => {}}
                />
              )}
            </div>
          </div>
          <div
            css={css`
              position: relative;
              @media print {
                display: none;
              }
            `}
          >
            <CanvasControlTop
              prm={order}
              setPrm={setOrder}
              fullscreen={false}
              setFullscreen={() => {}}
              orderDetail={1}
            />
          </div>
          <div
            css={css`
              padding: 10px;
              background: #ccc;
              @media only screen and (max-width: 768px) {
                padding: 5px;
                padding-bottom: 10px;
              }
              @media print {
                display: none;
              }
            `}
          >
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <div
                css={css`
                  font-weight: 600;
                  font-size: 18px;
                  text-transform: uppercase;
                  padding: 10px;
                  display: inline-flex;
                  background: #f2f2f2;
                  @media only screen and (max-width: 768px) {
                    padding: 5px;
                  }
                `}
              >
                3D náhled skříně:
              </div>
            </div>
            <Designer
              prm={orderDesigner}
              bgColor={bgColor}
              textureURL={textureURL}
              textureFrameURL={textureFrameURL}
              doorsTextures={doorsTextures}
              setPrm={setOrder}
              admin={1}
              orderDetail={1}
            />
          </div>
        </>
      ) : (
        !error && (
          <div
            css={css`
              position: relative;
              height: 400px;
            `}
          >
            <img
              src={Loading}
              alt='Loading...'
              css={css`
                position: absolute;
                top: calc(50%);
                left: calc(50% - 141px);
              `}
            />
          </div>
        )
      )}
    </>
  )
}
