/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React, { useState, useCallback } from "react"
import { extend } from "react-three-fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Scene from "./scene"
import { useLocation, Switch, Route } from "react-router-dom"
import Mouse from "../../../img/mouse.svg"
import CanvasControlTop from "./canvasControlTop"
import CurrentPartSelect from "./currentPartSelect"
import PriceCounter from "./priceCounter"
import { VRCanvas, DefaultXRControllers } from "@react-three/xr"

extend({ OrbitControls })

function Designer({
  textureURL,
  textureFrameURL,
  doorsTextures,
  setDoorsTextures,
  fullscreen,
  setFullscreen,
  size,
  admin,
  bgColor,
  orderDetail,
  prm,
  setPrm
}) {
  const location = useLocation()
  const colors = useTheme()

  const [manager, setManager] = useState()
  const [session, setSession] = useState()

  const startVR = useCallback(async () => {
    if (navigator.xr) {
      const session = await navigator.xr.requestSession(`immersive-vr`, {
        optionalFeatures: [`local-floor`, `bounded-floor`, `hand-tracking`]
      })

      manager.setSession(session)
      setSession(session)

      const onSessionEnd = () => {
        session.removeEventListener(`end`, onSessionEnd)

        setSession(null)
      }

      session.addEventListener(`end`, onSessionEnd)
    }
  }, [manager])

  return (
    <div
      css={css`
        position: relative;
        background: ${!admin ? bgColor : `#fff`};
        ${!admin && `border: 1px solid ${colors.gold}`};
        ${location.pathname.endsWith(`/summary`) && `border-bottom: 0;`}
        grid-area: ${location.pathname.startsWith(`/preview/`) === true ? `g` : `c`};
        @media only screen and (max-width: 992px) {
          border-radius: 0;
        }
        height: ${fullscreen ? `calc(100vh - 52px)` : `calc(100vh - 200px)`};
        ${location.pathname.endsWith(`/summary`) &&
          (fullscreen ? `height: 100vh;` : `height: calc(100vh - 150px);`)}
        @media only screen and (max-width: 768px) {
          height: 100%;
        }
        ${size === `small` && `height: 150px; width: 200px;`}
        margin-right: ${location.pathname.startsWith(`/app`) ? 30 : 0}px;
        @media only screen and (max-width: 768px) {
          margin-right: 0;
        }
      `}
    >
      <Switch>
        <Route path='/app'>
          <img
            src={Mouse}
            alt='Myš'
            width='40'
            css={css`
              position: absolute;
              right: 5px;
              top: 48px;
              cursor: pointer;
              z-index: 1;
              :hover + div {
                display: block;
              }
              @media only screen and (max-width: 768px) {
                display: none;
              }
            `}
          />
          <div
            css={css`
              position: absolute;
              right: 45px;
              top: 53px;
              background: ${colors.grey};
              color: #fff;
              padding: 5px 10px;
              font-size: 14px;
              display: none;
              z-index: 2;
            `}
          >
            Model lze přibližovat a otáčet pomocí myši. Použijte kolečko myši pro
            příblížení/oddálení. Držte levé tlačítko myši a pohybujte myší pro otáčení.
          </div>
        </Route>
      </Switch>
      {/* {size !== `small` && <PriceCounter prm={prm} />} */}
      {size !== `small` && (
        <div
          css={css`
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 1;
            @media only screen and (max-width: 768px) {
              top: 9px;
              left: 3px;
            }
          `}
        >
          <button
            css={css`
              background: ${prm.showDimensions ? `${colors.gold}` : `${colors.grey}`};
              color: ${prm.showDimensions ? `${colors.grey}` : `${colors.white}`};
              padding: 5px 10px;
              z-index: 1;
              cursor: pointer;
              text-transform: uppercase;
              font-size: 14px;
              cursor: pointer;
              transition: 200ms;
              display: flex;
              flex-direction: column;
              font-weight: 700px;
              border: 0;
              margin-right: 10px;
              &:hover {
                background: ${prm.showDimensions ? `${colors.grey}` : `${colors.gold}`};
                color: ${prm.showDimensions ? `${colors.white}` : `${colors.grey}`};
                path {
                  fill: ${prm.showDimensions ? `${colors.white}` : `${colors.grey}`};
                }
              }
              @media only screen and (max-width: 776px) {
                margin-right: 0;
              }
            `}
            onClick={() =>
              setPrm({
                ...prm,
                showDimensions: !prm.showDimensions
              })
            }
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height='16'
                viewBox='0 0 512 512'
                css={css`
                  margin-right: 5px;
                  @media only screen and (max-width: 768px) {
                    margin-right: 0;
                  }
                  path {
                    transition: 200ms;
                    fill: ${prm.showDimensions ? `${colors.grey}` : `${colors.white}`};
                  }
                `}
              >
                <path d='M497 337H175V15c0-8.284-6.716-15-15-15H15C6.716 0 0 6.716 0 15v482c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15V352c0-8.284-6.716-15-15-15zM30 30h115v43h-33c-8.284 0-15 6.716-15 15s6.716 15 15 15h33v34h-17c-8.284 0-15 6.716-15 15s6.716 15 15 15h17v34h-33c-8.284 0-15 6.716-15 15s6.716 15 15 15h33v34h-17c-8.284 0-15 6.716-15 15s6.716 15 15 15h17v42H30zm0 337h115v115H30zm452 115H175V367h42v33c0 8.284 6.716 15 15 15s15-6.716 15-15v-33h34v17c0 8.284 6.716 15 15 15s15-6.716 15-15v-17h34v33c0 8.284 6.716 15 15 15s15-6.716 15-15v-33h34v17c0 8.284 6.716 15 15 15s15-6.716 15-15v-17h43z'></path>
                <path d='M102.71 421.07c-1.87-8.926-10.711-13.914-19.07-11.42-8.616 2.808-12.195 11.286-9.99 18.71 2.843 8.695 11.36 12.182 18.71 9.99 7.919-2.582 11.71-10.014 10.35-17.28z'></path>
              </svg>
              <span
                css={css`
                  @media only screen and (max-width: 768px) {
                    display: none;
                  }
                `}
              >
                {prm.showDimensions ? `Skrýt` : `Ukázat`} rozměry (R){` `}
              </span>
            </div>
          </button>
        </div>
      )}
      <Switch>
        <Route path='/order/:owner/:id'>
          <CanvasControlTop
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            prm={prm}
            setPrm={setPrm}
            admin={1}
          />
        </Route>
        <Route path='/app/:owner'>
          <CanvasControlTop
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            prm={prm}
            setPrm={setPrm}
          />
        </Route>
        <Route path='*'>
          <CanvasControlTop
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            prm={prm}
            setPrm={setPrm}
            admin={1}
          />
        </Route>
      </Switch>
      {!orderDetail && (
        <div
          css={css`
            position: absolute;
            bottom: 0;
            z-index: 1;
            display: none;
            background: ${colors.grey};
            padding: 5px 0 5px 10px;
            @media only screen and (max-width: 768px) {
              display: flex;
              align-items: center;
              color: ${colors.white};
              font-size: 11px;
              text-transform: uppercase;
              font-weight: 600;
            }
          `}
        >
          <span
            css={css`
              margin-right: 10px;
            `}
          >
            Aktuální díl:
          </span>
          <CurrentPartSelect prm={prm} setPrm={setPrm} />
        </div>
      )}
      <div
        css={css`
          height: 100%;
          canvas {
            @media only screen and (max-width: 768px) {
              width: 100% !important;
              ${(orderDetail === 1 || admin === 1) && `height: 400px !important;`};
            }
          }
        `}
      >
        <VRCanvas camera={{ position: [0.2, 0.3, 2.5] }} onCreated={({ gl }) => setManager(gl.xr)}>
          <DefaultXRControllers />
          <React.Suspense fallback={null}>
            <Scene
              prm={prm}
              setPrm={setPrm}
              textureURL={textureURL}
              textureFrameURL={textureFrameURL}
              doorsTextures={doorsTextures}
              setDoorsTextures={setDoorsTextures}
              location={location.pathname}
            />
          </React.Suspense>
        </VRCanvas>
      </div>
      {size !== `small` && (
        <button
          css={css`
            position: absolute;
            background: ${navigator.xr ? colors.grey : `#000`};
            color: ${colors.white};
            padding: 5px 10px;
            z-index: 1;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 14px;
            cursor: pointer;
            transition: 200ms;
            display: flex;
            flex-direction: column;
            font-weight: 700px;
            border: 0;
            bottom: 10px;
            right: 10px;
            @media only screen and (max-width: 776px) {
              right: 8px;
              left: unset;
            }
            :hover + div {
              display: block;
            }
          `}
          onClick={startVR}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='20'
              enableBackground='new 0 0 512 512'
              viewBox='0 0 512 512'
              css={css`
                position: absolute;
                top: 10px;
                transition: 200ms;
              `}
            >
              <g fill='#e7e2f6'>
                <path
                  xmlns='http://www.w3.org/2000/svg'
                  d='M448.8 319.2h-92.464c-16.867 0-32.75-6.563-44.727-18.485l-32.132-32.133c-6.184-6.215-14.723-9.754-23.477-9.754-8.758 0-17.297 3.54-23.43 9.703l-32.156 32.156c-12 11.95-27.883 18.512-44.75 18.512H63.2C28.848 319.2 0 291.434 0 256V63.2C0 27.905 28.719 0 63.2 0h385.6C483.153 0 512 27.77 512 63.2V256c0 35.297-28.719 63.2-63.2 63.2zM256 228.827c16.684 0 32.973 6.762 44.695 18.543l32.11 32.113c6.285 6.254 14.648 9.715 23.53 9.715h92.466C466.84 289.2 482 274.598 482 256V63.2c0-18.606-15.172-33.2-33.2-33.2H63.2C45.16 30 30 44.602 30 63.2V256c0 18.61 15.172 33.2 33.2 33.2h92.464c8.879 0 17.246-3.462 23.559-9.747l32.132-32.129c11.672-11.734 27.961-18.496 44.645-18.496zm0 0'
                  data-original='#000000'
                ></path>
                <path
                  xmlns='http://www.w3.org/2000/svg'
                  d='M143.531 222.8c-34.847 0-63.199-28.35-63.199-63.198s28.352-63.204 63.2-63.204c34.85 0 63.202 28.356 63.202 63.204 0 34.847-28.351 63.199-63.203 63.199zm0-96.402c-18.304 0-33.199 14.895-33.199 33.204 0 18.304 14.895 33.199 33.2 33.199 18.308 0 33.202-14.895 33.202-33.2 0-18.308-14.894-33.203-33.203-33.203zm0 0M368.469 222.8c-34.852 0-63.203-28.35-63.203-63.198s28.351-63.204 63.203-63.204c34.847 0 63.199 28.356 63.199 63.204 0 34.847-28.352 63.199-63.2 63.199zm0-96.402c-18.309 0-33.203 14.895-33.203 33.204 0 18.304 14.894 33.199 33.203 33.199 18.304 0 33.199-14.895 33.199-33.2 0-18.308-14.895-33.203-33.2-33.203zm0 0'
                  data-original='#000000'
                ></path>
              </g>
            </svg>
            <span
              css={css`
                margin-left: 24px;
                line-height: 20px;
              `}
            >
              {navigator.xr ? `Vstup do VR` : `VR`}
            </span>
          </div>
        </button>
      )}
      {!navigator.xr && (
        <div
          css={css`
            position: absolute;
            right: 75px;
            bottom: 11px;
            height: 27px;
            background: ${colors.grey};
            color: #fff;
            padding: 5px 10px;
            font-size: 13px;
            z-index: 2;
            display: none;
          `}
        >
          3D model lze prohlédnout i ve VR. Zdá se však, že momentálně nemáte připojen VR headset
          nebo nejste v prohlížeči podporující webovou VR (Google Chrome).
        </div>
      )}
    </div>
  )
}

export default Designer
