/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"

function Header(props) {
  const fullscreen = props.fullscreen

  return (
    <header
      css={css`
        grid-area: a;
        z-index: ${fullscreen ? 0 : 1};
        background: #232429;
        @media print {
          display: none;
        }
      `}
    >
      <a href='https://lj-kuchyne.cz/'>
        <img
          src='images/lj-kuchyne-logo.svg'
          alt='Logo'
          height='26'
          css={css`
            margin: 10px 15px;
            @media only screen and (min-width: 769px) {
              display: none;
            }
          `}
        />
      </a>
      <div id='menu-wrapper' className='header-index'>
        <div className='container-fluid'>
          <div className='header-wrapper'>
            <div className='navbar-header'>
              <button
                type='button'
                className='navbar-toggle collapsed'
                data-toggle='collapse'
                id='navbar-toggle-menu'
                data-name='Menu'
                data-target='#menu'
              >
                <span className='navbar-toggle-line'></span>
                <span className='navbar-toggle-line'></span>
                <span className='navbar-toggle-line'></span>
              </button>
              <button
                type='button'
                className='navbar-toggle collapsed'
                data-toggle='collapse'
                id='navbar-toggle-call'
                data-name='Menu'
                data-target='#ul-header-btn'
              >
                <i className='fas fa-phone'></i>
              </button>
              <a className='navbar-brand logo' href='https://www.lj-kuchyne.cz/'>
                <img src='images/lj-kuchyne-logo.svg' className='img-responsive' alt='LJ kuchyně' />
              </a>
            </div>
            <ul className='collapse navbar-collapse list-unstyled' id='ul-header-btn'>
              <li>
                <a
                  className='btn-main btn-main-orange'
                  href='https://www.lj-kuchyne.cz/nezavazna-poptavka'
                >
                  Poptávka
                </a>
              </li>
              {` `}
              <li className='hidden-xs'>
                <a className='btn-main btn-main-orange' href='https://konfigurator.lj-kuchyne.cz/'>
                  Konfigurátor skříní
                </a>
              </li>
            </ul>
            <div className='collapse navbar-collapse' id='menu'>
              <nav className='menu'>
                <ul className='nav'>
                  <li
                    className='dropdown'
                    onMouseOver={(e) => e.target.parentElement.classList.toggle(`open`)}
                    onMouseOut={(e) => e.target.parentElement.classList.toggle(`open`)}
                  >
                    <a
                      href=''
                      className='dropdown-toggle'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='true'
                    >
                      Produkty{` `}
                    </a>
                    <ul
                      className='dropdown-menu'
                      css={css`
                        &:hover {
                          display: block;
                        }
                      `}
                    >
                      <li>
                        <a href='https://www.lj-kuchyne.cz/kuchyne-na-miru'>Kuchyně na míru</a>
                      </li>
                      <li>
                        <a href='https://www.lj-kuchyne.cz/vestavene-skrine'>Věstavěné skříně</a>
                      </li>
                      <li>
                        <a href='https://www.lj-kuchyne.cz/obyvaci-steny'>Obývací stěny</a>
                      </li>
                      <li>
                        <a href='https://www.lj-kuchyne.cz/kuchynske-spotrebice'>Spotřebiče</a>
                      </li>
                      <li>
                        <a href='https://www.lj-kuchyne.cz/prubeh-vyroby'>Průběh výroby</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href='https://www.lj-kuchyne.cz/novinky/'>Novinky</a>
                  </li>
                  <li>
                    <a href='https://www.lj-kuchyne.cz/realizace'>Realizace</a>
                  </li>
                  <li
                    className='dropdown'
                    onMouseOver={(e) => e.target.parentElement.classList.toggle(`open`)}
                    onMouseOut={(e) => e.target.parentElement.classList.toggle(`open`)}
                  >
                    <a
                      href=''
                      className='dropdown-toggle'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='true'
                    >
                      O nás{` `}
                    </a>
                    <ul
                      className='dropdown-menu'
                      css={css`
                        &:hover {
                          display: block;
                        }
                      `}
                    >
                      <li>
                        <a href='https://www.lj-kuchyne.cz/o-nas'>O nás</a>
                      </li>
                      <li>
                        <a href='https://www.lj-kuchyne.cz/kuchynske-studio-pardubice'>
                          Studio Pardubice
                        </a>
                      </li>
                      <li>
                        <a href='https://www.lj-kuchyne.cz/kuchynske-studio-hermanuv-mestec'>
                          Studio Heřmanův Městec
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href='https://www.lj-kuchyne.cz/kariera'>Kariéra</a>
                  </li>
                  <li>
                    <a href='https://www.lj-kuchyne.cz/lj-kuchyne'>Kontakty</a>
                  </li>
                  <li>
                    <a
                      href='https://www.facebook.com/LJvyrobanabytku'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-facebook-f'></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
