/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"
import { NavLink } from "react-router-dom"

export default function Cookies(props) {
  const colors = useTheme()
  const setCookiesBar = props.setCookiesBar
  const handleCookiesBar = () => {
    localStorage.setItem(`cookieBar`, false)
    setCookiesBar(`false`)
  }
  return (
    <div
      css={css`
        position: fixed;
        width: 100%;
        background: ${colors.green};
        bottom: 0;
        border-top: 1px solid #fff;
      `}
    >
      <div
        css={css`
          max-width: 100%;
          width: 1400px;
          margin: auto;
          display: flex;
          justify-content: space-between;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
          padding: 10px;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          align-items: center;
        `}
      >
        <p
          css={css`
            text-align: justify;
          `}
        >
          Tento web do Vašeho prohlížeče ukládá pouze tzv. technické informace, které jsou nutné pro
          správnou funkcionalitu webu. Více informací najdete
          {` `}
          <NavLink
            to='/cookies'
            css={css`
              color: #fff;
            `}
          >
            zde
          </NavLink>
          .
        </p>
        <button
          css={css`
            text-decoration: none;
            color: #fff;
            text-transform: uppercase;
            padding: 5px 20px;
            font-size: 14px;
            transition: 200ms;
            background: #fff;
            color: ${colors.green};
            align-items: center;
            display: flex;
            font-weight: 700;
            margin-left: 10px;
            font-size: 14px;
            cursor: pointer;
            border: none;
            &:hover,
            &.active {
              background: #fff;
              color: #000;
            }
          `}
          onClick={handleCookiesBar}
        >
          Ok
        </button>
      </div>
    </div>
  )
}
