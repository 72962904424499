/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useEffect, useState } from "react"
import { ThemeProvider } from "emotion-theming"
import { useParams } from "react-router-dom"
import Axios from "axios"
import Loading from "../../img/loading.svg"
import { ThemeProvider as ThemeProviderMaterialUI } from "@material-ui/styles"
import { createMuiTheme } from "@material-ui/core/styles"

export default function Demo(props) {
  let { owner } = useParams()
  const setAppId = props.setAppId

  const [loading, setLoading] = useState(true)
  const [primaryColor, setPrimaryColor] = useState()
  const [secondaryColor, setSecondaryColor] = useState()
  const [tertiaryColor, setTertiaryColor] = useState()
  const [quaternaryColor, setQuaternaryColor] = useState()
  const [labelColor, setLabelColor] = useState()
  const [color6, setColor6] = useState()
  const [theme, setTheme] = useState(
    createMuiTheme({
      palette: {
        primary: {
          main: `#FF6C6C`
        },
        secondary: {
          main: `#FF6C6C`
        }
      }
    })
  )

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  useEffect(() => {
    const checkLoggedIn = async () => {
      const userRes = await Axios.get(`/users/all/${owner}`)
      setPrimaryColor(userRes.data.primaryColor)
      setSecondaryColor(userRes.data.secondaryColor)
      setTertiaryColor(userRes.data.tertiaryColor)
      setQuaternaryColor(userRes.data.quaternaryColor)
      setLabelColor(userRes.data.labelColor)
      setColor6(userRes.data.color6)
      setTheme(
        createMuiTheme({
          palette: {
            primary: {
              main: userRes.data.primaryColor
            },
            secondary: {
              main: userRes.data.primaryColor
            }
          }
        })
      )
      if (userRes.data.favicon !== `favicon.ico`)
        document.querySelector(`[rel=icon]`).href =
          `https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/${userRes.data.favicon}`
      setLoading(false)
      setAppId(userRes.data.id)
    }
    checkLoggedIn()
  }, [bucketURL, owner, primaryColor, setAppId])

  const colors = {
    green: primaryColor,
    gold: secondaryColor,
    white: tertiaryColor,
    grey: quaternaryColor,
    red: `#FF6C6C`,
    label: labelColor,
    color6: color6
  }

  return (
    <>
      {loading ? (
        <img
          src={Loading}
          alt='Loading...'
          css={css`
            position: absolute;
            top: calc(50% - 141px);
            left: calc(50% - 141px);
          `}
        />
      ) : (
        <ThemeProviderMaterialUI theme={theme}>
          <ThemeProvider theme={colors}>{props.children}</ThemeProvider>
        </ThemeProviderMaterialUI>
      )}
    </>
  )
}
