/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState, useContext, useEffect } from "react"
import UserContext from "../../context/UserContext"
import Loading from "../../img/loading.svg"
import Axios from "axios"
import { useTheme } from "emotion-theming"
import Logo from "../../img/logo.png"

export default function LogoPage() {
  const [image, setImage] = useState({})
  const [logo, setLogo] = useState()
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const colors = useTheme()
  const { userData } = useContext(UserContext)

  let bucketURL

  const bucketGlobalVar = window.$bucket

  process.env.NODE_ENV === `development`
    ? (bucketURL = `${bucketGlobalVar}-dev`)
    : (bucketURL = bucketGlobalVar)

  useEffect(() => {
    const fetchLogo = async () => {
      const userRes = await Axios.get(`/users/all/${userData.user.displayName}`)
      setLogo(`https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/${userRes.data.favicon}`)
    }
    fetchLogo()
  }, [bucketURL, userData.user.displayName])

  const logoSubmit = async (e) => {
    e.preventDefault()
    try {
      let token = localStorage.getItem(`auth-token`)
      if (token === null) {
        localStorage.setItem(`auth-token`, ``)
        token = ``
      }
      const newLogo = new FormData()
      newLogo.append(`logo`, image)
      setError()
      setLoading(true)
      await Axios.post(`/users/faviconUpload`, newLogo, {
        headers: { "x-auth-token": token }
      })
      setError()
      setImage()
      Axios.get(`/users/all/${userData.user.displayName}`)
        .then((res) => {
          setLogo(`https://` + bucketURL + `.s3.eu-central-1.amazonaws.com/${res.data.favicon}`)
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (err) {
      if (err.response.data.msg) {
        setError(err.response.data.msg)
        setLoading(false)
      }
    }
  }
  return (
    <>
      <h2
        css={css`
          display: flex;
          &:before {
            content: "";
            background-image: url(${Logo});
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            display: inline-flex;
          }
        `}
      >
        Webová favicona
      </h2>
      <form
        css={css`
          display: grid;
          max-width: 500px;
          width: 100%;
          grid-template-columns: auto auto;
          grid-row-gap: 10px;
          align-items: center;
          padding: 10px;
          background: #ccc;
          margin-top: 20px;
          label {
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
          }
          @media only screen and (max-width: 768px) {
            max-width: 100%;
            label,
            input {
              grid-column-start: 1;
              grid-column-end: 3;
              padding: 0;
            }
          }
        `}
        onSubmit={logoSubmit}
        encType='multipart/form-data'
      >
        {(success || loading || error) && (
          <div
            css={css`
              background: ${loading ? `#f0ad4e` : `#60a26b`};
              ${error && `background: ${colors.red};`}
              grid-column-start: 1;
              grid-column-end: 3;
              color: #fff;
              padding: 10px;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              position: relative;
            `}
          >
            {error}
            {success}
            {loading && (
              <>
                Favicona se nahrává...
                <img
                  src={Loading}
                  alt='Loading...'
                  height='70'
                  css={css`
                    position: absolute;
                    right: -17px;
                    top: -17px;
                  `}
                />
              </>
            )}
          </div>
        )}
        <div
          css={css`
            background: #f2f2f2;
            grid-column-start: 1;
            grid-column-end: 3;
            padding: 10px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
          `}
        >
          <img
            src={logo}
            alt='Favicona'
            css={css`
              max-width: 200px;
            `}
            onLoad={() => {
              if (loading) {
                setSuccess(`Favicona byla úspěšně nahrána!`)
                setLoading(false)
                document.getElementById(`logo`).value = ``
                setTimeout(function() {
                  setSuccess()
                }, 5000)
              }
            }}
          />
        </div>
        <label>Favicona:</label>
        <input
          type='file'
          name='logo'
          id='logo'
          css={css`
            height: auto;
          `}
          onChange={(e) => setImage(e.target.files[0])}
        />
        <input
          type='submit'
          value='Nahrát faviconu'
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            cursor: pointer;
            &:hover {
              color: white;
              background: ${colors.green};
            }
          `}
        />
      </form>
    </>
  )
}
