/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React from "react"

export default function Model2DHeights(props) {
  const prm = props.prm
  const realHeight = props.realHeight
  const sumOfSlots = props.sumOfSlots
  const ratio = props.ratio
  const ratioPrint = props.ratioPrint
  const side = props.side
  const actualPart = props.actualPart
  const setConfigError = props.setConfigError
  const setConfigErrorNumber = props.setConfigErrorNumber

  const sizeOfOnePart =
    ((realHeight - (prm.config[actualPart].length + 1) * prm.deskWidth) / sumOfSlots) * 100
  const sizeOfOnePartPx = (realHeight / sumOfSlots) * 100

  function ErrorTooltip(props) {
    return (
      <>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          x='0'
          y='0'
          enableBackground='new 0 0 512 512'
          version='1.1'
          viewBox='0 0 512 512'
          xmlSpace='preserve'
          height='12'
        >
          <circle fill='#fff' cx='256' cy='378.5' r='25'></circle>
          <path
            fill='#fff'
            d='M256 0C114.516 0 0 114.497 0 256c0 141.484 114.497 256 256 256 141.484 0 256-114.497 256-256C512 114.516 397.503 0 256 0zm0 472c-119.377 0-216-96.607-216-216 0-119.377 96.607-216 216-216 119.377 0 216 96.607 216 216 0 119.377-96.607 216-216 216z'
          ></path>
          <path
            fill='#fff'
            d='M256 128.5c-44.112 0-80 35.888-80 80 0 11.046 8.954 20 20 20s20-8.954 20-20c0-22.056 17.944-40 40-40s40 17.944 40 40-17.944 40-40 40c-11.046 0-20 8.954-20 20v50c0 11.046 8.954 20 20 20s20-8.954 20-20v-32.531c34.466-8.903 60-40.26 60-77.469 0-44.112-35.888-80-80-80z'
          ></path>
        </svg>
        <div
          css={css`
            display: flex;
            display: none;
            position: absolute;
            bottom: 24px;
            left: 0;
            width: 100px;
            color: ${colors.red};
            font-weight: bold;
            background: #fff;
            z-index: 1;
            border-radius: 5px;
            font-size: 11px;
            letter-spacing: 0.5px;
            line-height: 14px;
            padding: 4px 6px;
            &:after {
              top: 100%;
              left: ${side === 1 ? `46px` : `11px`};
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-top-color: #fff;
              border-width: 5px;
              margin-left: -5px;
            }
          `}
        >
          {props.partSize * sizeOfOnePart > 80 &&
            props.partType === 1 &&
            `Šuplík je příliš vysoký! Povolený interval je 13-80cm.`}
          {props.partSize * sizeOfOnePart < 13 &&
            props.partType === 1 &&
            `Šuplík je příliš nízký! Povolený interval je 13-80cm.`}
          {props.partSize * sizeOfOnePart < 85 &&
            props.partType === 2 &&
            `Prostor s ramínkem je příliš nízký! Povolený interval je 85cm+.`}
        </div>
      </>
    )
  }

  const colors = useTheme()

  const tagStyles = `background: ${colors.grey};
    padding: ${side === 1 ? `1px 5px 1px 2px` : `1px 2px 1px 5px`};
    position: relative;
    top: 2px;
    display: flex;
    align-items: center;
    ${
      side === 0 ? `flex-direction: row-reverse; svg{margin-right: 4px;}` : `svg{margin-left: 4px;}`
    }
    svg{display: none};
    border: 2px solid ${colors.grey};
    ${side === 0 ? `border-right: 0;` : `border-left: 0;`}
    `

  const tagErrorStyles = `cursor: pointer;
    color: #fff;
    background: ${colors.red};
    &:hover div{
      display: flex
    }
    svg{
      display: flex;
    }`

  let numberOfErrors = 0

  const error = (partSize, partType, partNumber, sizeOfOnePart2) => {
    if (partType === 1) {
      if (partSize * sizeOfOnePart2 > 80) {
        setConfigError(
          `Opravte prosím chybu ve vnitřním vybavení ${partNumber}. dílu!
          Některá vnitřní vybavení nemohou být použita v libovolné výšce.`
        )
        setConfigErrorNumber(partNumber - 1)
        return true
      } else if (partSize * sizeOfOnePart2 < 13) {
        setConfigError(
          `Opravte prosím chybu ve vnitřním vybavení ${partNumber}. dílu!
          Některá vnitřní vybavení nemohou být použita v libovolné výšce.`
        )
        setConfigErrorNumber(partNumber - 1)
        return true
      } else return false
    } else if (partType === 2 && partSize * sizeOfOnePart2 < 85) {
      setConfigError(
        `Opravte prosím chybu ve vnitřním vybavení ${partNumber}. dílu!
        Některá vnitřní vybavení nemohou být použita v libovolné výšce.`
      )
      setConfigErrorNumber(partNumber - 1)
      return true
    } else return false
  }

  if (side === 1) {
    prm.config.map((el, a) => {
      let sumOfSlots = 0
      prm.config[a].map((x, k) => {
        if (Number.isInteger(x)) {
          sumOfSlots = sumOfSlots + x
        } else {
          const arraySum = prm.config[a][k][0]
            .slice(0, prm.config[a][k][0].length + 1)
            .reduce((a, b) => a + b, 0)
          sumOfSlots = sumOfSlots + arraySum
        }
        return true
      })
      const sizeOfOnePart2 =
        ((realHeight - (prm.config[actualPart].length + 1) * prm.deskWidth) / sumOfSlots) * 100
      prm.config[a].map((el, i) => {
        if (Array.isArray(prm.config[a][i])) {
          prm.config[a][i][0].map((el, k) => {
            error(el, prm.content[a][i][0][k], a + 1, sizeOfOnePart2) && numberOfErrors++
            error(el, prm.content[a][i][0][k], a + 1, sizeOfOnePart2)
            return true
          })
          prm.config[a][i][1].map((el, k) => {
            error(el, prm.content[a][i][1][k], a + 1, sizeOfOnePart2) && numberOfErrors++
            error(el, prm.content[a][i][1][k], a + 1, sizeOfOnePart2)
            return true
          })
        } else {
          if (error(prm.config[a][i], prm.content[a][i], a + 1, sizeOfOnePart2)) {
            numberOfErrors++
            error(prm.config[a][i], prm.content[a][i], a + 1, sizeOfOnePart2)
          }
        }
        return true
      })
      numberOfErrors === 0 && setConfigError(false)
      return true
    })
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      {prm.config[actualPart].map((el, i) => {
        let currentHeight
        let currentHeightWithShelves
        if (Array.isArray(prm.config[actualPart][i])) {
          const slotHeight = prm.config[actualPart][i][0]
            .slice(0, prm.config[actualPart][i][0].length + 1)
            .reduce((a, b) => a + b, 0)
          currentHeight = slotHeight * sizeOfOnePart
          currentHeightWithShelves = slotHeight * sizeOfOnePartPx
        } else {
          currentHeight = prm.config[actualPart][i] * sizeOfOnePart
          currentHeightWithShelves = prm.config[actualPart][i] * sizeOfOnePartPx
        }

        return (
          <div
            css={css`
              height: ${currentHeightWithShelves * ratio}px;
              @media print {
                height: ${currentHeightWithShelves * ratioPrint}px;
              }
              display: flex;
              align-items: flex-end;
              font-size: 12px;
              color: ${colors.white};
            `}
            key={i}
          >
            {Array.isArray(prm.config[actualPart][i]) ? (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: ${side === 0 ? `flex-end` : `flex-start`};
                `}
              >
                {prm.config[actualPart][i][side].map((el, k) => {
                  return (
                    <div
                      css={css`
                        display: flex;
                        align-items: flex-end;
                        height: ${el * ((realHeight / sumOfSlots) * 100 * ratio)}px;
                      `}
                      key={k}
                    >
                      <div
                        css={css`
                          ${tagStyles}
                          ${error(
                            el,
                            prm.content[actualPart][i][side][k],
                            actualPart + 1,
                            sizeOfOnePart
                          ) && tagErrorStyles}
                        `}
                      >
                        {(100 * Math.round(el * sizeOfOnePart * 10)) / 1000}
                        {` `}cm
                        <ErrorTooltip
                          partSize={el}
                          partType={prm.content[actualPart][i][side][k]}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <>
                {side === 1 && (
                  <div
                    css={css`
                      ${tagStyles};
                      top: 1px;
                      ${error(
                        prm.config[actualPart][i],
                        prm.content[actualPart][i],
                        actualPart + 1,
                        sizeOfOnePart
                      ) && tagErrorStyles}
                      svg {
                        margin-left: 3px;
                      }
                    `}
                  >
                    {Math.round(currentHeight * 10) / 10}
                    {` `}cm
                    <ErrorTooltip
                      partSize={prm.config[actualPart][i]}
                      partType={prm.content[actualPart][i]}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}
