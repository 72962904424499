/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React, { useState, useEffect, useContext } from "react"
import HangerHolder from "../../../img/hanger.svg"
import HangerHolderAdmin from "../../../img/hangerAdmin.svg"
import Model2DHeights from "./model2DHeights"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import Axios from "axios"
import UserContext from "../../../context/UserContext"
import { useParams } from "react-router-dom"
import printDrawerBgColor from "../../../img/print-drawer-bg.png"
import printDrawerBgColor2 from "../../../img/print-drawer-bg-inner.png"

export default function Model2DConfig(props) {
  const defaultPrm = props.defaultPrm
  const prm = props.prm
  const setPrm = props.setPrm
  const loop = props.loop
  const adminOrderDetail = props.adminOrderDetail
  const adminConfig = props.adminConfig
  const [error, setError] = useState()
  const [configurationNumbers, setConfigurationNumbers] = useState([])
  const setConfigError = props.setConfigError
  const setConfigErrorNumber = props.setConfigErrorNumber

  const { userData } = useContext(UserContext)
  const { owner } = useParams()

  function handleCurrentSlot(e) {
    const currentSlot = Array(defaultPrm.config.length)
      .fill(1)
      .map((el, j) => {
        if (j === defaultPrm.currentPart) {
          if (e.target.value.length > 1) {
            const array = JSON.parse(`[` + e.target.value + `]`)
            return array
          } else {
            return parseInt(e.target.value)
          }
        } else return defaultPrm.currentSlot[j]
      })
    setPrm({ ...defaultPrm, currentSlot: currentSlot })
  }

  function handlePartConfig(config, content, currentSlot) {
    let config2 = defaultPrm.config.slice()
    config2[defaultPrm.currentPart] = config[0]
    let content2 = defaultPrm.content.slice()
    content2[defaultPrm.currentPart] = content[0]
    let currentSlot2 = defaultPrm.currentSlot.slice()
    currentSlot2[defaultPrm.currentPart] = currentSlot

    setPrm({
      ...defaultPrm,
      config: config2,
      content: content2,
      currentSlot: currentSlot2
    })
  }

  const displayName = userData.user ? userData.user.displayName : owner

  useEffect(() => {
    const loadConfigurationNumbers = async () => {
      try {
        const loadedConfigurationNumbers = await Axios.get(
          `/users/configurationNumbers/${displayName}`
        )
        setConfigurationNumbers(loadedConfigurationNumbers.data.configuration)
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg)
      }
    }
    loadConfigurationNumbers()
  }, [displayName])

  const handleConfig = async (e, number) => {
    if (e.target.checked) {
      setConfigurationNumbers([...configurationNumbers, number])
      try {
        const submittedData = {
          configurationNumber: number
        }
        let token = localStorage.getItem(`auth-token`)
        if (token === null) {
          localStorage.setItem(`auth-token`, ``)
          token = ``
        }
        await Axios.put(`/users/addConfiguration`, submittedData, {
          headers: { "x-auth-token": token }
        })
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg)
      }
    } else {
      const filteredItems = configurationNumbers.filter((item) => item !== number)
      setConfigurationNumbers(filteredItems)
      try {
        const submittedData = {
          configurationNumber: number
        }
        let token = localStorage.getItem(`auth-token`)
        if (token === null) {
          localStorage.setItem(`auth-token`, ``)
          token = ``
        }
        await Axios.put(`/users/removeConfiguration`, submittedData, {
          headers: { "x-auth-token": token }
        })
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg)
      }
    }
  }

  const WhiteCheckbox = withStyles({
    root: {
      color: `#ffffff`,
      padding: 4,
      "&$checked": {
        color: `#ffffff`
      }
    },
    checked: {}
  })((props) => <Checkbox color='default' {...props} />)

  const colors = useTheme()

  let prm2 = 0
  let ratio
  let ratioPrint

  if (adminOrderDetail === 1) {
    const sumOfParts = prm.parts.reduce((a, b) => a + b, 0)
    ratio = 1.4
    if (sumOfParts * ratio * prm.width > 7) ratioPrint = 7 / sumOfParts / prm.width
  } else ratio = 1.25

  let incrementator = 0

  const loop2 = Array.isArray(loop) ? loop : Array(loop).fill(1)

  return (
    <div
      css={css`
        display: flex;
        padding-bottom: 5px;
        ${(adminConfig || props.modal) && `flex-wrap: wrap;`}
        ${(adminConfig || adminOrderDetail) && `overflow-y: auto;`}
        @media print {
          flex-wrap: wrap;
        }
        @media only screen and (max-width: 768px) {
          ${(adminConfig || adminOrderDetail) && `flex-wrap: nowrap;`}
          overflow-x: scroll;
          max-width: calc(100vw - 60px);
          overflow-y: auto;
        }
      `}
    >
      {error}
      {loop2.map((el, i) => {
        const isSelected = configurationNumbers.includes(el.number)
        if (isSelected) incrementator++
        if (isSelected || adminConfig || adminOrderDetail || loop === 1) {
          let actualPart
          if (loop !== 1 && !adminOrderDetail) {
            prm2 = prm[i]
            prm2.currentPart = 0
            prm2.width = 0.5
            prm2.height = 2
            prm2.parts = [1]
            if (Array.isArray(prm2.config[0][0])) prm2.currentSlot = [0, 0, 0]
            else prm2.currentSlot = 0
          } else {
            prm2 = prm
          }
          if (adminOrderDetail) {
            actualPart = i
          } else {
            actualPart = prm2.currentPart
          }
          let sumOfSlots = 0
          prm2.config[actualPart].map((x, k) => {
            if (Number.isInteger(x)) {
              sumOfSlots = sumOfSlots + x
            } else {
              const arraySum = prm2.config[actualPart][k][0]
                .slice(0, prm2.config[actualPart][k][0].length + 1)
                .reduce((a, b) => a + b, 0)
              sumOfSlots = sumOfSlots + arraySum
            }
            return true
          })
          const realHeight =
            (prm2.showTopSpace ? prm2.height - prm2.topSpaceHeight : prm2.height) -
            (prm.bottomSokl === 1 ? prm.soklHeight : 0)
          const sumOfParts = prm2.parts.reduce((a, b) => a + b, 0)

          const basicWidth = sumOfParts * prm.width
          const roundedPartsWidth = (prm.roundedLeft + prm.roundedRight) * prm.roundedWidth
          const availableSpace = basicWidth - roundedPartsWidth
          const partsWithoutRounded = sumOfParts - prm.roundedLeft - prm.roundedRight
          const singlePartWidth = availableSpace / partsWithoutRounded

          const current = adminOrderDetail ? i : prm.currentPart

          const slotWidth =
            (current === 0 && prm2.roundedLeft === 1) ||
            (current === prm2.parts.length - 1 && prm2.roundedRight === 1)
              ? prm2.roundedWidth * 100
              : prm2.parts[actualPart] *
                (loop === 1 || adminOrderDetail === 1 ? singlePartWidth : prm2.width) *
                100
          const slotWidthPx = slotWidth

          return (
            <div
              key={i}
              css={css`
                @media print {
                  margin-bottom: 20px;
                }
              `}
            >
              {!(
                (prm.currentPart === 0 && prm2.roundedLeft === 1) ||
                (prm.currentPart === sumOfParts - 1 && prm2.roundedRight === 1)
              ) && (
                <div
                  css={css`
                    display: flex;
                    ${adminOrderDetail && `margin-right: 10px;`}
                    ${adminConfig && `margin-bottom: 20px; margin-top: 20px;`}
                  `}
                >
                  {(loop === 1 || adminOrderDetail === 1) && (
                    <Model2DHeights
                      prm={prm2}
                      realHeight={realHeight}
                      sumOfSlots={sumOfSlots}
                      ratio={ratio}
                      ratioPrint={ratioPrint}
                      side={0}
                      actualPart={actualPart}
                      setConfigError={setConfigError}
                      setConfigErrorNumber={setConfigErrorNumber}
                    />
                  )}
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      margin-top: 1px;
                      ${Array.isArray(loop) && !adminOrderDetail && `margin-right: 6px;`}
                      label {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                      }
                      input.drawer {
                        display: none;
                      }
                    `}
                  >
                    {Array(prm2.config[actualPart].length)
                      .fill(1)
                      .map((el, j) => {
                        const slotHeight = () => {
                          if (!Array.isArray(prm2.config[actualPart][j])) {
                            return (prm2.config[actualPart][j] / sumOfSlots) * realHeight * 100
                          } else {
                            return (
                              (prm2.config[actualPart][j][0]
                                .slice(0, prm2.config[actualPart][j][0].length + 1)
                                .reduce((a, b) => a + b, 0) /
                                sumOfSlots) *
                              realHeight *
                              100
                            )
                          }
                        }
                        return (
                          <React.Fragment key={j}>
                            <input
                              type='checkbox'
                              id={`drawer${j}${loop}${i}`}
                              className='drawer'
                              value={j}
                              checked={isSelected}
                              onChange={
                                !adminOrderDetail && !adminConfig
                                  ? loop === 1
                                    ? handleCurrentSlot
                                    : () =>
                                        handlePartConfig(
                                          prm[i].config,
                                          prm[i].content,
                                          prm[i].currentSlot
                                        )
                                  : adminConfig
                                  ? (e) => handleConfig(e, prm[i].number)
                                  : () => {}
                              }
                            />
                            <label
                              htmlFor={`drawer${j}${loop}${i}`}
                              css={css`
                border: 2px solid ${colors.grey};
                ${prm.roundedLeft === 1 && current === 0 && `border-left: 0;`}
                ${prm.roundedRight === 1 && current === prm.parts.length - 1 && `border-right: 0;`}
                ${j !== prm2.config[actualPart].length - 1 && `border-bottom: 0;`}
                ${adminOrderDetail && `cursor: auto;`}
                height: ${slotHeight() * ratio}px;
                width: ${slotWidthPx * ratio}px;
                position: relative;
                @media print {
                  height: ${slotHeight() * ratioPrint}px;
                  width: ${slotWidthPx * ratioPrint}px;
                }
                ${prm2.content[actualPart][j] === 1 && `background: ${colors.white};`}
                ${!adminOrderDetail &&
                  prm2.currentSlot[actualPart] === j &&
                  `background: ${prm2.currentColor};`}
                ${Array.isArray(prm2.config[actualPart][j]) && `border-top: 0;`}
                ${Array.isArray(prm2.config[actualPart][j + 1]) && `border-bottom: 0;`}
                &:hover{
                  background: ${!Array.isArray(prm2.config[actualPart][j]) &&
                    !adminOrderDetail &&
                    prm2.currentColor};
                }
              }
              `}
                            >
                              {Array.isArray(prm2.config[actualPart][j]) ? (
                                <>
                                  {[...Array(2)].map((x, m) => {
                                    let width
                                    if (prm2.config[actualPart][j][3] === 1) {
                                      if (m === 0) width = 33.33
                                      else width = 66.66
                                    } else if (prm2.config[actualPart][j][3] === 2) {
                                      if (m === 0) width = 66.66
                                      else width = 33.33
                                    } else width = 50

                                    return (
                                      <div
                                        css={css`
                                          display: flex;
                                          flex-direction: column;
                                          width: ${width}%;
                                        `}
                                        key={m}
                                      >
                                        {prm2.config[actualPart][j][m].map((el, p) => {
                                          const slotHeight = (el / sumOfSlots) * 100 * realHeight
                                          return (
                                            <React.Fragment key={p}>
                                              <input
                                                type='checkbox'
                                                id={`drawer${j}${
                                                  m === 0 ? `l` : `r`
                                                }${p}${loop}${i}`}
                                                className='drawer'
                                                value={`${m},${p},${j}`}
                                                checked={isSelected}
                                                onChange={
                                                  !adminOrderDetail && !adminConfig
                                                    ? loop === 1
                                                      ? handleCurrentSlot
                                                      : () =>
                                                          handlePartConfig(
                                                            prm[i].config,
                                                            prm[i].content,
                                                            prm[i].currentSlot
                                                          )
                                                    : adminConfig
                                                    ? (e) => handleConfig(e, prm[i].number)
                                                    : () => {}
                                                }
                                              />
                                              <label
                                                htmlFor={`drawer${j}${
                                                  m === 0 ? `l` : `r`
                                                }${p}${loop}${i}`}
                                                css={css`
                                    height: ${slotHeight * ratio}px;
                                    position: relative;
                                    @media print {
                                      height: ${slotHeight * ratioPrint}px;
                                    }
                                    width: 100%;
                                    ${adminOrderDetail && `cursor: auto;`}
                                    border-top: ${
                                      p === 0 && j !== 0 && !prm2.config[actualPart][j][2][m]
                                        ? 0
                                        : 2
                                    }px
                                      solid ${colors.grey};
                                    ${m === 0 && `border-right: 2px solid ${colors.grey}`};
                                    ${prm2.config[actualPart][j][m].length - 1 === p &&
                                      prm2.content[actualPart][j][m][p] === 1 &&
                                      `border-bottom: 2px solid ${colors.grey};`};
                                    ${prm2.content[actualPart][j][m][p] === 1 &&
                                      `background: ${colors.white};`}
                                    ${!adminOrderDetail &&
                                      Array.isArray(prm2.currentSlot[actualPart]) &&
                                      prm2.currentSlot[actualPart][0] === m &&
                                      prm2.currentSlot[actualPart][1] === p &&
                                      prm2.currentSlot[actualPart][2] === j &&
                                      !adminOrderDetail &&
                                      `background: ${prm2.currentColor};`}
                                    &:hover {
                                      ${!adminOrderDetail && `background: ${prm2.currentColor};`}
                                  `}
                                              >
                                                {prm2.content[actualPart][j][m][p] === 2 && (
                                                  <img
                                                    src={
                                                      adminOrderDetail
                                                        ? HangerHolderAdmin
                                                        : HangerHolder
                                                    }
                                                    css={css`
                                                      max-height: 30px;
                                                      max-width: 80%;
                                                    `}
                                                    alt='Hanger holder'
                                                  />
                                                )}
                                                {prm2.content[actualPart][j][m][p] === 1 && (
                                                  <>
                                                    <img
                                                      src={printDrawerBgColor}
                                                      alt='Šuplík'
                                                      css={css`
                                                        width: 100%;
                                                        height: 100%;
                                                        position: absolute;
                                                        left: 0;
                                                        top: 0;
                                                        display: none;
                                                        @media print {
                                                          display: block;
                                                        }
                                                      `}
                                                    />
                                                    <img
                                                      src={printDrawerBgColor2}
                                                      alt='Šuplíková úchytka'
                                                      css={css`
                                                        width: 10px;
                                                        height: 5px;
                                                        position: absolute;
                                                        left: calc(50% - 5px);
                                                        top: 5px;
                                                        display: none;
                                                        @media print {
                                                          display: block;
                                                        }
                                                      `}
                                                    />
                                                    <div
                                                      css={css`
                                                        width: 10px;
                                                        height: 5px;
                                                        margin-top: 5px;
                                                        background: ${colors.grey};
                                                      `}
                                                    ></div>
                                                  </>
                                                )}
                                              </label>
                                            </React.Fragment>
                                          )
                                        })}
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <>
                                  {prm2.content[actualPart][j] === 2 && (
                                    <img
                                      src={adminOrderDetail ? HangerHolderAdmin : HangerHolder}
                                      css={css`
                                        max-height: 30px;
                                        max-width: 80%;
                                      `}
                                      alt='Hanger holder'
                                    />
                                  )}
                                  {prm2.content[actualPart][j] === 1 && (
                                    <>
                                      <img
                                        src={printDrawerBgColor}
                                        alt='Šuplík'
                                        css={css`
                                          width: 100%;
                                          height: 100%;
                                          position: absolute;
                                          left: 0;
                                          top: 0;
                                          display: none;
                                          @media print {
                                            display: block;
                                          }
                                        `}
                                      />
                                      <img
                                        src={printDrawerBgColor2}
                                        alt='Šuplíková úchytka'
                                        css={css`
                                          width: 10px;
                                          height: 5px;
                                          position: absolute;
                                          left: calc(50% - 5px);
                                          top: 5px;
                                          display: none;
                                          @media print {
                                            display: block;
                                          }
                                        `}
                                      />
                                      <div
                                        css={css`
                                          width: 10px;
                                          height: 5px;
                                          margin-top: 5px;
                                          background: ${colors.grey};
                                        `}
                                      ></div>
                                    </>
                                  )}
                                </>
                              )}
                            </label>
                          </React.Fragment>
                        )
                      })}
                    {adminConfig && (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          font-size: 12px;
                          font-weight: 600;
                          text-transform: uppercase;
                        `}
                      >
                        {prm[i].number}
                        <WhiteCheckbox
                          checked={isSelected}
                          onChange={(e) => handleConfig(e, prm[i].number)}
                          size='small'
                        />
                      </div>
                    )}
                    {props.numbers && (
                      <span
                        css={css`
                          margin-top: 2px;
                          margin-bottom: 5px;
                          font-size: 13px;
                          color: ${colors.white};
                        `}
                      >
                        {incrementator}
                      </span>
                    )}
                    {(loop === 1 || adminOrderDetail === 1) && (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          font-size: 12px;
                          padding: 1px 5px;
                          background: ${colors.grey};
                          color: ${colors.white};
                          justify-content: center;
                          width: ${slotWidthPx * ratio}px;
                          @media print {
                            width: ${slotWidthPx * ratioPrint}px;
                          }
                        `}
                      >
                        {Math.round(slotWidth * 10) / 10}
                        {` `}cm
                      </div>
                    )}
                  </div>
                  {(loop === 1 || adminOrderDetail === 1) && (
                    <Model2DHeights
                      prm={prm2}
                      realHeight={realHeight}
                      sumOfSlots={sumOfSlots}
                      ratio={ratio}
                      ratioPrint={ratioPrint}
                      side={1}
                      actualPart={actualPart}
                      setConfigError={setConfigError}
                      setConfigErrorNumber={setConfigErrorNumber}
                    />
                  )}
                </div>
              )}
            </div>
          )
        } else {
          return false
        }
      })}
    </div>
  )
}
