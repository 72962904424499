/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React, { useState, useContext, useEffect } from "react"
import Wrapper from "./registerLoginWrapper"
import Axios from "axios"
import UserContext from "../../context/UserContext"
import { useHistory, NavLink } from "react-router-dom"
import ErrorNotice from "../modules/errorNotice"

export default function Login() {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState()

  const { userData, setUserData } = useContext(UserContext)
  const history = useHistory()

  const submit = async (e) => {
    e.preventDefault()
    try {
      const loginUser = { email, password }
      const loginRes = await Axios.post(`/users/login`, loginUser)
      setUserData({
        token: loginRes.data.token,
        primaryColor: loginRes.data.primaryColor,
        secondaryColor: loginRes.data.secondaryColor,
        tertiaryColor: loginRes.data.tertiaryColor,
        quaternaryColor: loginRes.data.quaternaryColor,
        user: loginRes.data.user
      })
      localStorage.setItem(`auth-token`, loginRes.data.token)
      history.push(`/admin/orders/1`)
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg)
    }
  }

  useEffect(() => {
    if (userData.user) history.push(`/admin/orders/1`)
  })

  return (
    <Wrapper maxWidth={390}>
      <h2>Přihlášení</h2>
      {error && <ErrorNotice message={error} />}
      <form onSubmit={submit}>
        <label htmlFor='login-email'>E-mail:</label>
        <input id='login-email' type='email' onChange={(e) => setEmail(e.target.value)} />
        <label htmlFor='login-password'>Heslo:</label>
        <input id='login-password' type='password' onChange={(e) => setPassword(e.target.value)} />
        <NavLink
          to='/forgotPassword'
          css={css`
            grid-column-start: 1;
            grid-column-end: 3;
            font-size: 14px;
            color: #fff;
            text-align: right;
            text-decoration: underline;
            cursor: pointer;
          `}
        >
          Zapomněli jste heslo?
        </NavLink>
        <input type='submit' value='Přihlásit se' />
      </form>
    </Wrapper>
  )
}
