/** @jsx jsx */
import React from "react"
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { Scrollbars } from "react-custom-scrollbars"

function LeftCol(props) {
  const colors = useTheme()
  return (
    <div
      css={css`
        background: ${colors.gold};
        grid-area: b;
        padding-bottom: 3px;
        z-index: 0;
        padding-left: 20px;
        @media only screen and (max-width: 992px) {
          margin-right: 0;
          border-radius: 0;
          border-bottom: 2px solid ${colors.grey};
          border-right: 0;
        }
        @media only screen and (max-width: 768px) {
          padding-left: 0;
        }
        h2 {
          background: ${colors.grey};
          color: ${colors.white};
          font-size: 13px;
          padding: 10px;
          margin-top: 0;
          margin-top: 10px;
          font-weight: 600;
        }
        .column {
          display: grid;
          grid-template-columns: 46% 54%;
          margin-bottom: 15px;
          align-items: center;
          padding: 0 5px;
        }
        label {
          font-size: 13px;
          font-weight: 500;
          color: ${colors.label};
        }
        > div > div:nth-of-type(3) {
          top: 10px !important;
          right: 2px !important;
        }
        > div > div:nth-of-type(3) > div {
          background-color: ${colors.white} !important;
          border-radius: 0 !important;
          width: 7px !important;
        }
        > div > div:nth-of-type(1) > div {
          padding: 0 10px;
        }
        > div > div:nth-of-type(1) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      `}
    >
      <Scrollbars id={`leftScroll`}>{props.children}</Scrollbars>
    </div>
  )
}

export default LeftCol
