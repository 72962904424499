/** @jsx jsx */
import React, { useState } from "react"
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import ResizeIcon from "../../../img/3d.svg"
import ResizeIconActive from "../../../img/3d-active.svg"
import ColorIcon from "../../../img/art.svg"
import ColorIconActive from "../../../img/art-active.svg"
import PartsIcon from "../../../img/parts.svg"
import PartsIconActive from "../../../img/parts-active.svg"
import DoorsIcon from "../../../img/doors.svg"
import DoorsIconActive from "../../../img/doors-active.svg"
import SummaryIcon from "../../../img/summary.svg"
import SummaryIconActive from "../../../img/summary-active.svg"
import { Link, useLocation } from "react-router-dom"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"

function Menu(props) {
  const location = useLocation()
  const colors = useTheme()
  const configError = props.configError
  const configErrorNumber = props.configErrorNumber
  const prm = props.prm
  const setPrm = props.setPrm

  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <div
      css={css`
        border-bottom: 1px solid ${colors.grey};
        display: flex;
        background: ${colors.gold};
        grid-area: e;
        padding: 10px 0 0 10px;
        z-index: 1;
        padding-left: 30px;
        @media only screen and (max-width: 768px) {
          margin-right: 0;
          border-radius: 0;
          border-right: 0;
          padding-top: 5px;
          padding-left: 10px;
        }
        a {
          margin-right: 15px;
        }
        img {
          display: flex;
          background-color: ${colors.white};
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          padding: 5px;
          width: 48px;
        }
      `}
    >
      <Modal
        open={open}
        onClose={closeModal}
        center
        styles={{ modal: { backgroundColor: `#ff6c6c`, color: `#fff`, paddingRight: 50 } }}
      >
        {configError}
      </Modal>
      <Link to={`./dimensions`}>
        <img
          src={location.pathname.endsWith(`dimensions`) ? ResizeIconActive : ResizeIcon}
          alt='Dimensions'
        />
      </Link>
      <Link to={`./colors`}>
        <img
          src={location.pathname.endsWith(`colors`) ? ColorIconActive : ColorIcon}
          alt='Colors'
        />
      </Link>
      <Link to={`./parts`}>
        <img src={location.pathname.endsWith(`parts`) ? PartsIconActive : PartsIcon} alt='Parts' />
      </Link>
      <Link
        to={`./doors`}
        onClick={(e) => {
          if (configError) {
            openModal()
            setPrm({ ...prm, currentPart: configErrorNumber })
            e.preventDefault()
          }
        }}
      >
        <img src={location.pathname.endsWith(`doors`) ? DoorsIconActive : DoorsIcon} alt='Doors' />
      </Link>
      <Link
        to={`./summary`}
        onClick={(e) => {
          if (configError) {
            openModal()
            setPrm({ ...prm, currentPart: configErrorNumber })
            e.preventDefault()
          }
        }}
      >
        <img
          src={location.pathname.endsWith(`summary`) ? SummaryIconActive : SummaryIcon}
          alt='Complete order'
        />
      </Link>
    </div>
  )
}

export default Menu
