/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useTheme } from "emotion-theming"
import React from "react"

export default function RegisterLoginWrapper(props) {
  const colors = useTheme()
  return (
    <div
      css={css`
        grid-area: g;
        max-width: ${props.maxWidth}px;
        margin: 40px auto;
        align-self: center;
        @media only screen and (max-width: 576px) {
          width: 90%;
        }
      `}
    >
      <div
        css={css`
          background: ${colors.green};
          padding: 20px 36px;
          margin: auto;
          h2 {
            text-align: center;
            font-size: 36px;
            text-transform: uppercase;
            color: #fff;
          }
          form {
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 10px;
            grid-row-gap: 20px;
            align-items: center;
            label {
              text-align: right;
              color: #fff;
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
            }
            input {
              background: #fff;
              color: #000;
              height: 40px;
              border: 2px solid #fff;
              min-width: 260px;
            }
            input[type="submit"] {
              color: #fff;
              background: ${colors.green};
              grid-column-start: 1;
              grid-column-end: 3;
              border: 2px solid #fff;
              background: transparent;
              cursor: pointer;
              font-weight: 700;
              margin-top: 10px;
              margin-bottom: 30px;
              font-size: 16px;
              text-transform: uppercase;
              padding: 10px 29px;
              justify-self: center;
              &:hover {
                transition: 200ms;
                background: white;
                color: ${colors.green};
              }
            }
          }
          @media only screen and (max-width: 768px) {
            form {
              grid-row-gap: 10px;
              label,
              input {
                grid-column-start: 1;
                grid-column-end: 3;
              }
              label {
                text-align: left;
              }
              input {
                margin-bottom: 10px;
              }
            }
            h2 {
              font-size: 26px;
            }
            input[type="submit"] {
              font-size: 14px;
            }
          }
          @media (max-width: 576px) {
            padding: 5px 20px;
            form input {
              min-width: 0;
            }
          }
        `}
      >
        {props.children}
      </div>
    </div>
  )
}
